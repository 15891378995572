@use "~styles/constants" as *;

.scd-batch {
    width: 100%;
    height: 100%;
    padding: 0px 24px;
    display: flex;
    flex-direction: column;
    align-items: center;

    &__container {
        max-width: 1116px;
        min-height: 440px;
        width: 100%;
    }

    &__header {
        width: 100%;
        display: flex;
        row-gap: 12px;
        flex-direction: column;
        flex-wrap: nowrap;

        &-container {
            display: flex;
            width: 100%;

            &--title {
                font-size: 34px;
                font-weight: 500;
                color: #545454;
                margin: 0;
            }

            &--align-right {
                width: 100%;
                justify-content: flex-end;
                display: flex;
                align-items: center;
            }
        }

        &--buttons-content {
            display: flex;
            width: 100%;

            &-filter-button {
                padding-right: 10px;
            }

            &-error-arrow {
                content: $svg_error_arrow;
                width: 30px;
                height: 30px;
                margin-top: 8px;
                margin-left: 10px;
                cursor: pointer;
            }

            &-align-right {
                width: 100%;
                justify-content: flex-end;
                display: flex;
            }
        }

        @include responsive(s) {
            flex-direction: column;
        }
    }

    &__table {
        width: 100%;
        margin: 8px 0px 0px;

        .page-container--table {
            display: inline-block;
            
            @media screen and (min-height: 850px){
                height: 400px;
            }

            thead {
                width: 100%;
                
                &:first-child {
                    display: inline-block;
                }
            }
            
            tbody {
                height: calc(100vh - 396px);
                display: block;
                overflow-y: auto;
            }

            &.CHIPS_ACTIVE {
                tbody {
                    height: calc(100vh - 432px);

                }
            }

            &.EMPTY_COLLECTION {
                tbody {
                    min-height: 0px;
                    height: min-content;
                }
            }

            &-container::-webkit-scrollbar {
                width: 4px;
                background-color: #e1e2e6;
            }

            &-container::-webkit-scrollbar-thumb {
                width: 3px;
                background: #a4a4a4;
            }


            th {
                &:nth-child(1) {
                    width: 10.3%;
                }
                &:nth-child(2) {
                    width: 7.7%;
                }
                &:nth-child(3) {
                    width: 11.2%;
                }
                &:nth-child(4) {
                    width: 12.1%;
                }
                &:nth-child(5) {
                    width: 19.4%;
                }
                &:nth-child(6) {
                    width: 10%;
                }
                &:nth-child(7) {
                    width: 12%;
                }
                &:nth-child(8) {
                    width: 10%;
                }
            }
            td {
                margin-right: 11px;

                &:nth-child(1) {
                    width: 9.5%;
                }
                &:nth-child(2) {
                    width: 6.5%;

                    span {
                        color: #163c6e;
                        border: 1px solid #163c6e;
                        height: 25px;
                        width: 40px;
                        border-radius: 4px;
                    }
                }
                &:nth-child(3) {
                    width: 10.5%;
                }
                &:nth-child(4) {
                    width: 11%;
                }
                &:nth-child(5) {
                    width: 18.5%;

                    span {
                        font-family: 'Frutiger-light';
                        font-size: 14px;
                        font-weight: 300;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                        display: block;
                    }
                }
                &:nth-child(6) {
                    width: 9%;
                }
                &:nth-child(7) {
                    width: 10%;
                }
                &:nth-child(8) {
                    width: 11%;
                }
            }

            &-status {
                display: inline-flex;
                font-size: 11px;
                align-items: center;
                white-space: nowrap;
                justify-content: flex-start;
                height: 24px;
                font-weight: 500;
                border-radius: 5px;
                padding: 10px;

                &.OPEN {
                    background-color: #f2c466;
                }

                &.CLOSED {
                    background-color: #b8d759;
                }

                &.CONCLUDED {
                    background-color: #6acff1;
                }

                &.ERROR {
                    background-color: #ff7978;
                }

                &.PROCESSING {
                    background-color: #d3d0cb;
                }
            }
        }

        &--content {
            min-height: 330px;
        }

        &-empty-filter {
            width: 100%;
            height: 260px;
            display: flex;
            justify-content: center;
            align-items: center;
            font-family: 'Frutiger';
            flex-direction: column;

            &-image {
                content: $svg_ops;
                width: 100px;
                height: 100px;
            }

            &-title {
                font-size: 26px;
                line-height: 24px;
                color: #d8d8d8;
                padding-top: 10px;
            }

            &-subtitle {
                font-size: 16px;
                line-height: 24px;
                color: #d8d8d8;
                padding-top: 10px;
            }
        }
    }

    &__modal-message {
        width: 100%;
        font-size: 15px;
        text-align: center;
        color: #545454;
        padding: 0 20px;
        font-family: 'Frutiger-light';
    }
}

.MuiPaper-root {
    background: #ffffff;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    
    .MuiMenuItem-root {
        font-size: 15px;
        color: #072056;
    }
}
