.simple-menu-item {
    width: 100%;
    display: flex;
    flex-direction: column;
    border-radius: 8px;

    &:hover {
        background-color: #163c6e;
    }


    &--link {
        width: 100%;
        height: 48px;
        background: transparent;
        border: none;
        color: white;

        font-family: 'Frutiger', sans-serif;
        font-size: 16px;
        font-weight: 400;
        border-radius: 8px;

        display: flex;
        align-items: center;
        padding: 0 24px;

        column-gap: 8px;

        img {
            min-width: 20px;
            min-height: 20px;
        }

        &:hover {
            text-decoration: none;
            color: white;
            background-color: #163c6e;
        }

        &.active {
            background-color: #32aacf;

            img {
                filter: brightness(0) invert(100%);
            }

        }


        &--text {
            column-gap: 8px;
            padding: 0;
            margin: 0;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
        }
    }
}
