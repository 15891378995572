@use '~styles/constants' as *;

.monthly-commission-generate-and-rectify-tax-report {
    display: flex;
    align-items: center;

    &--title {
        color: #565a5b;
        letter-spacing: 1.5px;
        width: 210px;
    }

    &--inputs {
        width: 578px;
        display: flex;
        margin: 0px 10px;
    }

    &--icons {
        width: 260px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        &-remove-arrow {
            width: 25px;
            cursor: pointer;

            path {
                fill: #163c6e;
            }
        }

        &-check {
            border: 1px solid #9199a1;
            padding: 6px;
            width: 45px;
            display: flex;
            align-items: center;
            justify-content: center;
            height: 45px;
            border-radius: 10px;

            &.disabled {
                cursor: default;
            }

            &.active {
                cursor: pointer;
                border: 1px solid #3f41d1;
                color: #3f41d1;
            }
        }

        &-comentary {
            cursor: pointer;
            content: $svg_description;
            width: 20px;
            height: 20px;
    
            &.active {
                cursor: pointer;
                content: $svg_description_active;
            }
    
            &.disabled {
                content: $svg_description;
                cursor: default;
            }
        }

        &__content {
            width: 170px;
            justify-content: space-between;
            align-items: center;

            display: flex;

            &-icon {
                margin: 0px 5px;
                cursor: pointer;
            }            
        }
    }

    &__tax-add-new-input {
        width: 160px;
        opacity: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 5px;
        margin-right: 10px;
        height: 50px;

        .MuiSvgIcon-root {
            color: #d8d8d8;
        }

        &:hover {
            width: 160px;
            background: #f6f6f6;
            border: 1px dashed #d8d8d8;
            border-radius: 6px;
            opacity: 1;
            cursor: pointer;
        }
    }
}
