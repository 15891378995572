@use "~styles/constants" as *;

.confirm-modal-close {
    font-family: 'Frutiger', sans-serif;

    &--batch-code {
        display: flex;
        color:$gray-100; 
        column-gap: 4px;
        font-family: 'Frutiger', sans-serif;
        font-size: 16px;


        &--number {
            font-weight: 500;
            font-family: 'Frutiger', sans-serif;
        }
    }

    &--batch-operations {
        display: flex;
        color:$gray-100; 
        column-gap: 4px;
        font-family: 'Frutiger', sans-serif;
        font-size: 16px;

        &--number {
            font-family: 'Frutiger', sans-serif;   
            font-weight: 500;
        }
    }

    &--batch-errors {
        
        display: flex;
        align-items: center;
        column-gap: 8px;
        margin: 16px 0px;
        
        &--outlined-icon {
            width: 24px;
            height: 24px;
            border-radius: 100%;
            background-color: $red-30;
            cursor: pointer;
    
            display: flex;
            justify-content: center;
            align-items: center;
            border: none;
    
            background-image: $svg_error_outline_icon;
            background-size: 20px 20px;
            background-repeat: no-repeat;
            background-position: center;
        }

        &--text {
            color: $red-70;
            font-family: 'Frutiger', sans-serif;
            font-size: 16px;
        }
    }
    
    &--batch-errors-empty {
        color: $gray-100;
        font-family: 'Frutiger', sans-serif;
        font-size: 16px;
        margin-top: 20px;
    }

    &--batch-warning-message {

        color: $medium_blue;
        font-family: 'Frutiger', sans-serif;
        font-size: 16px;
        
        &.HAS_ERROR {
            color: $red-70;
        }
    }
    &__checkbox {
        margin: 16px 0px 0px;
        display: flex;
        align-items: center;
        column-gap: 8px;

        .MuiCheckbox-root {
            padding: 0px;
            margin: 0px;
            color: $medium_blue;    
        }
    }
}

