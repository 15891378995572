.repayment-fallback {
    padding: 10px 20px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;

    &__container {
        max-width: 1115px;
        width: 100%;
    }

    &__header {
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        width: 100%;
        padding: 20px 0;

        &-container {
            display: flex;
            width: 100%;
            align-items: center;
            justify-content: space-between;

            &--title {
                font-size: 34px;
                font-weight: 500;
                color: #545454;
                margin: 0;
            }

            &--align {
                display: flex;
                justify-content: flex-start;
                align-items: center;
            }
        }

        &-account-infos {
            display: flex;
            justify-content: space-between;
            width: 30%;

            &--item {
                font-size: 19px;
                color: #bac0c2;
                margin: 15px 0px;
            }

            &--status {
                display: inline-flex;
                padding: 10px;
                font-size: 11px;
                align-items: center;
                white-space: nowrap;
                justify-content: center;
                height: 24px;
                font-weight: 500;
                border-radius: 5px;
                margin: 15px 0px;

                &.ACTIVE {
                    background-color: #b8d759;
                }

                &.INACTIVE {
                    background-color: #db6968;
                    color: #ffffff;
                }
            }
        }
    }


    &--container {
        padding: 50px 0;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: fit-content;

        .button__style--back, .MuiButton-contained {
            max-width: 220px;

            &:hover {
                max-width: 220px;
            }
        }
    }

    &--message {
        font-size: 26px;
        line-height: 26px;
        color: #d8d8d8;
        padding-top: 10px;
        margin-bottom: 30px;
        text-align: center;
    }
}