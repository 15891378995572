@use '~styles/constants' as *;

.button__style--dashed {
    white-space: nowrap;
    width: 100%;

    .MuiButton-contained {
        border-color: $blurple;
        span {
            color: $blurple;
        }
        &:hover {
            .button__style--dashed-icon {
                content: $svg_plus_hover;
            }
        }
    }

    .MuiButton-label {
        margin-top: 4px;
        font-size: 12px;
    }

    .MuiButton-contained:hover {
        border-color: $blurple_focus;

        .button--arrow {
            color: $blurple_focus;
        }

        span {
            color: $blurple_focus;
        }
    }

    .MuiIcon-root {
        height: 16px;
        width: 16px;
    }

    &-icon {
        display: flex;
        align-items: center;
        justify-content: center;
        content: $svg_plus_rest;
        height: 100%;
        width: 100%;
    }
    &-label {
        margin-top: 2px;
    }

    .MuiButton-contained:hover,
    .MuiButton-contained {
        background-color: $white_two;
        box-shadow: unset;
        border-style: dashed;
        border-width: 1px;
        outline: 0;
        width: 100%;
        max-width: 100%;
        min-width: 170px;
        color: $brown_grey_three;
        font-size: 0.875rem;
        font-weight: 500;
        letter-spacing: 1.5px;
        border-radius: 10px;
        min-height: 45px;
        max-height: 45px;
        padding: 10px 20px;

        &.Mui-disabled {
            background-color: $white_two;
            border-color: $very_light_pink_four;

            span {
                color: $very_light_pink_four;
                font-weight: 600;
            }
            .button__style--dashed-icon {
                content: $svg_plus_disabled;
            }
        }
    }

    .button--arrow {
        color: $blurple;
    }
}
