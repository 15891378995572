@use '~styles/constants' as *;

.relocate-operation-modal-batch {
    z-index: 2;
    width: 500px !important;
    height: 570px !important;

    &--container {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        padding: 14px 32px 30px;
        border-radius: 6px;
        background-color: #ffffff;
        height: 100%;
    }

    &--content {
        width: 380px;
        height: 480px;
        text-align: center;

        &-link {
            color: #3f41d1;
            font-size: 14px;
            letter-spacing: 1.5px;
            padding-top: 30px;
            cursor: pointer;
        }
    }

    &--header {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;
        margin-bottom: 16px;

        &-title {
            font-size: 24px;
            color: #4A4A4A;
            text-align: center;
            font-family: 'Frutiger-55';
            font-weight: 500;
        }

        &-subtitle {
            font-size: 15px;
            text-align: center;
            color: #545454;
            font-family: 'Frutiger-light';
        }

        &-close-button {
            width: 100%;
            justify-content: flex-end;
            display: flex;
        }
    }

    &--body {
        padding: 0px 20px;  
    }

    &--buttons {
        &-actions {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-top: 15px;
            padding: 0px 20px;

            > :nth-child(1) {
                width: 150px;
            }

            > :nth-child(2) {
                width: 150px;
            }
        }
    }
}
