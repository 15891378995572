@use "~styles/constants" as *;

.scd-tax {
    width: 100%;
    height: 100%;
    padding: 0px 24px;
    display: flex;
    flex-direction: column;
    align-items: center;

    &__container {
        max-width: 1116px;
        min-height: 440px;
        width: 100%;
    }

    &__header {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-wrap: nowrap;

        &--title {
            font-size: 34px;
            font-weight: 500;
            color: #545454;
            margin: 0;

            @include responsive(sm) {
                text-align: center;
                font-size: 32px;
            }

            @include responsive(s) {
                text-align: center;
                font-size: 32px;
                margin-bottom: 20px;
            }

            @include responsive(ss) {
                text-align: center;
                font-size: 29px;
            }
        }

        @include responsive(s) {
            flex-direction: column;
        }
    }

    &__table {
        width: 100%;
        margin: 8px 0px 0px;

        .page-container--table {
            display: inline-block;
            
            @media screen and (min-height: 850px){
                height: 400px;
            }

            thead {
                width: 100%;

                &:first-child {
                    display: inline-block;
                }
            }
            
            tbody {
                display: block;
                height: calc(100vh - 332px);
            }

            &-container::-webkit-scrollbar {
                width: 4px;
                background-color: #e1e2e6;
            }

            &-container::-webkit-scrollbar-thumb {
                width: 3px;
                background: #a4a4a4;
            }

            &.EMPTY_COLLECTION {
                tbody {
                    min-height: 0px;
                    height: min-content;
                }
            }

            th {
                &:nth-child(1) {
                    width: 23%;
                }
                &:nth-child(2) {
                    width: 22%;
                }
            }
            td {
                &:nth-child(1) {
                    width: 40%;
                }
                &:nth-child(2) {
                    width: 40%;
                }
            }



        }

        &--content {
            min-height: 330px;
        }

        &--cell-icon {
            @include responsive(sm) {
                width: 7%;
            }

            @include responsive(s) {
                width: 7%;
            }
        }
    }
}
