@use "~styles/constants" as *;

.scd-payment-transaction__detail {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-bottom: 30px;

    &--column-section {
        display: flex;
        justify-content: space-around;
        align-items: flex-start;
        width: 100%;
        max-width: 1050px;
    }

    &--container {
        height: auto;
        overflow-y: auto;
    }

    &--left-container {
        min-width: 350px;
        display: flex;
        align-items: center;
        flex-direction: column;
        margin-top: 25px;
        position: relative;
        height: 70vh;
    }

    &--right-container {
        display: flex;
        align-items: center;
        flex-direction: column;
        flex: 1;
        margin-top: 25px;
        position: relative;
        max-height: 70vh;
        background-color: #3f578b;
        border-radius: 8px;
        padding: 10px 25px 25px 25px;
    }

    &--button-confirm {
        margin-top: 80px;
        position: fixed;
        max-height: 70vh;
        border-radius: 8px;
        padding: 10px;
        transform: translateY(-50%);
        top: 80%;
    }

    &--header {
        &-title {
            text-align: center;
            padding: 10px 0;
        }
    }

    &--form {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        gap: 15px;
        background-color: #f5f5f5;
        border-radius: 8px;
        margin-top: 20px;

        &-loading {
            height: 300px;
            width: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            gap: 20px;

            label {
                font-size: 26px;
                line-height: 24px;
                color: #d8d8d8;
                text-align: center;
            }

            .loading--row {
                height: 60px;
                min-height: unset;

                .loading--cell {
                    height: 100%;

                    > div {
                        inset: unset !important;
                    }
                }
            }
        }

        &-title {
            text-align: center;
            color: #fff
        }

        &-header {
            width: 100%;
            margin: 10px 0;
            display: flex;
            align-items: center;
            justify-content: space-between;
        }

        &-infos {
            width: 100%;
            height: 320px;
            display: flex;
            justify-content: space-around;
            align-items: flex-start;
            overflow-y: auto;
        }

        &-item {
            width: 100%;
            margin: 10px 0;
        }

        &-account-item {
            display: flex;
            gap: 15px;
            margin: 10px 0;

            div:nth-child(1) {
                width: 195%;
            }
        }

        &-buttons {
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            padding-bottom: 10px;
            gap: 10px;

            .MuiButton-contained {
                max-width: unset;

                &:hover {
                    max-width: unset;
                }
            }
        }

        &-errors {
            padding: 25px;
            height: auto;

            &__label {
                font-size: 20px;
                text-align: center;
                font-weight: 400;
            }

            &__item {
                font-size: 16px;
                margin: 16px;
                font-weight: 400;
                color: #2d3e46;
                font-family: 'Frutiger'
            }
        }
    }

    &--modal-message {
        width: 100%;
        font-size: 15px;
        text-align: center;
        color: #545454;
        padding: 0 20px;
        font-family: 'Frutiger-light';
    }
}
