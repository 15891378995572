$black: #000000;
$dark: #2d3e46;

$gray-100: #121619; 
$gray-10: #ececec;
$gray-20: #DDE1E6;
$gray-30: #c6c6c6;


$white_two: #ffffff;
$white_three: #f6f6f6;
$white: #f5f5f5;
$alabaster: #fafafa;
$light-periwinkle: #e1e2e6;
$cloudy_blue: #c3c7cb;
$light_periwinkle: #e1e2e6;
$ice_blue: #e8ebec;
$bluey_grey: #9199a1;
$very_light_pink: #f2f2f2;
$very_light_pink_two: #e3e3e3;
$mercury: #e3e3e3;
$black_squeeze: #f4f8fb;

$greyish_brown: #4a4a4a;
$brown_grey: #7b7b7b;
$brown_grey_two: #aaaaaa;
$very_light_pink_three: #b9b9b9;
$very_light_pink_four: #bfbfbf;
$brown_grey_three: #a4a4a4;
$scorpion: #5e5e5e;
$medium_grey: #343434;
$lighter_grey: #565A5B;
$dark_blue: #163C6E;


$link_water: #d4e5f0;
$cool_blue: #3aadd1;
$cool_blue_two: #32aacf;
$denim_blue: #39549e;
$dusky_blue: #486094;
$picton_blue: #51cdf3;
$madison: #072056;

$gross_green: #9ac91c;
$greenish_teal: #34c7a9;
$turquoise: #00bfa5;
$sick_green: #9bc731;
$sickly_green: #86b31a;
$soft_green: #6dbc70;
$piss_yellow: #d9cc18;
$yellowish: #f6ed71;
$sun_yellow: #e6da3c;

$pumpkin: #ef7d00;
$punch: #e04444;
$red-70: #A2191F;
$red-30:#FFB3B8;

$blurple: #3f41d1;
$blurple_focus: #282a86;
$medium_blue: #4262D7;

// organization
$organization_primary: #3f41d1;
$organization_primary_focus: #32348d;
$organization_primary_disabled: #3f41d14d;

$organization_program_default: #406afa;

$organization_secondary: #072056;
$organization_secondary_focus: #784af4;

$organization_secondary_transparent: #030b1db3;

$organization_secundary_border: #486094;

$organization_secundary_link: #32aacf;
$organization_secundary_link_hover: #51cdf3;

$organization_secundary_button: #3f41d1;
$organization_secundary_button_focus: #32348d;

