.select-option-search-batches-input-container {
    height: 270px;
    border-radius: 7px;
    background-color: white;
    margin-top: 15px;
}

.select-option-search-batches-div-style {
    overflow-y: auto;
    overflow-x: hidden !important;
    max-height: 200px;
    margin-top: 15px;
}

.select-option-search-batches-div-style::-webkit-scrollbar {
    width: 4px;
    background-color: #e1e2e6;
}

.select-option-search-batches-div-style::-webkit-scrollbar-thumb {
    width: 3px;
    background: #a4a4a4;
}

.select-option-search-batches-item-container {
    font-size: 18px;
    font-weight: 300;
    letter-spacing: 0.56px;
    display: flex;
    align-items: center;
    color: #9199A1;
    line-height: 15px;
    overflow: hidden;
    font-family: 'Frutiger-55';
    padding: 14px;
    line-height: 22px;
    text-align: left;

    &:hover {
        border-radius: 4px;
        background-color: #163C6E;
        cursor: pointer;
        color: #ffffff;
    }
}
