@use "~styles/constants" as *;

.error-card {
    width: 100%;
    height: 100%;
    font-size: 14px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    border: 1px solid $red-30;
    border-radius: 6px;

    &--message {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        width: 95%;
        height: 50%;
        font-size: 14px;
        font-family: 'Frutiger-65-bold', sans-serif;
        font-weight: 200;
        letter-spacing: 1.5px;
    }
    
    &--refresh {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 95%;
        height: 35%;
        border-top: 1px solid #d8d8d8;
        cursor: pointer;


        &__value {
            letter-spacing: 1px;
            color: $red-70;
            font-weight: 400;
            font-size: 12px;
            margin: 0px 8px;
            line-height: 20px;
            text-transform: uppercase;
            font-family: 'Frutiger', sans-serif;
        }
    }

    &--refresh-icon {
        color: $red-70;
        cursor: pointer;

        &--spin {
            color: $red-70;
            animation: spin 0.5s linear infinite;
        }
    }
}