@use "~styles/constants" as *;

.batch-closing-footer {
    width: 100%;
    height: 90px;
    background: #ffff;
    font-family: 'Frutiger', sans-serif;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    box-shadow: 0px -5px 10px 0px rgba(0, 0, 0, 0.05);
    position: fixed;
    bottom: 52px;
    left: 0;
    right: 0;
    z-index: 1;

    &__content {
        width: 100%;
        max-width: 1116px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 10px 20px;
    }

    &__message-box {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    &__alert-box {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        flex-grow: 1; 
    }

    &__close-button {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-left: auto;
    }

    &--render-quantities {
        display: flex;
        align-items: center;
        column-gap: 8px;
        margin: 2px 0px;

        &--icon {
            width: 24px;
            height: 24px;
            border-radius: 100%;
            background-color: $red-30;
            cursor: pointer;

            display: flex;
            justify-content: center;
            align-items: center;
            border: none;

            background-image: $svg_error_outline_icon;
            background-size: 20px 20px;
            background-repeat: no-repeat;
            background-position: center;
        }

        &--text {
            color: $red-70;
            font-family: 'Frutiger', sans-serif;
            font-size: 16px;
        }

        &--text-warning {
            color: #9c8727;
            font-family: 'Frutiger', sans-serif;
            font-size: 16px;
        }

        &--icon-warning {
            color: #9c8727;
        }
    }

    &__button-container--secondary {
        .MuiButton-contained {
            background-color: #A2191F;
            border-color: #A2191F;
            color: #FFFFFF;
    
            &:hover {
                background-color: darken(#A2191F, 10%); 
                border-color: darken(#A2191F, 10%);
            }
        }
    }

}