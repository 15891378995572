@use '~styles/constants' as *;

.chip-filter-active {
  width: 100%;
  display: flex;
  gap: 12px;
  flex-wrap: wrap;

  .MuiChip-root {
    background-color: $dark_blue;
    color: $white_two;
    max-width: 225px;

    &:focus {
        background-color: $dark_blue;
    }
  } 

  .MuiChip-deleteIcon {
    color: $white_two;

    &:hover {
      color: $cloudy_blue
    }
  }
}