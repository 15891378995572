@use '~styles/constants' as *;

.transfeera-request-details {
    margin-bottom: 15px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 30px;
    width: 600px;

    &--header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-bottom: 16px;

        &-title {
            font-size: 20px;
            font-weight: bold;
            color: #292a2a;
            letter-spacing: 0.3px;
            line-height: 1.2;
            margin: 0;
        }
    }

    &--content {
        label {
            font-size: 1rem;
            color: #292a2a;
            font-weight: 500;
            display: block;
            margin: 0;
        }
    }

    &--buttons {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 25px;
        > :nth-child(2) {
            margin-left: 25px;
        }
    }

    &--loading {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        min-height: 130px;

        &.MuiCircularProgress-root {
            background-color: #3f41d1;
        }
    }
}