@use '~styles/constants' as *;

.banking-correspondent {
    height: 100%;
    padding: 0px 24px;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;

    &__container {
        max-width: 1116px;
        min-height: 440px;
        width: 100%;
    }

    &__header {
        width: 100%;
        display: flex;
        row-gap: 12px;
        flex-direction: column;
        flex-wrap: nowrap;

        &-container {
            display: flex;
            width: 100%;
            justify-content: space-between;

            &--title {
                font-size: 34px;
                font-weight: 500;
                color: #545454;
                margin: 0;
            }
        }

        @include responsive(s) {
            flex-direction: column;
        }
    }

    &__table {
            width: 100%;
            margin: 8px 0px 0px;

        .page-container--table {
            display: inline-block;

            @media screen and (min-height: 850px){
                height: 400px;
            }
            

            thead {
                width: 100%;
                &:first-child {
                    display: inline-block;
                }
            }

            tbody {
                display: block;
                height: calc(100vh - 332px);
            }

            &-container::-webkit-scrollbar {
                width: 4px;
                background-color: #E1E2E6;
            }

            &-container::-webkit-scrollbar-thumb {
                width: 3px;
                background: #A4A4A4;
            }

            th {
                &:nth-child(1) {
                    width: 37.3%;
                }
                &:nth-child(2) {
                    width: 24%;
                }
                &:nth-child(3) {
                    width: 35%;
                }
            }

            td {
                &:nth-child(1) {
                    width: 42%;
                    margin-right: 16px;
                    line-height: 1.2rem;
                }
                &:nth-child(2) {
                    width: 28%;
                    margin-right: 1px;
                }
                &:nth-child(3) {
                    width: 35%;
                }
            }

            @include responsive(sm) {
                th {
                    &:nth-child(1) {
                        width: 25%;
                    }
                    &:nth-child(2) {
                        width: 20%;
                    }
                    &:nth-child(3) {
                        width: 25%;
                    }
                }
                td {
                    &:nth-child(1) {
                        width: 25%;
                    }
                    &:nth-child(2) {
                        width: 20%;
                    }
                    &:nth-child(3) {
                        width: 25%;
                    }
                }
            }

            @include responsive(s) {
                th {
                    &:nth-child(1) {
                        width: 290px;
                    }
                    &:nth-child(2) {
                        width: 250px;
                    }
                    &:nth-child(3) {
                        width: 200px;
                    }
                }
                td {
                    &:nth-child(1) {
                        width: 300px;
                    }
                    &:nth-child(2) {
                        width: 290px;
                    }
                    &:nth-child(3) {
                        width: 215px;
                    }
                }
            }
        }

        &-empty-filter {
            width: 100%;
            height: 350px;
            display: flex;
            justify-content: center;
            align-items: center;
            font-family: 'Frutiger';
            flex-direction: column;

            &-image {
                content: $svg_piggy_bank;
                width: 100px;
                height: 100px;
            }

            &-title {
                font-size: 26px;
                line-height: 24px;
                color: #d8d8d8;
                padding-top: 10px;
            }

            &-subtitle {
                font-size: 16px;
                line-height: 24px;
                color: #d8d8d8;
                padding-top: 10px;
            }
        }

        &-empty-filter {
            width: 100%;
            height: 350px;
            display: flex;
            justify-content: center;
            align-items: center;
            font-family: 'Frutiger';
            flex-direction: column;

            &-image {
                content: $svg_piggy_bank;
                width: 100px;
                height: 100px;
            }

            &-title {
                font-size: 26px;
                line-height: 24px;
                color: #d8d8d8;
                padding-top: 10px;
            }

            &-subtitle {
                font-size: 16px;
                line-height: 24px;
                color: #d8d8d8;
                padding-top: 10px;
            }
        }
        &--content {
            min-height: 330px;
        }
        
        &--cell-icon {
            @include responsive(sm) {
                width: 7%;
            }
            @include responsive(s) {
                width: 7%;
            }
        }
    }
}