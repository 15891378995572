@use "~styles/constants" as *;

.button__style--link {
    .MuiButton-contained {
        background-color: $organization_primary;
        box-shadow: unset;
    }

    .MuiButton-contained:hover {
        background-color: $organization_primary_focus;
        box-shadow: unset;
    }

    .MuiButton-label {
        justify-content: center;
    }

    .MuiButton-contained:hover,
    .MuiButton-contained {
        color: $white_two;
        font-size: 0.875rem;
        font-family: Frutiger;
        padding: 10px 30px;
        font-weight: 400;
        letter-spacing: 1.5px;
        border-radius: 10px;
        white-space: nowrap;
        width: 100%;
        height: 100%;
    }

    .MuiButton-contained.Mui-disabled {
        color: $white_two;
        background-color: $organization_primary;
        font-size: 0.875rem;
        font-family: Frutiger;
        padding: 10px 30px;
        font-weight: 400;
        letter-spacing: 1.5px;
        border-radius: 10px;
        white-space: nowrap;
        width: 100%;
        height: 100%;
        opacity: 0.5;
    }
}

.button__style--link-outlined {
    .MuiButton-contained {
        background-color: transparent;
        border: $organization_primary 1px solid;
        box-shadow: unset;
    }

    .MuiButton-contained:hover {
        background-color: transparent;
        border: $organization_primary 1px solid;
        box-shadow: unset;
    }

    .MuiButton-label {
        justify-content: center;
    }

    .MuiButton-contained:hover,
    .MuiButton-contained {
        color: $organization_primary;
        font-size: 0.875rem;
        font-family: Frutiger;
        padding: 10px 30px;
        font-weight: 400;
        letter-spacing: 1.5px;
        border-radius: 10px;
        white-space: nowrap;
        width: 100%;
        height: 100%;
    }

    .MuiButton-contained.Mui-disabled {
        color: $organization_primary;
        background-color: transparent;
        font-size: 0.875rem;
        font-family: Frutiger;
        padding: 10px 30px;
        font-weight: 400;
        letter-spacing: 1.5px;
        border-radius: 10px;
        white-space: nowrap;
        width: 100%;
        height: 100%;
        opacity: 0.5;
    }
}
