@use "~styles/constants" as *;

@keyframes spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

.refresh-button {
    background: none;
    border: none;
    margin-left: 12px;
    color: #3f41d1;

    &--refresh-icon {
        cursor: pointer;

        &--spin {
            animation: spin 0.5s linear infinite;

        }
    }
}
