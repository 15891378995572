.transfeera-statement-show-balance {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 25px;
    width: 50%;

    &--item {
        width: 70%;
        margin-right: 15px;
    }

    &--label {
        font-family: 'Frutiger';
        color: #545454;
        margin: 0;
        font-weight: 500;
        margin-top: 10px;
    }
}