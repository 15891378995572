@use '~styles/constants' as *;

.payment-type-subtitle {

    &__button {
        font-weight: 500;
        font-size: 14px;
        line-height: 24px;
        letter-spacing: 1.5px;
        color: #32AACF;
        display: flex;
        column-gap: 8px;
        justify-content: flex-start;
        align-items: center;

        background: none;
        border: none;
        outline: none;
    }


    &__subtitle-menu {
        display: flex;
        align-items: center;
        column-gap: 8px;
        margin: 0px 8px;
        padding: 8px 0px;
        border-bottom: 1px solid lightgrey;
        position: relative;
        cursor: default;
        font-family: "Frutiger-light";
        font-weight: 500;
        font-size: 14px;
        color: #343434;

        &__subtitle-color {
            width: 16px;
            height: 16px;
            border-radius: 50%;



        &.MANUAL {
            background-color: #9199a1;
        }

        &.DISBURSEMENT_BY_BATCH {
           background-color: #072056;
        }

        &.DISBURSEMENT_TO_DEBTOR {
           background-color: #32aacf;
        }
        &.MANUAL_DISBURSEMENT {
           background-color: #6dbc70;
        }
        &.AMENDED_DISBURSEMENT {
           background-color: #ED66C9;
        }
        &.INTERNAL_REPAYMENT {
           background-color: #ef7d00;
        }
        &.EXTERNAL_DISBURSEMENT {
           background-color: #b947ff;
        }
    }


    &__subtitle-text {
        font-weight: 500;
        font-size: 14px;
        color: #343434;
        }
    }
}
