@use '~styles/constants' as *;

.scd-organization {
    width: 100%;
    height: 100%;
    padding: 0px 24px;
    display: flex;
    flex-direction: column;
    align-items: center;

    &__container {
        max-width: 1116px;
        min-height: 440px;
        width: 100%;
    }

    &__header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-wrap: nowrap;
        width: 100%;
        row-gap: 12px;

        &--title {
            font-size: 34px;
            font-weight: 500;
            color: #545454;
            margin: 0;

            @include responsive(sm) {
                text-align: center;
                font-size: 32px;
            }

            @include responsive(s) {
                text-align: center;
                font-size: 32px;
                margin-bottom: 20px;
            }

            @include responsive(ss) {
                text-align: center;
                font-size: 29px;
            }
        }

        @include responsive(s) {
            flex-direction: column;
        }
    }

    &__modal {
        &--message {
            width: 100%;
            font-size: 15px;
            text-align: center;
            font-weight: 300;
            padding: 0 20px;
            color: #545454;
            font-family: 'Frutiger-light';
            word-break: break-all;
        }
    }

    &__table {
        width: 100%;
        margin: 8px 0px 0px;

        .page-container--table {
            display: inline-block;
            
            @media screen and (min-height: 850px){
                height: 400px;
            }
            
            
            thead {
                width: 100%;

                &:first-child {
                    display: inline-block;
                }
            }

            tbody {
                display: block;
                height: calc(100vh - 332px);
            }

            &.EMPTY_COLLECTION {
                tbody {
                    min-height: 0px;
                    height: min-content;
                }
            }

            &-container::-webkit-scrollbar {
                width: 4px;
                background-color: #e1e2e6;
            }

            &-container::-webkit-scrollbar-thumb {
                width: 3px;
                background: #a4a4a4;
            }
            
            th {
                &:nth-child(1) {
                    width: 40%;
                }
                &:nth-child(2) {
                    width: 25%;
                }
                &:nth-child(3) {
                    width: 30%;
                }
            }
            td {
                white-space: nowrap;
                &:nth-child(1) {
                    margin-right: 15px;
                    display: block;
                    overflow-x: hidden;
                    text-overflow: ellipsis;
                    width: 39%;
                }
                &:nth-child(2) {
                    width: 25%;
                }
                &:nth-child(3) {
                    width: 20%;
                }
                &:nth-child(4) {
                    width: 5%;
                }
                &:nth-child(5) {
                    width: 5%;
                }
                &:nth-child(6) {
                    width: 5%;
                }
            }
            @include responsive(sm) {
                th {
                    &:nth-child(1) {
                        width: 38%;
                    }
                    &:nth-child(2) {
                        width: 25%;
                    }
                    &:nth-child(3) {
                        width: 25%;
                    }
                    &:nth-child(3) {
                        width: 25%;
                    }
                }
                td {
                    &:nth-child(1) {
                        width: 36%;
                    }
                    &:nth-child(2) {
                        width: 26%;
                    }
                    &:nth-child(3) {
                        width: 20%;
                    }
                    &:nth-child(4) {
                        width: 5%;
                    }
                    &:nth-child(5) {
                        width: 5%;
                    }
                    &:nth-child(6) {
                        width: 5%;
                    }
                }
            }

            @include responsive(s) {
                th {
                    &:nth-child(1) {
                        width: 295px;
                    }
                    &:nth-child(2) {
                        width: 250px;
                    }
                    &:nth-child(3) {
                        width: 200px;
                    }
                }
                td {
                    &:nth-child(1) {
                        width: 280px;
                    }
                    &:nth-child(2) {
                        width: 250px;
                    }
                    &:nth-child(3) {
                        width: 220px;
                    }
                    &:nth-child(4) {
                        width: 50px;
                    }
                    &:nth-child(5) {
                        width: 50px;
                    }
                    &:nth-child(6) {
                        width: 50px;
                    }
                }
            }
        }

        &--content {
            min-height: 330px;
        }

        &--cell-icon {
            @include responsive(sm) {
                width: 7%;
            }

            @include responsive(s) {
                width: 7%;
            }
        }
    }
}
