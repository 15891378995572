.submenu-menu-list {
    width: 100%;
    display: flex;
    flex-direction: column;
    border-radius: 8px;

    &:hover {
        background-color: #163c6e;
    }

    &.isOpen:hover {
        background: transparent;

    }

    &--button {
        width: 100%;
        height: 48px;
        background: transparent;
        border: none;
        color: white;

        font-family: 'Frutiger', sans-serif;
        font-size: 16px;
        font-weight: 400;
        border-radius: 8px;

        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 24px;

        &.active {
            background-color: #3aadd1;

            img {
                filter: brightness(0) invert(100%);
            }
        }

        &--title-icon-box {
            display: flex;
            align-items: center;
            column-gap: 8px;

            img {
                min-width: 20px;
                min-height: 20px;

            }
        }

        &--text {
            max-width: 144px;
            column-gap: 8px;
            padding: 0;
            margin: 0;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
        }

    }

    &--submenu-list {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content:center;
        list-style: none;
        padding: 0 0 0 28px;
        margin: 4px 0 0;
        row-gap: 4px;

    }
}

