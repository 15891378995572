@use "~styles/constants" as *;

.button__style--action {
    .sc-aXZVg, 
    .guZdik {
        background-color: $organization_primary;
        box-shadow: unset;
        cursor:pointer;

    }

    .sc-aXZVg, 
    .guZdik .label
     {
        color: $white_two;
        font-size: 0.875rem;
        font-family: Frutiger;
        font-weight: 400;
        letter-spacing: 1.5px;
        border-radius: 10px;
        width: 100%;
        max-width: 172px;
        min-width: 172px;
        min-height: 46px;
        max-height: 46px;
        @include responsive(s) {
            max-width: 130px;
            min-width: 130px;
        }
        .label {
            background-color: red;
            color: red;
        }
    }

    .sc-aXZVg {
        justify-content: center;
        align-items: center;
        display: flex;
        color: white;
    }

}
