.monthly-commission-document-file-input {
    font-family: Frutiger;
    display: flex;
    flex-direction: column;

    &__label {
        display: flex;
        flex-direction: column;

        &--title {
            color: #072056;
            font-size: 15px;
        }

        &--description {
            color: #a5a8aa;
            font-size: 13px;
        }
    }

    label {
        margin-top: 7px;
        margin-bottom: 0px;
    }

    &__file {
        cursor: pointer;
        display: flex;
        align-items: center;

        &.disabled {
            opacity: 0.3;
            cursor: default;
        }

        &--label {
            color: #9199a1;
            font-family: 'Frutiger-55';
            border-bottom: 1px solid #9199a1;
            font-size: 13px;
            font-weight: 500;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            display: block;
            line-height: 1.2;
        }

        &--icon {
            color: #9199a1;
            margin-right: 6px;
            height: 14px;
        }

        &--error-message {
            text-align: left;
            font-size: 0.75rem;
            width: 100%;
            color: #f44336;
            font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
            font-weight: 400;
            letter-spacing: 0.03333em;
        }

        &--selected {
            width: 90%;
            background-color: #f5f5f5;
            border-radius: 6px;
            color: #565a5b;
            margin-top: 2px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            position: relative;
            padding-right: 26px;

            &-value {
                padding: 0px 3px;
                font-family: 'Frutiger-light';
                font-size: 12px;

                &.isDownload {
                    cursor: pointer;
                }
            }

            .MuiSvgIcon-fontSizeSmall {
                font-size: 16px;
                margin-left: 5px;
            }

            &-file-icon {
                transform: rotate(220deg);
                margin-left: 5px;

                &.isDownload {
                    transform: rotate(0deg);
                    height: 13px;

                    path {
                        fill: #9199a1;
                    }
                }
            }

            &-button-cancel {
                position: absolute;
                right: 5px;
                top: 5px;
                color: #d8d8d8;
                cursor: pointer;

                &:hover {
                    color: #ff4d6d;
                }
            }
        }
    }
}
