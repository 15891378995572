@use "~styles/constants" as *;

.user__detail {
    width: 100%;
    display: flex;
    justify-content: center;
    padding-top: 25px;
    
    
    &--container {
        width: 290px;
    }

    &--header {
        padding: 10px 20px 25px;
        margin: 0;
        text-align: center;

        &-title {
            font-size: 28px;
            font-weight: 500;
            color: #292a2a;
        }
    }
}
