.loading-card {
    width: 100%;
    height: 100%;
    padding: 6px 10px;
    border-radius: 6px;

    &__title {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 95%;
        height: 60%;

        &--tooltip {
            width: 80%;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }

        span {
            font-family: 'Frutiger-65-bold', sans-serif;
            font-weight: 700;
            line-height: 24px;
            letter-spacing: 1.5px;
            font-size: 14px;
        }
    }

    &__display {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 95%;
        height: 35%;
        overflow: hidden;
        border-top: 1px solid #d8d8d8;

        span {
            font-family: 'Frutiger-45-Light', sans-serif;
            font-size: 12px;
            line-height: 10px;
        }

        &--box-loading {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
        }
    }
}