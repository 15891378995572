@use "~styles/constants" as *;

.scd-program-output-policies {
    height: 100%;
    padding: 10px 20px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;

    &__container {
        max-width: 1115px;
        min-height: 450px;
        width: 100%;
    }

    &__header {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: space-between;
        flex-wrap: nowrap;
        padding: 20px 0;
        width: 100%;

        &-container {
            display: flex;
            width: 100%;
            justify-content: space-between;

            &--title {
                font-size: 34px;
                font-weight: 500;
                color: #545454;
                margin: 0;

                @include responsive(sm) {
                    text-align: center;
                    font-size: 32px;
                }

                @include responsive(s) {
                    text-align: center;
                    font-size: 32px;
                    margin-bottom: 20px;
                }

                @include responsive(ss) {
                    text-align: center;
                    font-size: 29px;
                }
            }

            &--info {
                max-width: 100%;
                display: flex;
                justify-content: space-between;
                align-items: center;
                gap: 25px;
                margin: 15px 0;

                &-item {
                    font-size: 19px;
                    color: #bac0c2;
                }

                &-status {
                    display: inline-flex;
                    align-self: initial;
                    font-size: 11px;
                    align-items: center;
                    white-space: nowrap;
                    height: 25px;
                    font-weight: 500;
                    border-radius: 5px;
                    padding: 10px;
                    color: #343434;

                    &.INACTIVE {
                        color: #ffffff;
                        background-color: #db6968;
                    }

                    &.ACTIVE {
                        background-color: #b8d759;
                    }
                }
            }
        }

        .MuiButton-contained,
        .MuiButton-contained:hover {
            max-width: unset;
        }

        &--buttons-content {
            padding: 20px 0px;
            display: flex;
            width: 100%;

            &-filter-button {
                padding-right: 10px;
            }

            &-error-arrow {
                content: $svg_error_arrow;
                width: 30px;
                height: 30px;
                margin-top: 8px;
                margin-left: 10px;
                cursor: pointer;
            }

            &-align-right {
                width: 100%;
                justify-content: flex-end;
                display: flex;
            }
        }

        @include responsive(s) {
            flex-direction: column;
        }
    }

    &__table {
        @include responsive(s) {
            width: 100%;
            overflow-x: auto;
        }

        .page-container--table {

            display: inline-block;
            height: calc(100vh - 420px);

            @media screen and (min-height: 850px){
                height: 400px;
            }

            tbody {
                display: block;
                height: 100%;
            }

            thead {
                width: 100%;
                
                &:first-child{
                    display: inline-block;
                }
            }

            &.EMPTY_COLLECTION {
                tbody {
                    min-height: 0px;
                    height: min-content;
                }
            }

            &-container {
                scrollbar-color: #a4a4a4 #e1e2e6;
                scrollbar-width: auto;
            }

            &-container::-webkit-scrollbar {
                width: 4px;
                background-color: #e1e2e6;
            }

            &-container::-webkit-scrollbar-thumb {
                width: 3px;
                background: #a4a4a4;
            }

            th {
                &:nth-child(1) {
                    width: 20%;
                }
                &:nth-child(2) {
                    width: 20%;
                }
                &:nth-child(3) {
                    width: 20%;
                }
                &:nth-child(4) {
                    flex: 1;
                }
            }
            td {
                &:nth-child(1) {
                    width: 20%;
                }
                &:nth-child(2) {
                    width: 20%;
                    
                }
                &:nth-child(3) {
                    width: 20%;
                }
                &:nth-child(4) {
                    flex: 1;
                }
         
                &:nth-child(6) {
                    .button__style--remove-icon--icon {
                        width: 24px;
                        height: 24px;
                    }
                }
                
            }
            
            &-cell-title {
                &--info-status {
                    display: inline-flex;
                    align-self: initial;
                    font-size: 11px;
                    align-items: center;
                    white-space: nowrap;
                    height: 25px;
                    font-weight: 500;
                    border-radius: 5px;
                    padding: 10px;
                    color: #343434;

                    &.INACTIVE {
                        color: #ffffff;
                        background-color: #db6968;
                    }
                    
                    &.ACTIVE {
                        background-color: #b8d759;
                    }
                }
            }
        }

        &-not-found {
            width: 100%;
            height: 260px;
            display: flex;
            justify-content: center;
            align-items: center;
            font-family: 'Frutiger';
            flex-direction: column;

            &-image {
                content: $svg_piggy_bank;
                width: 100px;
                height: 100px;
            }

            &-title {
                font-size: 26px;
                line-height: 24px;
                color: #d8d8d8;
                padding-top: 10px;
            }

            &-subtitle {
                font-size: 16px;
                line-height: 24px;
                color: #d8d8d8;
                padding-top: 10px;
            }
        }
        
    }
}