.monthly-commission-tax-file-input {
    font-family: Frutiger;
    display: flex;
    flex-direction: column;

    &__label {
        display: flex;
        flex-direction: column;

        &--title {
            color: #072056;
            font-size: 15px;
        }

        &--description {
            color: #a5a8aa;
            font-size: 13px;
        }
    }

    label {
        margin-top: 7px;
        margin-bottom: 0px;
        width: 70px;
    }

    &__description-icon {
        color: #d8d8d8;
        cursor: pointer;

        &--popover {
            padding: 15px;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 195px;
            font-size: 11px;
            font-family: 'Frutiger-light';
            color: #072056;
            border-radius: 50px;
            box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
            border-radius: 5px;
        }
    }

    &__file {
        cursor: pointer;
        display: flex;
        align-items: center;
        margin-left: 3px;

        &.disabled {
            opacity: 0.3;
            cursor: default;
        }

        &--label {
            color: #9199a1;
            font-family: 'Frutiger-55';
            border-bottom: 1px solid #9199a1;
            font-size: 14px;
            font-weight: 500;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            display: block;
            line-height: 1.2;
        }

        &--icon {
            color: #9199a1;
            margin-right: 6px;
            height: 14px;
        }

        &--error-message {
            text-align: left;
            font-size: 0.75rem;
            width: 100%;
            color: #f44336;
            font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
            font-weight: 400;
            letter-spacing: 0.03333em;
        }

        &--selected {
            width: 136px;
            border-radius: 6px;
            color: #9199a1;
            margin-top: 2px;
            display: flex;
            position: relative;
            align-items: center;

            &-value {
                padding: 0px 3px;
                font-family: 'Frutiger-light';
                font-size: 12px;
                border-bottom: #9199a1 1px solid;
                width: 93px;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;

                &.isDownload {
                    cursor: pointer;
                }
            }

            .MuiSvgIcon-fontSizeSmall {
                font-size: 16px;
            }

            &-file-icon {
                &.isDownload {
                    transform: rotate(0deg);
                    height: 13px;

                    path {
                        fill: #9199a1;
                    }
                }
            }

            &-button-cancel {
                position: absolute;
                right: 3px;
                top: 2px;
                color: #d8d8d8;
                cursor: pointer;

                &:hover {
                    color: #ff4d6d;
                }
            }
        }
    }
}
