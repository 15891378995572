$drawer-width: 292px;
$closed-drawer-width: 96px;

$transition-duration-easing-sharp: 195ms;
$transition-duration-entering-screen: 195ms;
$transition-duration-leaving-screen: 195ms;
$transition-easing-sharp: cubic-bezier(0.4, 0, 0.6, 1);

.menu-header {
    width: calc(100% - #{$closed-drawer-width});
    height: 80px;
    background: transparent;
    border-radius: 0;
    box-shadow: none;
    position: fixed;
    top: 0;
    right: 0;

    transition: width $transition-duration-easing-sharp $transition-easing-sharp,
                margin $transition-duration-easing-sharp $transition-easing-sharp;

    &.isOpen {
        width: calc(100% - #{$drawer-width});
        transition: width $transition-duration-entering-screen $transition-easing-sharp,
                    margin $transition-duration-entering-screen $transition-easing-sharp;
    }
}

.MuiDrawer-root {
    .drawer {
        z-index: 10;
        width: $closed-drawer-width;
        background: #072056;
        border-radius: 0;
        box-shadow: none;
        border: none;
        overflow-x: hidden;
        transition: width $transition-duration-entering-screen $transition-easing-sharp;
        white-space: nowrap;
        &::-webkit-scrollbar {
            width: 4px;
            background-color: #e1e2e6;
        }

        &::-webkit-scrollbar-thumb {
            width: 3px;
            background: #a4a4a4;
        }
    }

    .drawer.isOpen {
        width: $drawer-width;
    }
}
