@use "~styles/constants" as *;

.button__style--outlined-legacy {
    .MuiButton-label {
        justify-content: center;
        margin-top: 3px;
    }

    .MuiButton-startIcon {
        height: 24.5px;
        margin-top: 5px;
    }

    &.small {
        .MuiButton-contained:hover,
        .MuiButton-contained {
            font-size: 0.75rem;
            letter-spacing: 1.29px;
            max-height: 36px;
            max-width: 110px;
        }
    }

    &.medium {
        .MuiButton-contained:hover,
        .MuiButton-contained {
            font-size: 0.8125rem;
            letter-spacing: 1.29px;
            max-height: 40px;
            padding: 5px 15px;
        }
    }

    &.large {
        .MuiButton-contained:hover,
        .MuiButton-contained {
            font-size: 0.8125rem;
            letter-spacing: 1.29px;
            max-height: 40px;
            padding: 5px 5px;
            width: 200px;
            height: 50px;
        }
    }

    .MuiButton-contained:hover,
    .MuiButton-contained {
        background-color: #ffff;
        border: solid 1px #3F41D1;
        box-shadow: unset;
        color: #3F41D1;
        font-size: 14px;
        letter-spacing: 1.5px;
        border-radius: 10px;
        width: 145px;
        height: 100%;
        max-width: 200px;
        max-height: 46px;
        padding: 12px 43px;
        outline: none;
        font-weight: 400;

        &.Mui-disabled {
            background-color: $white_two;
            border-color: #ececec;
            color: #c6c6c6;
        }
    }

    .button--arrow {
        color: $brown_grey_three;
    }
}
