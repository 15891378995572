@use '~styles/constants' as *;

.provisional-limelight {
    height: 100%;
    padding: 10px 20px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;

    &__container {
        max-width: 1115px;
        min-height: 450px;
        width: 100%;
    }

    &__header {
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        padding: 20px 0;
        width: 100%;

        &-container {
            display: flex;
            flex-direction: column;
            width: 100%;

            &--content {
                display: flex;
                justify-content: space-between;

                .page-container--table-status {
                    display: inline-flex;
                    font-size: 11px;
                    align-items: center;
                    white-space: nowrap;
                    justify-content: flex-start;
                    height: 24px;
                    font-weight: 500;
                    border-radius: 5px;
                    padding: 10px;
                    margin-top: 3px;
                    
                    &.CLOSED {
                        background-color: #b8d759;
                    }
                    
                    &.CONCLUDED {
                        background-color: #6acff1;
                    }
                }
            }

            &--title {
                display: flex;
                align-items: center;
                font-size: 34px;
                font-weight: 500;
                color: #545454;
                margin: 0;
            }

            &--subtitle {
                font-size: 19px;
                color: #bac0c2;
                margin: 15px 0px;
            }

            &--buttons {
                display: flex;
                align-items: center;
                justify-content: flex-end;
            }
            
        }
        &--select-operations {
            display: flex;
            align-items: center;
            cursor: pointer;
    
            &__icon {
                content: $svg_select;
            }
            
            &__value {
                letter-spacing: 1.5px;
                color: #3f41d1;
                font-weight: 400;
                font-size: 15px;
                margin: 0px 8px;
                line-height: 24px;
            }
        }

        @media screen and (max-height: 720px) {
            padding: 20px 0 0 0;
            height: 120px;
       
            &--subtitle {
                margin: 10px 0;
            }
        }

        @include responsive(s) {
            flex-direction: column;
        }
    }

    &__table {
        width: 100%;

        thead {
            .page-container--table-cell {
                height: 35px;
                border-bottom: 2px solid #d8d8d8;
                font-family: "Frutiger";
                color: #bac0c2;

                &.size {
                    width: calc(100% - 14px);
                }
            }
        }

        
        tbody {
            display: block;
            height: 400px;
            overflow-y: auto;

            tr{
                border-bottom: 1px solid #d8d8d8;
            }

            @media screen and (max-height: 720px) {
                height: 350px;
            }
        }

        .page-container--table-cell {
            height: 50px;
        }

        .page-container--table {
         
            
            th {
                &:nth-child(1) {
                    width: 15%;
                
                }
                &:nth-child(2) {
                    width: 13.5%;
                }
                &:nth-child(3) {
                    width: 12%;
                  
                }
                &:nth-child(4) {
                    flex: 1;
                }
             
            }
            td {
                margin-right: 11px;
                display: flex;
                align-items: center;

                &:nth-child(1) {
                    width: 15%;
                }
                &:nth-child(2) {
                    width: 14%;
                }
                &:nth-child(3) {
                    width: 12%;
                }
                &:nth-child(4) {
                    width: 58%;
                    div {
                        width: 80%;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                    }

                    @media screen and (max-width: 768px) {
                        width: 35%;
                    }
                }
                &:nth-child(5) {
                    flex: 1;
                }
            }
        }

        &--content {
            min-height: 330px;
        }
    }
}