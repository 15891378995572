$svg_ops: url('~images/ops.svg');
$svg_ops-2: url('~images/ops-2.svg');

$svg_fechar-3: url('~images/fechar-3.svg');
$svg_fechar: url('~images/fechar.svg');

$svg_logo: url('~images/logo.svg');
$svg_logo_white: url('~images/logo-white.svg');

$svg_paper_plane: url('~images/paper-plane.svg');

$svg_positivo: url('~images/positivo.svg');

$svg_edit_disabled: url('~images/edit-disabled.svg');
$svg_edit_rest: url('~images/edit-rest.svg');
$svg_edit_hover: url('~images/edit-hover.svg');

$svg_visualize_disabled: url('~images/visualize-disabled.svg');
$svg_visualize_rest: url('~images/visualize-rest.svg');
$svg_visualize_hover: url('~images/visualize-hover.svg');

$svg_trash_disabled: url('~images/trash-disabled.svg');
$svg_trash_rest: url('~images/trash-rest.svg');
$svg_trash_hover: url('~images/trash-hover.svg');

$svg_suitcase_disabled: url('~images/suitcase-disabled.svg');
$svg_suitcase_rest: url('~images/suitcase-rest.svg');
$svg_suitcase_hover: url('~images/suitcase-hover.svg');

$svg_close_message_modal: url('~images/close-modal-message.svg');
$svg_close_hover: url('~images/close-hover.svg');
$svg_close_rest: url('~images/close-rest.svg');

$svg_back_simulation: url('~images/back-simulation.svg');

$svg_blue_search: url('~images/blue-search.svg');

$svg_arrow: url('~images/arrow.svg');
$svg_arrow_download: url('~images/arrow-download.svg');

$svg_search: url('~images/search.svg');

$svg_plus_disabled: url('~images/plus-disabled.svg');
$svg_plus_rest: url('~images/plus-rest.svg');
$svg_plus_hover: url('~images/plus-hover.svg');

$svg_calendar: url('~images/calendar.svg');
$svg_calendar_rest: url('~images/calendar-rest.svg');

$svg_error_arrow: url('~images/error-arrow.svg');

$svg_piggy_bank: url('~images/piggy-bank.svg');

$svg_description: url('~images/description.svg');
$svg_description_active: url('~images/description-active.svg');
$svg_description_rest: url('~images/description-rest.svg');
$svg_description_rest_alternative: url('~images/description-rest-alternative.svg');
$svg_description_hover: url('~images/description-hover.svg');
$svg_description_disabled: url('~images/description-disabled.svg');

$svg_move_arrow: url('~images/move-arrow.svg');

$svg_no_disturb: url('~images/no-disturb.svg');

$svg_error: url('~images/error.svg');

$svg_contract_rest: url('~images/contract-rest.svg');
$svg_contract_hover: url('~images/contract-hover.svg');

$svg_select: url('~images/select.svg');

$svg_upload: url('~images/upload.svg');

$svg_payment: url('~images/payment.svg');
$svg_payment_rest: url('~images/payment-rest.svg');
$svg_payment_hover: url('~images/payment-hover.svg');
$svg_payment_disabled: url('~images/payment-disabled.svg');

$svg_edit_report: url('~images/edit-report.svg');

$svg_report_dollar_sign: url('~images/report-dollar-sign.svg');

$svg_copy_icon: url('~images/copy-icon.svg');

$svg_more_options_disabled: url('~images/more-options-disabled.svg');
$svg_more_options_rest: url('~images/more-options-rest.svg');
$svg_more_options_hover: url('~images/more-options-hover.svg');

$svg_bank: url('~images/bank-rest.svg');
$svg_bank_hover: url('~images/bank-hover.svg');
$svg_bank_active: url('~images/bank-active.svg');
$svg_bank_disabled: url('~images/bank-disabled.svg');

$svg_document: url('~images/document-rest.svg');
$svg_document_hover: url('~images/document-hover.svg');
$svg_document_disabled: url('~images/document-disabled.svg');
$svg_document_active: url('~images/document-active.svg');

$svg_policy: url('~images/policy.svg');
$svg_policy_hover: url('~images/policy-hover.svg');
$svg_policy_disable: url('~images/policy-disable.svg');
$svg_error_outline_icon: url('~images/error-outline-icon.svg');