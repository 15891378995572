@use "~styles/constants" as *;

.batch-detail {
    height: 100%;
    padding: 10px 20px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;

    &__container {
        max-width: 1115px;
        min-height: 450px;
        width: 100%;

        &-title {
            margin: 25px 0px -10px 0px;

            font-family: 'Frutiger-55';
            font-weight: 500;
            font-size: 20px;
            line-height: 24px;
        }

        &-status {
            display: inline-flex;
            font-size: 11px;
            align-items: center;
            white-space: nowrap;
            justify-content: flex-start;
            height: 24px;
            font-weight: 500;
            border-radius: 5px;
            padding: 10px;
    
            &.OPEN {
                background-color: #f2c466;
            }
    
            &.CLOSED {
                background-color: #b8d759;
            }
    
            &.CONCLUDED {
                background-color: #6acff1;
            }
    
            &.ERROR {
                background-color: #ff7978;
            }
    
            &.PROCESSING {
                background-color: #d3d0cb;
            }
        }
    }
}