.cession-integration-webcred-footer {
    width: 100%;
    height: 90px;
    background: #ffff;
    font-family: 'Frutiger', sans-serif;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0px -5px 10px 0px rgba(0, 0, 0, 0.05);
    position: fixed;
    bottom: 52px;
    left: 0;
    right: 0;

    &__button-box {
        width: 100%;
        max-width: 1116px;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        column-gap: 16px;
        position: relative;
    }

    &__message-box {
        width: 100%;
        display: flex;
        justify-content:center;
        align-items: center;
        position: absolute;
        left: 0;
        z-index: 0;
    }
}