.chunk-cession-finalize-modal {
    
    &__loading-box {
        width: 100%;
        height: 80px;
    }

    &__content-title {
        font-weight: 500;
        font-family: 'Frutiger', sans-serif;
        margin-bottom: 16px;
    }


    &__content-subtitle {
        font-weight: 500;
        font-family: 'Frutiger', sans-serif;
        margin-bottom: 4px;
    }
    &__text-box {
        display: flex;
        flex-direction: column;
        row-gap: 4px;

    }
    &__text-data {
        font-weight: 400;
        font-family: 'Frutiger', sans-serif;
    }
}