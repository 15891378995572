@use '~styles/constants' as *;

.monthly-commission-release-button {
    display: flex;

    .MuiButton-contained {
        border: $organization_primary 1px solid;
        border-right: none;
        box-shadow: unset;
        background-color: transparent;
    }

    .MuiButton-contained:hover {
        background-color: transparent;
    }

    .MuiButton-label {
        justify-content: center;
    }

    .MuiButton-contained:hover,
    .MuiButton-contained {
        color: $organization_primary;
        font-size: 0.875rem;
        font-family: Frutiger;
        font-weight: 400;
        letter-spacing: 1.5px;
        border-radius: 10px 0px 0px 10px;
        width: 100%;
        max-width: 255px;
        min-width: 130px;
        min-height: 46px;
        max-height: 46px;
        @include responsive(s) {
            max-width: 130px;
            min-width: 130px;
        }
    }

    .MuiButton-contained.Mui-disabled {
        background: $organization_primary_disabled;
        cursor: not-allowed;

        span {
            color: white;
        }
    }
}

.monthly-commission-release-button-menu {
    border: $organization_primary 1px solid;
    min-height: 46px;
    width: 45px;
    border-radius: 0px 10px 10px 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: $organization_primary;
    cursor: pointer;

    &.active {
        background-color: #3f41d1;
        color: white;
        cursor: pointer;
    }
}

.monthly-commission-release-menu {
    .MuiList-padding {
        padding: 0 !important;
    }

    .MuiPaper-root {
        width: 175px;
        border: 1px solid #3f41d1;
        color: #3f41d1;
        box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
        border-radius: 5px;
        background-color: white;

        .MuiMenuItem-root {
            font-size: 15px;
            color: #3f41d1;
            font-family: 'Frutiger-light';
            letter-spacing: 0.5px;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
            height: 43px;
            background-color: white;

            &:hover {
                background-color: #3f41d1;
                color: white;
            }

            &:active {
                background-color: #3f41d1;
                color: white;
            }
        }
    }
}
