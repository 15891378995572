@use "~styles/constants" as *;

.scd-program {
    width: 100%;
    height: 100%;
    padding: 0px 24px;
    display: flex;
    flex-direction: column;
    align-items: center;

    &__container {
        width: 100%;
        max-width: 1116px;
        min-height: 440px;
    }

    &__header {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        flex-wrap: nowrap;
        width: 100%;

        row-gap: 12px;

        &-container {
            width: 100%;
            display: flex;
            justify-content: space-between;

            &--title {
                font-size: 34px;
                font-weight: 500;
                color: #545454;
                margin: 0;
            }

            &--align-right {
                width: 100%;
                justify-content: flex-end;
                display: flex;
                align-items: center;
            }
        }

        &--buttons-content {
            display: flex;
            width: 100%;

            &-filter-button {
                padding-right: 10px;
            }

            &-error-arrow {
                content: $svg_error_arrow;
                width: 30px;
                height: 30px;
                margin-top: 8px;
                margin-left: 10px;
                cursor: pointer;
            }

            &-align-right {
                width: 100%;
                justify-content: flex-end;
                display: flex;
            }
        }

        @include responsive(s) {
            flex-direction: column;
        }

    }

    &__table {
        width: 100%;
        margin: 8px 0px 0px;
        
        .page-container--table {
            display: inline-block;
            
            @media screen and (min-height: 850px){
                height: 400px;
            }
            thead {
                width: 100%;

                &:first-child{
                    display: inline-block;
                }
            }
            
            tbody {
                height: calc(100vh - 396px);
                display: block;
                overflow-y: auto;
            }

            &.CHIPS_ACTIVE {
                tbody {
                    height: calc(100vh - 432px);

                }
            }

            &.EMPTY_COLLECTION {
                tbody {
                    min-height: 0px;
                    height: min-content;
                }
            }

            &-container::-webkit-scrollbar {
                width: 4px;
                background-color: #e1e2e6;
            }

            &-container::-webkit-scrollbar-thumb {
                width: 3px;
                background: #a4a4a4;
            }

            th {
                &:nth-child(1) {
                    width: 23.8%;
                }
                &:nth-child(2) {
                    width: 10%;
                }
                &:nth-child(3) {
                    width: 10%;
                }
                &:nth-child(4) {
                    flex: 1;
                }

            }
            td {
                display: flex;
                align-items: center;
                overflow: hidden;
                text-overflow: ellipsis;
                line-height: 1.2rem;
                &:nth-child(1) {
                    width: 24%;
                }
                &:nth-child(2) {
                    width: 10%;
                    span {
                        color: #163c6e;
                        border: 1px solid #163c6e;
                        height: 25px;
                        width: 40px;
                        border-radius: 4px;
                    }
                }
                &:nth-child(3) {
                    width: 10%;
                }
                &:nth-child(4) {
                    flex: 1;
                }
                &:nth-child(8) {
                    button {
                        div {
                            height: 27px;
                        }
                    }
                }
                &:nth-child(9) {
                    button {
                        div {
                            height: 23px;
                        }
                    }
                }
            }
            @include responsive(sm) {
                th {
                    &:nth-child(1) {
                        width: 100%;
                    }
                }
                td {
                    &:nth-child(1) {
                        width: 100%;
                    }
                }
            }

            @include responsive(s) {
                th {
                    &:nth-child(1) {
                        width: 100%;
                    }
                }
                td {
                    &:nth-child(1) {
                        width: 100%;
                    }
                }
            }

            &-status {
                display: inline-flex;
                align-self: initial;
                font-size: 11px;
                align-items: center;
                white-space: nowrap;
                height: 25px;
                font-weight: 500;
                border-radius: 5px;
                padding: 10px;
                color: #343434;

                &.INACTIVE {
                    color: #ffffff;
                    background-color: #db6968;
                }

                &.ACTIVE {
                    background-color: #b8d759;
                }
            }

        }

        &-not-found {
            width: 100%;
            height: 260px;
            display: flex;
            justify-content: center;
            align-items: center;
            font-family: 'Frutiger';
            flex-direction: column;

            &-image {
                content: $svg_piggy_bank;
                width: 100px;
                height: 100px;
            }

            &-title {
                font-size: 26px;
                line-height: 24px;
                color: #d8d8d8;
                padding-top: 10px;
            }

            &-subtitle {
                font-size: 16px;
                line-height: 24px;
                color: #d8d8d8;
                padding-top: 10px;
            }
        }

        &--content {
            min-height: 330px;
        }

        &--cell-icon {
            @include responsive(sm) {
                width: 7%;
            }

            @include responsive(s) {
                width: 7%;
            }
        }

    }

    &--container {
        width: 350px;
        display: flex;
        align-items: center;
        flex-direction: column;
        margin-top: 25px;
        position: relative;
        min-height: 500px;
    }

    &--header {
        display: flex;
        justify-content: flex-start;
        width: 100%;
        padding: 10px 20px 25px;

        &-title {
            font-size: 28px;
            font-weight: 500;
            text-align: center;
            color: #292a2a;
            width: 100%;
            margin: 0;
        }
    }
}
