@use "~styles/constants" as *;

.scd-batch-generate-cession {
    height: 100%;
    padding: 10px 20px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;

    &__create {
        width: 100%;
        display: flex;
        justify-content: center;
    }

    &__manager {
        display: flex;
        align-items: center;
        flex-direction: column;
        width: 100%;

        &--button {
            width: 100%;
            padding: 0 25px;
        }
    }

    &--container {
        width: 350px;
        display: flex;
        align-items: center;
        flex-direction: column;
        margin-top: 25px;
        position: relative;
        min-height: 500px;
    }

    &--header {
        display: flex;
        justify-content: flex-start;
        width: 100%;
        padding: 10px 20px 25px;

        &-title {
            font-size: 28px;
            font-weight: 500;
            text-align: center;
            color: #292a2a;
            width: 100%;
            margin: 0;
            text-align: center;
        }
    }

    &--form {
        width: 100%;
        margin: 0 auto 50px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;

        &-field {
            display: flex;
            width: 100%;
            margin-bottom: 5px;
            min-height: 65px;
            padding: 0 20px;
        }

        &-checkbox {
            display: flex;
            width: 100%;
            margin-bottom: 5px;
            min-height: 55px;
            padding: 0 20px;
        }

        &-card {
            display: flex;
            flex-direction: column;
            align-items: center;
            margin: 10px 0 5px 0;
            min-height: 45px;
            width: 100%;

            &-field {
                margin-bottom: 15px;
            }

            &-double-fields {
                margin-bottom: 15px;
                display: flex;
                gap: 5px;

                div:nth-child(1),dl:nth-child(1) {
                    width: 195%;
                }
            }

            &--field-error {
                display: inline-block;
                text-align: left;
                font-size: 0.75rem;
                width: 100%;
                color: #f44336;
                font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
                font-weight: 400;
                line-height: 1.66;
                letter-spacing: 0.03333em;
            }

            &--error-message {
                text-align: left;
                font-size: 0.75rem;
                width: 100%;
                color: #f44336;
                padding: 3px 29px;
                font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
                font-weight: 400;
                line-height: 1.66;
                letter-spacing: 0.03333em;
                margin-right: 20px;
            }
        }

        &-buttons {
            display: flex;
            justify-content: space-between;
            width: 100%;
            padding: 0 25px;
            margin-top: 30px;
        }
    }

    &__container {
        max-width: 1115px;
        width: 100%;
    }

    &__header {
        padding: 20px 0;
        width: 100%;

        &--title {
            font-size: 34px;
            font-weight: 500;
            color: #545454;

            @include responsive(sm) {
                text-align: center;
                font-size: 32px;
            }

            @include responsive(s) {
                text-align: center;
                font-size: 32px;
            }

            @include responsive(ss) {
                text-align: center;
                font-size: 29px;
            }
        }

        &--button {
            background-color: #3f41d1;
            border-radius: 10px;
            max-height: 43px;
            color: #fff;
        }

        &--column-right {
            width: 100%;
            justify-content: flex-end;
            display: flex;

            @include responsive(sm) {
                margin-top: 15px;
                justify-content: center;
            }

            @include responsive(s) {
                margin-top: 15px;
                justify-content: center;
            }
        }
    }

    &__modal-alert {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        width: 90%;
        padding: 20px 4px;
        min-height: 200px;
        margin-left: 20px;
    
        &__message {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            margin-bottom: 15px;
        }
        
        span {
            font-family: "Frutiger-light";
            font-size: 15px;
        }
    }
    &__checkbox-container {
        margin-top: 20px;
    }
}