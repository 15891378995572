@use '~styles/constants' as *;

.tax-value-input {
    width: 160px;
    margin-right: 10px;
    margin-bottom: 5px;
    margin-top: 5px;
    position: relative;
    z-index: 1;

    &--content {
        display: flex;
    }

    &--icon {
        width: 18px;
        height: 18px;
        position: absolute;
        right: 0px;
        top: -13px;
        z-index: 2;
        cursor: pointer;
        opacity: 0;

        path {
            fill: #DB6968;
        }

        &.active {
            opacity: 1;
        }
    }
}
