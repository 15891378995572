@use "~styles/constants" as *;

.batch-reports {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding: 10px 20px 0;

    &__container {
        max-width: 1115px;
        min-height: 450px;
        width: 100%;

        &-title {
            margin: 25px 0px -10px 0px;
            font-family: 'Frutiger-55';
            font-weight: 500;
            font-size: 20px;
            line-height: 24px;
        }
    }

    &__content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: stretch;
        width: 300px;
        margin: 100px auto;
        gap: 20px;
    }

    &__slide {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 90px;
        position: fixed;
        bottom: 52px;
        left: 0;
        right: 0;
        background: #ffff;
        font-family: 'Frutiger';

        &-buttons {
            display: flex;
            justify-content: flex-end;
            align-items: center;
            width: 1115px;
            
            div:nth-child(1) {
                margin-right: 10px;
            }
            div:nth-child(2) {
                margin-right: 10px;
            }
        }
    }
}