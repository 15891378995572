@use 'styles/constants/colors' as *;
@use 'styles/constants/images' as *;
@use 'styles/constants/media-query' as *;

.page-container--table {
    border-collapse: collapse;
    border-spacing: 0;
    margin: 10px 0;
    width: 100%;    

    tbody {
        position: relative;
    }

    &-head {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        border-bottom: solid 2px #d8d8d8;
        color: #bac0c2;
        font-size: 0.75rem;
        height: 30px;        

        &-title {
            cursor: pointer;
            display: flex;
            font-family: 'Frutiger';
            letter-spacing: 1px;
            text-transform: uppercase;
            font-size: 11px;
            font-weight: bold;

            .page-container--table-head-arrow {
                margin-left: 5px;
            }
        }
        &-search {
            content: $svg_blue_search;
            cursor: pointer;
            height: 36px;
            margin-left: 7px;
            padding: 8px;
            width: 36px;
            opacity: 0.8;
            &:hover {
                opacity: 1;
            }
        }
    }
    &-cell {
        align-items: center;
        border-bottom: solid 1px #d8d8d8;
        color: #4A4A4A;
        display: flex;
        font-family: 'Frutiger-light';
        font-size: 14px;
        font-weight: 400;
        height: 50px;
        line-height: 2.43;
        overflow: hidden;
        text-overflow: ellipsis;
        width: 100%;

        &.newActive {
            opacity: 0.6;
        }

        &.disabled {
            opacity: 0.6;
        }

        &.isSelected {
            background-color: #ffffff;

            &:hover {
                background-color: #ffffff;
            }
        }

        &:hover {
            background-color: #fafafa;
        }

        &-title {
            width: 100%;
            display: flex;
            align-items: center;
            overflow: hidden;
            text-overflow: ellipsis;
        }
        &-text {
            display: flex;
            justify-content: center;
            width: 100% !important;
        }

        &-icon {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 100%;
            width: 5%;
            cursor: pointer;

            @include responsive(sm) {
                width: 7%;
            }

            @include responsive(s) {
                width: 13%;
            }

            a {
                display: flex;
                align-items: center;
                justify-content: center;
                color: #d8d8d8;
                font-size: 1.1rem;
                height: 100%;
                margin: 0 5px;
                width: 100%;
                &:hover {
                    color: #9e9e9e;
                }
            }

            .Mui-checked {
                color: #3f41d1 !important;
            }
        }
    }    

    &-infinite-scroll-cell {
        align-items: center;
        border: 1px solid #E3E3E3;
        color: #343434;
        display: flex;
        font-family: 'Frutiger-light';
        font-size: 14px;
        font-weight: 300;
        height: 50px;
        line-height: 2.43;
        overflow: hidden;
        text-overflow: ellipsis;
        width: 100%;
        border-radius: 10px;        

        &.MANUAL {
            border-left: 10px solid #9199a1;
        }

        &.DISBURSEMENT_BY_BATCH {
           border-left: 10px solid #072056;
        }

        &.DISBURSEMENT_TO_DEBTOR {
           border-left: 10px solid #32aacf;
        }
        &.MANUAL_DISBURSEMENT {
           border-left: 10px solid #6dbc70;
        }
        &.AMENDED_DISBURSEMENT {
           border-left: 10px solid #ED66C9;
        }
        &.INTERNAL_REPAYMENT {
           border-left: 10px solid #ef7d00;
        }
        &.EXTERNAL_DISBURSEMENT{
            border-left: 10px solid #b947ff;
        }

        &.FALLBACK {
            border-left: 10px solid #9199a1;
        }


        &.newActive {
            opacity: 0.6;
        }

        &.disabled {
            opacity: 0.6;
        }

        &.isSelected {
            background-color: #ffffff;

            &:hover {
                background-color: #ffffff;
            }
        }

        &:hover {
            background-color: #fafafa;
        }

        &-title {
            width: 100%;
            display: flex;
            align-items: center;
            overflow: hidden;
            text-overflow: ellipsis;
        }
        
        &-text {
            display: flex;
            justify-content: center;
            width: 100% !important;
        }

        &-icon {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 100%;
            width: 5%;
            cursor: pointer;

            @include responsive(sm) {
                width: 7%;
            }

            @include responsive(s) {
                width: 13%;
            }

            a {
                display: flex;
                align-items: center;
                justify-content: center;
                color: #d8d8d8;
                font-size: 1.1rem;
                height: 100%;
                margin: 0 5px;
                width: 100%;
                
                &:hover {
                    color: #9e9e9e;
                }
            }

            .Mui-checked {
                color: #3f41d1 !important;
            }
        }
    }

    &-new {
        align-items: center;
        border-bottom: solid 1px #d8d8d8;
        color: $dark;
        display: flex;
        font-family: 'Frutiger';
        font-size: 0.875rem;
        font-weight: 300;
        height: 55px;
        line-height: 2.43;
        overflow: hidden;
        text-overflow: ellipsis;
        width: 100%;
        padding: 35px 15px;
        background-color: white;
    }

    th,
    td {
        &:nth-child(1) {
            padding-left: 20px;
        }
        span {
            max-width: 100%;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }
}
