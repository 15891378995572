@use '~styles/constants' as *;

.simple-date-picker {
    width: 100%;
    display: flex;
    row-gap: 8px;
    column-gap: 16px;
    justify-content: flex-start;
    align-items: center;
    
    &--calendar-icon {
        position: absolute;
        content: $svg_calendar_rest;
        right: 8px;
        
    }

    &__input-box {
        width: 100%;
        height: 48px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        position: relative;
        background-color: $white_three;


        &:focus-within &--label {
            top: 4px;
            left: 8px;
            font-size: 10px;
        }

        &--label {
            font-size: 14px;
            font-weight: 500;
            color: $brown_grey;
            position: absolute;
            left: 8px;
            top: calc((100% - 21px) / 2);
            transition: top 0.3s ease;

            &.HAS_DATE {
                top: 4px;
                left: 8px;
                font-size: 10px;
            }
        }

        .react-datepicker-wrapper {
            width: 100%;
            height: 100%;

            .react-datepicker__input-container {
                width: 100%;
                height: 100%;

                input {
                    width: 100%;
                    height: 100%;
                    font-size: 16px;
                    border: 1px solid #e8ebec;
                    border-radius: 4px;
                    background: transparent;
                    padding: 20px 8px 4px;
                }
            }
        }

        .react-datepicker-popper {
            z-index: 999;
            .react-datepicker {
                .react-datepicker__header {
                    width: 280px;
                    background: $madison;
                    
                    
                    .react-datepicker__current-month {
                        color: $white_two;
                        font-size: 16px;
                    }
                    
   
                    .react-datepicker__day-names .react-datepicker__day-name {
                        width: 32px;
                        color: $white_two;
                        font-size: 16px;
                        padding: 0px 4px
                    }
                }
                
                .react-datepicker__month .react-datepicker__week {
                    .react-datepicker__day {
                        width: 32px;
                        padding: 4px;
                        font-size: 16px;

                        &:hover {
                            background: $dark_blue;
                            color: $white_two;
                        }

                        &--selected {
                            background: $madison;
                        }
                        
                        &--in-selecting-range {
                            background: $link_water;
                            color: $black;
                        }
                        &--outside-month {
                            color: $brown_grey_two;
                        }
                    }
                }
            }
        }
    }
}
