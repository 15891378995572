@use '~styles/constants' as *;

.issuer-policies {
    height: 100%;
    padding: 10px 20px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;

    &__container {
        max-width: 1115px;
        min-height: 450px;
        width: 100%;
    }

    &__header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-wrap: nowrap;
        padding: 20px 0;
        width: 100%;

        &--title {
            font-size: 34px;
            font-weight: 500;
            color: #545454;
            margin: 0;

            @include responsive(sm) {
                text-align: center;
                font-size: 32px;
            }

            @include responsive(s) {
                text-align: center;
                font-size: 32px;
                margin-bottom: 20px;
            }

            @include responsive(ss) {
                text-align: center;
                font-size: 29px;
            }
        }

        @include responsive(s) {
            flex-direction: column;
        }
    }

    &__table {
        overflow-x: auto;

        .page-container--table {
            height: calc(100vh - 425px);
            display: inline-block;

            thead {
                width: 100%;

                &:first-child {
                    display: inline-block;
                }
            }

            tbody {
                display: block;
                min-height: 320px;
                height: 100%;
            }

            &.EMPTY_COLLECTION {
                tbody {
                    min-height: 0px;
                    height: min-content;
                }
            }

            th {
                width: 32%;
            }

            td {
                width: 33%;

                &:nth-child(4) {
                    flex: 1;
                }
            }

            &-status {
                display: inline-flex;
                font-size: 11px;
                align-items: center;
                white-space: nowrap;
                justify-content: flex-start;
                height: 24px;
                font-weight: 500;
                border-radius: 5px;
                padding: 10px;
    
                &.ACTIVE {
                    background-color: #b8d759;
                }
                &.INACTIVE {
                    background-color: #ff7978;
                }
            }

            &-container {
                scrollbar-color: #a4a4a4 #e1e2e6;
                scrollbar-width: auto;
            }

            &-container::-webkit-scrollbar {
                width: 4px;
                background-color: #e1e2e6;
            }

            &-container::-webkit-scrollbar-thumb {
                width: 3px;
                background: #a4a4a4;
            }
        }

        &--content {
            min-height: 330px;
        }

        &-empty-filter {
            width: 100%;
            height: 260px;
            display: flex;
            justify-content: center;
            align-items: center;
            font-family: 'Frutiger';
            flex-direction: column;

            &-image {
                content: $svg_piggy_bank;
                width: 100px;
                height: 100px;
            }

            &-title {
                font-size: 26px;
                line-height: 24px;
                color: #d8d8d8;
                padding-top: 10px;
            }

            &-subtitle {
                font-size: 16px;
                line-height: 24px;
                color: #d8d8d8;
                padding-top: 10px;
            }
        }
    }

    &--container {
        width: 350px;
        display: flex;
        align-items: center;
        flex-direction: column;
        margin-top: 25px;
        position: relative;
        min-height: 500px;
    }

    &--header {
        display: flex;
        justify-content: center;
        width: 100%;
        padding: 10px 20px 25px;

        &-title {
            font-size: 28px;
            font-weight: 500;
            text-align: center;
            color: #292a2a;
            width: 100%;
            margin: 0;
        }
    }
}
