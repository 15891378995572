@use '~styles/constants' as *;

.scd-ccb-operation-create-batch {
    padding: 10px 20px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;

    &__container {
        max-width: 1115px;
        min-height: 450px;
        width: 100%;
    }

    &__header {
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        padding: 20px 0;
        width: 100%;
        margin-bottom: 10px;

        &-container {
            display: flex;
            width: 100%;

            &--title {
                font-size: 28px;
                font-weight: 500;
                color: #545454;
                margin: 0;
            }
        }
    }

    &__table {
        width: 100%;
        thead {
            width: 1115px;
            background-color: #FAFAFA;
            z-index: 2;
        }
        
        tbody {
            height: calc(100vh - 374px);
            overflow-x: auto;
            display: block;


            &::-webkit-scrollbar {
                width: 4px;
                background-color: #e1e2e6;
            }

            &::-webkit-scrollbar-thumb {
                width: 3px;
                background: #a4a4a4;
            }
     
        }

        .page-container--table {
            th {
                &:nth-child(1) {
                    width: 11%;
                }
                &:nth-child(2) {
                    width: 18%;
                }
                &:nth-child(3) {
                    width: 18%;
                }
                &:nth-child(4) {
                    width: 9%;
                }
                &:nth-child(5) {
                    width: 9%;
                }
                &:nth-child(6) {
                    width: 14%;
                }
                &:nth-child(7) {
                    width: 14%;
                }
            }
            td {
                margin-right: 11px;

                &:nth-child(1) {
                    width: 10%;
                }
                &:nth-child(2) {
                    width: 17.5%;
                    .batchless {
                        padding: 0px 6px;
                        color: #565a5b;
                        font-weight: 400;
                        font-size: 11px;
                        background: #f0f0f0;
                        border-radius: 4px;
                    }
                }
                &:nth-child(3) {
                    width: 17.2%;
                }
                &:nth-child(4) {
                    width: 8.2%;

                    span {
                        color: #163c6e;
                        border: 1px solid #163c6e;
                        height: 25px;
                        width: 40px;
                        border-radius: 4px;
                    }
                }
                &:nth-child(5) {
                    width: 8.1%;

                    span {
                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                        display: block;
                    }
                }
                &:nth-child(6) {
                    width: 12.2%;

                    span {
                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                        display: block;
                    }
                }

                &:nth-child(7) {
                    width: 13%;
                }

                &:nth-child(8) {
                    padding-left: 14px;
                }
            }

            &-status {
                display: inline-flex;
                font-size: 11px;
                align-items: center;
                white-space: nowrap;
                justify-content: flex-start;
                height: 24px;
                font-weight: 500;
                border-radius: 5px;
                padding: 10px;

                &.EXPORTED {
                    background-color: #6acff1;

                }

                &.ERROR {
                    color: #ffffff;
                    background-color: #FA4D56;
                }

                &.NEW {
                    color: #ffffff;
                    background-color: #42BE65;
                }

                &.BATCHLESS {
                    background-color: #bac0c2;
                }

                &.ARCHIVED {
                    color: #ffffff;
                    background-color: #A2A9B0;
                }
            }
        }

        &-empty-filter {
            width: 100%;
            height: 350px;
            display: flex;
            justify-content: center;
            align-items: center;
            font-family: 'Frutiger';
            flex-direction: column;

            &-title {
                font-size: 26px;
                line-height: 24px;
                color: #d8d8d8;
                padding-top: 10px;
            }

            &-subtitle {
                font-size: 16px;
                line-height: 24px;
                color: #d8d8d8;
                padding-top: 10px;
            }
        }
        &--content {
            min-height: 330px;
        }
    }
}
