@use '~styles/constants' as *;

.transfeera-balance {
    &--title {
        display: flex;
        align-items: center;
        justify-content: flex-start !important;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        font-family: "Frutiger-light";
        font-size: 14px;
        font-weight: 300;
        cursor: pointer;
        width: 100%;
    }

    &--error {
        content: $svg_error;
        width: 25px;
        height: 25px;
    }
}