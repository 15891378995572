@use '~styles/constants' as *;

.scd-monthly-commission-detail {
    padding: 10px 20px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    margin-top: 40px;

    &__container {
        max-width: 940px;
        min-height: 450px;
        width: 100%;
    }

    &__commission-info {
        margin: 25px 0px;
        display: flex;
        flex-direction: row;

        &--item {
            display: flex;
            flex-direction: column;
            width: 150px;
            padding: 8px 0px;
            position: relative;

            &-label {
                color: #565a5b;
                font-family: 'Frutiger-light';
                font-weight: 300;
                font-size: 16px;
                line-height: 19px;
            }

            &-value {
                color: #bac0c2;
                font-family: 'Frutiger-light';
                font-size: 16px;
                font-weight: 300;
            }
        }
    }

    &__tax-info {
        margin: 25px 0px;
        display: flex;
        flex-direction: row;
        width: 540px;
        align-items: center;
        border-radius: 6px;
        background: #ffffff;
        height: 53px;

        &--item {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 180px;
            padding: 8px 30px;
            position: relative;
            background: #ffffff;

            &:nth-child(1) {
                border-right: 1px solid #f0f0f0;
            }

            &:nth-child(2) {
                border-right: 1px solid #f0f0f0;
            }

            &__content {
                position: absolute;
                display: flex;
                flex-direction: column;
                width: 155px;
                border-radius: 6px;
                padding: 5px 10px;
                z-index: 2;
                height: 125px;
                top: -15px;

                &.active {
                    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.15);
                    background: #f7f8fb;
                }

                &-label {
                    color: #163c6e;
                    font-family: 'Frutiger-light';
                    font-weight: 300;
                    font-size: 14px;
                    line-height: 19px;

                    .MuiSvgIcon-root{
                        font-size: 15px;
                        margin-right: 5px;
                    }
                }

                &-value {
                    color: #163c6e;
                    font-family: 'Frutiger-light';
                    font-size: 14px;
                    font-weight: 300;
                    margin-bottom: 3px;
                }

                &-icon {
                    position: absolute;
                    right: 8px;
                    top: 10px;
                    cursor: pointer;

                    &.active {
                        transform: rotate(180deg);
                    }

                    .MuiSvgIcon-root {
                        color: #163c6e;
                    }
                }

                &-file-input {
                    padding: 6px 0px;
                }
            }
        }
    }

    &__header {
        width: 100%;
        display: flex;
        align-items: center;
        margin-bottom: 20px;
        height: 35px;

        &--title {
            font-size: 26px;
            font-weight: 500;
            color: #565a5b;
            margin: 0;
            display: flex;
            align-items: center;
            width: 73%;
        }

        &--last-update {
            color: #bac0c2;
            font-size: 14px;
            font-family: 'Frutiger-55';
            display: flex;
            align-items: center;
            width: 100%;

            &-icon {
                margin-right: 5px;
            }

            &-datetime {
                margin-left: 8px;
            }
        }

        &--action-buttons {
            width: 100%;
            display: flex;
            justify-content: flex-end;

            &--link {
                color: #3f41d1;
                font-weight: 500;
                font-size: 14px;
                font-family: 'Frutiger-55';
                letter-spacing: 1.5px;
                cursor: pointer;
            }
        }

        &--status {
            display: inline-flex;
            font-size: 11px;
            align-items: center;
            white-space: nowrap;
            justify-content: flex-start;
            height: 24px;
            font-weight: 500;
            border-radius: 5px;
            padding: 10px;
            margin-left: 20px;
            color: #343434;

            &.NEW {
                color: #ffffff;
                background-color: #db6968;
            }

            &.WAITING {
                background-color: #f2c466;
            }

            &.RELEASED {
                background-color: #b8d759;
            }

            &.TRANSFERRING {
                background-color: #6acff1;
            }

            &.PAID {
                color: #ffffff;
                background-color: #163c6e;
            }
        }
    }

    &__cards {
        display: flex;
        margin-top: 10px;
    }
}
