@use '~styles/constants' as *;

.monthly-commission-report-modal--container {
    margin-bottom: 15px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 25px;
    width: 750px;
    position: relative;

    &__inputs {
        display: flex;
        max-width: 100%;
        padding-left: 60px;
    }

    &__remove-arrow {
        width: 25px;
        cursor: pointer;
        margin-top: 12px;
        position: absolute;
        right: 120px;

        path {
            fill: #163c6e;
        }
    }

    &__comentary {
        cursor: pointer;
        margin-top: 17px;
        position: absolute;
        right: 70px;
        content: $svg_description;
        width: 20px;
        height: 20px;

        &.active {
            cursor: pointer;
            content: $svg_description_active;
        }

        &.disabled {
            content: $svg_description;
            cursor: default;
        }
    }

    &__tax-add-new-input {
        width: 160px;
        opacity: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 5px;
        margin-right: 10px;
        height: 50px;

        .MuiSvgIcon-root {
            color: #d8d8d8;
        }

        &:hover {
            width: 160px;
            background: #f6f6f6;
            border: 1px dashed #d8d8d8;
            border-radius: 6px;
            opacity: 1;
            cursor: pointer;
        }
    }
}

.monthly-commission-report-modal--header {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-bottom: 16px;
    position: relative;
    flex-direction: column;

    &-title {
        color: #565a5b;
        letter-spacing: 0.3px;
        line-height: 1.2;
        font-family: 'Frutiger-55';
        font-weight: 500;
        font-size: 24px;
        margin-top: 20px;
    }

    &-subtitle {
        font-family: 'Frutiger-Light';
        font-size: 15px;
        line-height: 22px;
        color: #545454;
        margin-bottom: 12px;
    }

    &-close-icon {
        position: absolute;
        right: 0px;
        top: -10px;
    }
}

.monthly-commission-report-modal--buttons {
    &-actions {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 25px;
        > :nth-child(2) {
            margin-left: 25px;
        }
    }
}


