.scd-operation-read-only-text-field {
    display: flex;
    flex-direction: column;
    padding: 15px 0px;
    

    &__value {
        color: #343434;
        font-size: 16px;
        line-height: 19px;
        font-family: 'Frutiger-light';
        padding: 5px 0px;
        border-bottom: 1px solid #f6f6f6;
        width: 90%;
        overflow-wrap: break-word;
    }

    &__label {
        color: #9199a1;
        font-size: 13px;
        line-height: 14px;  
        font-family: 'Frutiger-light';
    }
}
