@use "~styles/constants" as *;

.financial-institution {
  width: 100%;
  height: 100%;
  padding: 0px 24px;
  display: flex;
  flex-direction: column;
  align-items: center;

  &__container {
    max-width: 1116px;
    min-height: 440px;
    width: 100%;
  }

  &__header {
    width: 100%;

    display: flex;
    row-gap: 12px;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    flex-wrap: nowrap;

    &-container {
      display: flex;
      justify-content: space-between;
      width: 100%;

      &--title {
        font-size: 34px;
        font-weight: 500;
        color: #545454;
        margin: 0;
      }


      &--align-right {
        width: 100%;
        justify-content: flex-end;
        display: flex;
        align-items: center;
      }


      &--buttons-content {
        align-items: flex-end;
      }
    }

    &--buttons-content {
      display: flex;
      width: 100%;

      &-filter-button {
        padding-right: 10px;
      }

      &-error-arrow {
        content: $svg_error_arrow;
        width: 30px;
        height: 30px;
        margin-top: 8px;
        margin-left: 10px;
        cursor: pointer;
      }

      &-align-right {
        width: 100%;
        justify-content: flex-end;
        display: flex;
      }
    }

    @include responsive(s) {
      flex-direction: column;
    }
  }


  &__table {
    width: 100%;
    margin: 8px 0px 0px;

    .page-container--table {
      display: inline-block;

      @media screen and (min-height: 850px) {
        height: 400px;
      }



      thead {
        width: 100%;

        &:first-child {
          display: inline-block;
        }
      }

      tbody {
        height: calc(100vh - 396px);
        display: block;
        overflow-y: auto;
      }

      &.CHIPS_ACTIVE {
        tbody {
          height: calc(100vh - 432px);

        }
      }

      &.EMPTY_COLLECTION {
        tbody {
          min-height: 0px;
          height: min-content;
        }
      }

      th {
        &:nth-child(1) {
          width: 30%;
        }

        &:nth-child(2) {
          width: 30%;
        }

        &:nth-child(3) {
          width: 30%;
        }

        &:nth-child(4) {
          width: 5%;
        }

        &:nth-child(5) {
          width: 5%;
        }
      }

      td {

        overflow: hidden;
        text-overflow: ellipsis;

        &:nth-child(1) {
          width: 30%;
        }

        &:nth-child(2) {
          width: 30%;
        }

        &:nth-child(3) {
          width: 30%;
        }

        &:nth-child(4) {
          width: 5%;
        }

        &:nth-child(5) {
          width: 5%;
        }
      }

      @include responsive(s) {
        th {
          &:nth-child(1) {
            width: 150px;
          }

        }
        td {
          &:nth-child(1) {
            width: 100%;
          }

          &:nth-child(2) {
            width: 40px;
          }

          &:nth-child(3) {
            width: 40px;
          }

          &:nth-child(4) {
            width: 40px;
          }

        }
      }

      &-status {
        display: inline-flex;
        padding: 10px;
        font-size: 11px;
        align-items: center;
        white-space: nowrap;
        justify-content: center;
        height: 24px;
        font-weight: 500;
        border-radius: 5px;

        &.ACTIVE {
          background-color: #b8d759;
        }

        &.INACTIVE {
          background-color: #db6968;
          color: #ffffff;
        }
      }

      &-container::-webkit-scrollbar {
        width: 4px;
        background-color: #e1e2e6;
      }

      &-container::-webkit-scrollbar-thumb {
        width: 3px;
        background: #a4a4a4;
      }
    }

    &--content {
      min-height: 330px;
    }

    &-empty-filter {
      width: 100%;
      height: 260px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-family: 'Frutiger';
      flex-direction: column;

      &-image {
        content: $svg_piggy_bank;
        width: 100px;
        height: 100px;
      }

      &-title {
        font-size: 26px;
        line-height: 24px;
        color: #d8d8d8;
        padding-top: 10px;
      }

      &-subtitle {
        font-size: 16px;
        line-height: 24px;
        color: #d8d8d8;
        padding-top: 10px;
      }
    }
  }
}
