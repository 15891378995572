@use "~styles/constants" as *;

.readonly__text-field {
    font-family: Frutiger;
    border-bottom: solid 1px $very_light_pink_two;
    margin: 10px 0 5px;
    padding-right: 15px;
    width: 100%;
    
    &-label {
        color: $brown_grey;
        font-size: 0.75rem;
        font-weight: 400;
        height: 14px;
        margin: 0;
        white-space: nowrap;
    }
    &-content {
        font-size: 1rem;
        font-weight: 300;
        color: $dark;
        margin: 4px 2px 7px;
        min-height: 24px;
        width: 100%;
        padding-right: 32px;
        word-break: break-word;
    }
}
