@use '~styles/constants' as *;

.fallback {
    &--title {
        display: flex;
        align-items: center;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        font-family: "Frutiger-light";
        font-size: 14px;
        font-weight: 300;
    }
}