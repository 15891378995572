.limelight-modal {
    z-index: 2;
    width: 650px; 

    &--content {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        padding: 14px 32px 30px;
        border-radius: 6px;
        background-color: #ffffff;
    }

    &--summary {
        overflow: auto;
        max-height: 200px;
        display: flex;
        flex-direction: column;
    }

    &--message {
        width: 100%;
    }
    &--header {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        width: 100%;
        margin-bottom: 8px;
    }
    &--title {
        font-size: 24px;
        font-weight: 500;
        line-height: 1.25;
        text-align: center;
        color: #4a4a4a;
        margin-bottom: 16px;
        padding: 0 20px;
        font-family: 'Frutiger-55';
    }
    &--text {
        width: 100%;
        font-size: 15px;
        font-weight: 300;
        text-align: center;
        color: #545454;
        padding: 0 20px;
        font-family: 'Frutiger-light';
    }
    &--actions {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 15px;
        white-space: pre-line;

        > :nth-child(2) {
            margin-left: 25px;
        }
    }

    &--icon {
        text-align: center;
        padding-bottom: 15px;
    }
}

.modal-content {
    top: 10px;
}

.MuiGrid-container {
    justify-content: center;
}
.MuiAccordionSummary-root.Mui-expanded {
    min-height: 50px !important;
    max-height: 30px !important;
    font-size: 24px;
    font-weight: 500;
    line-height: 1.25;
    text-align: center;
    color: #4a4a4a;
    padding: 0 20px;
    font-family: 'Frutiger-55';
}

.MuiAccordionSummary-root.Mui-expanded {
    margin: 0;
    max-height: 30px;
}

.MuiAccordionSummary-content {
    font-size: 18px;
    font-weight: 500;
    line-height: 1.25;
    text-align: center;
    color: #4a4a4a;
    margin-bottom: 16px;
    padding: 0 20px;
    font-family: 'Frutiger-55';
}

.MuiAccordionDetails-root {
    max-height: 155px !important;
    label {
        font-size: 18px;
        color: #4a4a4a;

    }
    pre {
        display: block;
        font-size: 87.5%;
        color: #212529;
        text-align: left;
        font-size: small;
    }
}
