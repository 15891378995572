@use "~styles/constants" as *;

.batch-report-modal {
    &--link {
        color: #3f41d1;
        letter-spacing: 1.5px;
        font-size: 14px;
        cursor: pointer;
        display: flex;
        align-items: center;

        &__icon {
            content: $svg_arrow_download;
            height: 20px;
            width: 20px;
            margin-right: 10px;
            cursor: pointer;
        }
    }

    &--content {
        width: 320px;
        background-color: #f6f6f6;
        padding: 20px 25px;
        margin: 20px 0px;
        border-radius: 6px;

        &-item {
            font-size: 15px;
            padding: 3px 10px;
            border-bottom: 1px solid #d8d8d8;
            display: flex;
            justify-content: space-between;
            align-items: center;
            height: 52px;

            &__icon {
                color: #9199a1;

                &.active {
                    color: #3f41d1;
                }
            }

            span {
                padding-left: 5px;
            }

            .Mui-checked {
                color: #3f41d1 !important;
            }
        }
    }
}
