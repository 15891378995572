.batch-relocate-success-modal {
    z-index: 2;
    width: 460px !important;
    height: 440px !important;
    
    &--message {
        margin: 17px;

        &__text {
            width: 100%;
            font-size: 16px;
            font-weight: 300;
            text-align: left;
            color: #545454;
            padding: 10px 0px;            
            display: flex;
            flex-direction: column;
            gap: 10px;
        }
    }    
}
