.archive-many-modal-error {
    z-index: 2;
    width: 460px !important;
    height: 440px !important;

    &--content {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        padding: 14px 32px 20px;
        border-radius: 6px;
        background-color: #ffffff;
    }
    &--header {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        width: 100%;
        margin-bottom: 8px;
    }
    &--title {
        font-size: 24px;
        line-height: 1.25;
        text-align: center;
        color: #4a4a4a;
        margin-bottom: 20px;
        padding: 0 20px;
    }

    &--errors {
        height: 260px;
        overflow-y: auto;
        margin: 17px;
        scrollbar-color: #a4a4a4 #e1e2e6;
        scrollbar-width: thin;

        &__text {
            width: 100%;
            font-size: 16px;
            font-weight: 300;
            text-align: left;
            color: #545454;
            padding: 10px 0px;
            // height: 100px;
            display: flex;
            flex-direction: column;
        }
    }

    &--actions {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 15px;
        > :nth-child(2) {
            margin-left: 25px;
        }
    }
}
