@use "~styles/constants" as *;

.modal-detail {
    width: 400px;
    min-height: calc(100vh - 500px);
    padding: 20px;
    display: flex;
    flex-direction: column;
  
    &-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-top: 20px;
    }

    &--detail {
        padding: 10px;
        display: flex;
        flex-direction: column;

        &-title {
            font-family: "Frutiger-55";
            font-size: 24px;
            font-weight: 400;
            color: #000;
        }
    }

    &--buttons {
        display: flex;
        justify-content: space-around;
        align-items: center;
        margin-top: 20px;
    }
}