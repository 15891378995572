.monthly-commission-originator-card {
    background: #ffffff;
    width: 350px;
    height: 244px;
    border: 1px solid #d8d8d8;
    border-radius: 6px;
    padding: 22px;
    margin-right: 25px;

    &--title {
        font-family: 'Frutiger-55';
        font-weight: 500;
        font-size: 18px;
        color: #565a5b;
    }

    &--item {
        display: flex;
        flex-direction: column;
        margin-top: 18px;
        border-bottom: 1px solid #f6f6f6;

        &__label {
            color: #9199a1;
            font-family: 'Frutiger-light';
            font-size: 12px;
            line-height: 14px;
        }

        &__value {
            color: #343434;
            font-family: 'Frutiger-light';
            font-size: 16px;
            line-height: 25px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            width: 160px;
        }
    }
}
