@use "~styles/constants" as *;

.fare-amount-report {
    width: 100%;
    display: flex;
    justify-content: center;

    &__container {
        width: 480px;
        display: flex;
        align-items: center;
        flex-direction: column;
        position: relative;
        min-height: 500px;
    }

    &--header {
        display: flex;
        justify-content: flex-start;
        width: 100%;
        padding: 10px 20px 25px;

        &-title {
            font-size: 28px;
            font-weight: 500;
            text-align: center;
            color: #292a2a;
            width: 100%;
            margin: 0;
        }
    }

    &--form {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        row-gap: 16px;
        padding: 0 24px;

        &-field {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            
            &--error {
                width: 480px;
                color: red;
                font-size: 12px;
                margin-top: 4px;
                padding: 0px 24px;
                
            }

            &__icon {
                width: 45px;
                height: 45px;
                display: flex;
                justify-content: center;
                align-items: center;
                background-color: #3f41d1;
                color: white;
                border-radius: 5px;
                margin-left: 5px;
                padding: 10px;
                cursor: pointer;
            }
        }

        &-card {
            display: flex;
            flex-direction: column;
            align-items: center;
            margin: 10px 0 0 0;
            min-height: 45px;
            width: 100%;

            &-field {
                margin-bottom: 15px;
            }
        }

        &-buttons {
            display: flex;
            justify-content: space-between;
            width: 100%;
            margin-top: 24px;
        }
    }
}

.MuiSnackbarContent-root{
    margin-bottom: 30px;
}