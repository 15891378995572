@use '~styles/constants' as *;

.batch-session-interest-calculate--text {
    position: relative; 
    display: flex;
    align-items: center;
    white-space: nowrap;
    padding-right: 30px; 
}

.copy-icon--icon {
    position: absolute;
    width: 20px;
    height: 20px;
    content: $svg_copy_icon;
    cursor: pointer;
    margin-top:-8px;
}
