@use '~styles/constants' as *;

.disbursement-confirmation-list {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-top: 20px;
    border-bottom: 1px solid #d8d8d8;

    table {
        width: calc(100% + 4px);
    }

    &__table-body {
        width: 100%;
        height: 100%;
        max-height: calc(100vh - 540px);
        display: block;
        overflow-y: auto;

        &::-webkit-scrollbar {
            width: 4px;
            background-color: #e1e2e6;
        }

        &::-webkit-scrollbar-thumb {
            width: 3px;
            background: #a4a4a4;
        }
    }

    &__table-content {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-bottom: 1px solid #d8d8d8;
        font-family: 'Frutiger-light', sans-serif;
        font-size: 14px;
        height: 50px;

        td {
            width: 18%;

            &:nth-child(1) {
                width: 5%;
                cursor: pointer;
                svg {
                    transform: rotate(0.75turn);
                    transition: 0.2s ease-in-out;
                }

                .active {
                        transform: rotateX(1turn);
                    }
                }

            &:nth-child(7) {
                width: 5%;
            }

            &.disable {
                svg {
                    opacity: 0.5;
                }
            }
            
        }

        &__chunk-status {
            font-family: 'Frutiger', sans-serif;
            width: fit-content;
            font-size: 11px;
            font-weight: 500;
            padding: 4px 8px;
            border-radius: 5px;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            white-space: nowrap;
            color: #4a4a4a;


            &.SUCCESS {
                background-color: #b8d759;
            }

            &.PROCESSING {
                background-color: #f2c466 ;
            }

            &.PENDENT {
                background-color: #6acff1;
            }

            &.ERROR {
                background-color: #ff7978;
            }
        }
    }

    &__closing {
        width: 100%;
        background: none;
        border: none;
        padding: 0px;
        font-size: 14px;
        height: 50px;
        display: flex;
        align-items: center;
        margin-left: 8px;

        &-box {
            width: 100%;
            display: flex;
            flex-direction: column;
        }

        &-cell {
            width: 22%;
            display: flex;
            font-family: 'Frutiger-light', sans-serif;

            &:nth-child(1) {
                width: 5%;
                cursor: pointer;
                svg {
                    transform: rotate(0.75turn);
                    transition: 0.2s ease-in-out;
                }

                .active {
                    transform: rotateX(1turn);
                }
            }

            &:nth-child(7) {
                width: 5%;
            }

            &.disable {
                svg {
                    opacity: 0.5;
                }
            }

            &-status {
                font-family: 'Frutiger', sans-serif;
                width: fit-content;
                font-size: 11px;
                font-weight: 500;
                padding: 4px 8px;
                border-radius: 5px;
                display: flex;
                align-items: center;
                justify-content: flex-start;
                white-space: nowrap;
                color: #4a4a4a;    
                &.SUCCESS {
                    background-color: #b8d759;
                }
    
                &.PROCESSING {
                    background-color: #f2c466;
                }
    
                &.PENDENT {
                    background-color: #6acff1;
                }
    
                &.ERROR {
                    background-color: #ff7978;
                }
            }
        }
    }

    &__pending-message {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 50px; 
        background-color: #f0f0f0; 
        color: #333; 
        font-size: 15px;
        border: 1px solid #ccc; 
        border-radius: 4px; 
        margin: 20px; 
        padding: 10px; 
        text-align: center; 
        font-family: 'Frutiger-light', sans-serif;
    }
}
