@use "~styles/constants" as *;

.scd-originator {
    height: 100%;
    padding: 0px 24px;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;

    &__container {
        width: 100%;
        max-width: 1116px;
        min-height: 440px;
    }

    &__header {
        width: 100%;

        display: flex;
        row-gap: 12px;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        flex-wrap: nowrap;

        &-container {
            display: flex;
            width: 100%;
            justify-content: space-between;

            &--title {
                font-size: 34px;
                font-weight: 500;
                color: #545454;
                margin: 0;
            }

            &--align-right {
                width: 100%;
                justify-content: flex-end;
                display: flex;
                align-items: center;
            }
        }

        &--buttons-content {
            width: 100%;
            display: flex;

            &-filter-button {
                padding-right: 10px;
            }

            &-error-arrow {
                content: $svg_error_arrow;
                width: 30px;
                height: 30px;
                margin-top: 8px;
                margin-left: 10px;
                cursor: pointer;
            }

            &-align-right {
                width: 100%;
                justify-content: flex-end;
                display: flex;
            }
        }
        @include responsive(s) {
            flex-direction: column;
        }
    }

    &__table {
            width: 100%;
            margin: 8px 0px 0px;


        .page-container--table {
            display: inline-block;
            
            @media screen and (min-height: 850px){
                height: 400px;
            }
            
            
            thead {
                width: 100%;

                &:first-child {
                    display: inline-block;
                }
            }

            tbody {
                height: calc(100vh - 396px);
                display: block;
                overflow-y: auto;
            }

            &.CHIPS_ACTIVE {
                tbody {
                    height: calc(100vh - 432px);

                }
            }

            &.EMPTY_COLLECTION {
                tbody {
                    min-height: 0px;
                    height: min-content;
                }
            }

            &-container::-webkit-scrollbar {
                width: 4px;
                background-color: #e1e2e6;
            }

            &-container::-webkit-scrollbar-thumb {
                width: 3px;
                background: #a4a4a4;
            }
            
            th {
                &:nth-child(1) {
                    width: 30%;
                }
                &:nth-child(2) {
                    width: 21%;
                }
                &:nth-child(3) {
                    width: 50%;
                }
            }
            td {
                &:nth-child(1) {
                    width: 34%;
                }
                &:nth-child(2) {
                    width: 24%;
                }
                &:nth-child(3) {
                    width: 46%;
                }
            }
            @include responsive(sm) {
                th {
                    &:nth-child(1) {
                        width: 31%;
                    }
                    &:nth-child(2) {
                        width: 29%;
                    }
                    &:nth-child(3) {
                        width: 25%;
                    }
                }
                td {
                    &:nth-child(1) {
                        width: 53%;
                    }
                    &:nth-child(2) {
                        width: 50%;
                    }
                }
            }

            @include responsive(s) {
                th {
                    &:nth-child(1) {
                        width: 235px;
                    }
                    &:nth-child(2) {
                        width: 200px;
                    }
                    &:nth-child(3) {
                        width: 200px;
                    }
                }
                td {
                    &:nth-child(1) {
                        width: 265px;
                    }
                    &:nth-child(2) {
                        width: 228px;
                    }
                    &:nth-child(3) {
                        width: 300px;
                    }
                }
            }
        }

        &--content {
            min-height: 330px;
        }

        &--cell-icon {
            @include responsive(sm) {
                width: 7%;
            }

            @include responsive(s) {
                width: 7%;
            }
        }
    }
}
