@use '~styles/constants' as *;

.scd-ccb-operation-detail {
    padding: 10px 20px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    margin-top: 40px;


    &__container {
        max-width: 1115px;
        min-height: 450px;
        width: 100%;
    }

    &__header {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 133px;
        margin-bottom: 40px;


        &--title {
            font-size: 26px;
            font-weight: 500;
            color: #565a5b;
            margin: 0;
        }
        &--cards {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            width: 85%;
            height: 133px;
            border-radius: 6px;
        }
    }

    &__content {
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        &--status {
            display: inline-flex;
            font-size: 12px;
            align-items: center;
            white-space: nowrap;
            justify-content: flex-start;
            height: 24px;
            font-weight: 500;
            border-radius: 5px;
            padding: 10px;
            margin-bottom: 15px;

            &.EXPORTED {
                background-color: #6acff1;
                width: 90px;
            }

            &.ERROR {
                color: #ffffff;
                background-color: #FA4D56;
                width: 50px;
            }

            &.NEW {
                color: #ffffff;
                background-color: #42BE65;
                width: 55px;
            }

            &.BATCHLESS {
                background-color: #bac0c2;
                width: 75px;
            }

            &.PROCESSED {
                color: #ffffff;
                background-color: #08BDBA ;
            }

            &.ARCHIVED {
                color: #ffffff;
                background-color: #A2A9B0;
                width: 90px;
            }

            &.DISAPPROVED {
                color: #ffffff;
                background-color: #FF832B;
                width: 90px;
            }

            &.PENDING_APPROVAL {
                color: #ffffff;
                background-color: #A56EFF;
                width: 96px;
            }
        }

        &--info {
            font-size: 18px;
            line-height: 21px;
            display: flex;
            align-items: center;
            color: #9199a1;

            span {
                &:nth-child(1) {
                    padding-right: 20px;
                }
            }
        }
    }
}
