@use '~styles/constants' as *;

.scd-complete-payment-transactions {
    padding: 15px 20px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    margin-top: 10px;
    overflow: hidden;

    &__container {
        max-width: 1150px;
        height: 100%;
        width: 100%;
        padding: 0px 20px;
    }

    &__header {
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        padding: 20px 0 10px 0;
        width: 100%;

        &--title {
            font-size: 34px;
            font-weight: 500;
            color: #292A2A;
            margin: 0;
        }

        &--buttons-content {
            padding: 10px 0px;
            display: flex;
            width: 100%;

            &-filter-button {
                padding-right: 10px;
            }

            &-error-arrow {
                content: $svg_error_arrow;
                width: 30px;
                height: 30px;
                margin-top: 8px;
                margin-left: 10px;
                cursor: pointer;
            }

            &-align-right {
                width: 100%;
                justify-content: flex-end;
                display: flex;
            }
        }
    }

    &__content {
        font-family: 'Frutiger';
        display: flex;
        flex-direction: column;
        height: calc(100vh - 400px);
        padding-bottom: 15px;
        overflow-y: auto;
        overflow-x: auto;

        &--infos {
            display: flex;
            justify-content: space-between;
            align-items: center;

            span {
                font-family: 'Frutiger-55';
                font-weight: 700;
                font-size: 20px;
                line-height: 24px;
            }

            div {
                margin-right: 20px;
                color: $bluey_grey;

                .MuiCheckbox-root {
                    padding: 5px;
                }
            }
        }

        &-slide {
            width: 100%;
            background: #ffff;
            position: fixed;
            bottom: 52px;
            left: 0;
            right: 0;
            display: flex;
            z-index: 1;
            justify-content: center;
            height: 90px;
            align-items: center;
            font-family: 'Frutiger';

            &--container {
                display: flex;
                justify-content: space-between;
                align-items: center;
                height: 100%;
                width: 1115px;
            }

            &--item {
                display: flex;
                flex-direction: column;

                &__title {
                    font-size: 13px;
                    color: #5c5c5c;

                    @include responsive(s) {
                        width: 120px;
                    }
                }

                &__value {
                    font-size: 18px;

                    strong {
                        font-weight: 400;
                        margin-right: 120px;
                        color: #072056;
                    }

                    span {
                        font-size: 16px;
                        margin-right: 40px;
                    }
                }
            }

            &--buttons {
                display: flex;
            }
        }
    }
    &__table {
        width: 100%;
        overflow-x: auto;

        &-empty-filter {
            width: 100%;
            height: 260px;
            display: flex;
            justify-content: center;
            align-items: center;
            font-family: 'Frutiger';
            flex-direction: column;

            &-image {
                content: $svg_piggy_bank;
                width: 100px;
                height: 100px;
            }

            &-title {
                font-size: 26px;
                line-height: 24px;
                color: #d8d8d8;
                padding-top: 10px;
            }

            &-subtitle {
                font-size: 16px;
                line-height: 24px;
                color: #d8d8d8;
                padding-top: 10px;
            }
        }
    }
}

.scd-complete-payment-transactions__content::-webkit-scrollbar {
    width: 4px;
    height: 4px;
    background-color: #e1e2e6;
    overflow: visible;
}

.scd-complete-payment-transactions__content::-webkit-scrollbar-thumb {
    width: 3px;
    height: 3px;
    background: #a4a4a4;
}


