.statement-error {
    padding: 10px 20px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;

    &--container {
        padding: 50px 0;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: fit-content;

        .button__style--back, .MuiButton-contained {
            max-width: 220px;

            &:hover {
                max-width: 220px;
            }
        }
    }

    &--message {
        font-family: "Catamaran", sans-serif;
        font-size: 1.25rem;
        color: #5e5e5e;
        text-align: center;
        line-height: 20px;
        margin-bottom: 30px;
    }
}