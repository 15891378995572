@use "~styles/constants" as *;

.modal-message {
  &__body {
    max-width: 504px;
    width: 100vw;
    padding: 48px 40px 32px;
    background-color: $white;
    border-radius: 16px;
    display: flex;
    flex-direction: column;
    position: relative;

    &__header {
      margin-bottom: 32px;

      &--title {
        color: $gray-100;
        font-family: 'Frutiger', sans-serif;
        font-size: 32px;
        margin: 0;
        white-space: nowrap;
      }

      &--close-modal {
        width: 24px;
        height: 24px;
        border-radius: 100%;
        background-color: $gray-20;
        cursor: pointer;
        position: absolute;
        top: 16px;
        right: 16px;
        display: flex;
        justify-content: center;
        align-items: center;
        border: none;
        background-image: $svg_close_message_modal;
        background-size: 20px 20px;
        background-repeat: no-repeat;
        background-position: center;

        &:hover {
          filter: invert(100%);
        }
      }
    }

    &__footer {
      width: 100%;
      display: flex;
      justify-content: space-between;
      padding-top: 32px;
      position: relative;
      margin-top: 32px;

      &--border {
        width: calc(80px + 100%);
        background-color: $gray-20;
        height: 1px;
        position: absolute;
        top: 0;
        left: -40px;
      }
    }
  }
}
