@use "~styles/constants" as *;

.button__style--description-icon {
  background: none;
  box-shadow: unset;
  border: none;
  outline: none;
  min-width: 20px;
  max-width: 100%;

  display: flex;
  align-items: center;
  justify-content: center;

  margin: 0px;

  &--icon {
    &.DEFAULT {
      content: $svg_description_rest;
    }

    &.ALTERNATIVE {
      content: $svg_description_rest_alternative
    }

    &:hover {
      content: $svg_description_hover;
    }
  }
}
