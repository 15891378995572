@use "~styles/constants" as *;

.page-container--transfeera-transfers-infinite-scroll {

    &--loading {
        width: 100%;
        margin-top: 20px;
        height: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .MuiButton-contained {
        width: 100%;
        background-color: $white_two;
        margin: 15px 0;
        box-shadow: unset;
        border: 1px solid $very_light_pink_two;
        text-transform: none;
    }

    .MuiButtonBase-root.Mui-disabled {
        cursor: not-allowed;
        pointer-events: all;
        background-color: $very_light_pink_two
    }

    .MuiButton-contained:hover {
        box-shadow: unset;
        background-color: $white;
    }

    .MuiButton-label {
        font-family: 'Frutiger-55';
        font-weight: 500;
        font-size: 14px;
        line-height: 24px;
        letter-spacing: 1.5px;
        color: $blurple;
    }

    .MuiButton-active {
        box-shadow: unset;
    }
}