.closed-menu-item {
    &--inactive-icon-box {
        width: 48px;
        height: 48px;
        background: transparent;
    }

    &--active-icon-box {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 48px;
        height: 48px;
        background: #163c6e;
        border-radius: 50%;

        img {
            width: 20px;
            height: 20px;
            filter: brightness(0) invert(100%);
        }

    }
}
