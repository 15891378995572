@font-face {
    font-family: 'Frutiger';
    src: url('./fonts/Frutiger_bold.ttf');
    font-weight: 800;
}

@font-face {
    font-family: 'Frutiger-italic';
    src: url('./fonts/Frutiger_Italic.ttf');
}

@font-face {
    font-family: 'Frutiger-light';
    src: url('./fonts/Frutiger_light.ttf');
    font-weight: 300;
}

@font-face {
    font-family: 'Frutiger-45-light';
    src: url('./fonts/Frutiger_lt_45_light.ttf');
    font-weight: 300;
}

@font-face {
    font-family: 'Frutiger-55';
    src: url('./fonts/Frutiger_55.ttf');
}

@font-face {
    font-family: 'Frutiger-65-bold';
    src: url('./fonts/Frutiger_lt_65_bold.ttf');
}


@font-face {
    font-family: 'Frutiger';
    src: url('./fonts/Frutiger_semibold.ttf');
    font-weight: 500;
}

@font-face {
    font-family: 'Frutiger';
    src: url('./fonts/Frutiger_regular.ttf');
}

@font-face {
    font-family: 'icomoon';
    src: url('fonts/icomoon.eot?28ai4b');
    src: url('fonts/icomoon.eot?28ai4b#iefix') format('embedded-opentype'), url('fonts/icomoon.ttf?28ai4b') format('truetype'), url('fonts/icomoon.woff?28ai4b') format('woff'), url('fonts/icomoon.svg?28ai4b#icomoon') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: block;
}

[class^='icon-'],
[class*=' icon-'] {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'icomoon' !important;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.icon-flechas2:before {
    content: '\e900';
    color: #3aadd1;
    cursor: pointer;
}

.icon-flechas1:before {
    content: '\e901';
    color: #3aadd1;
    cursor: pointer;
}

.icon-flechas:before {
    content: '\e904';
    color: #3aadd1;
    cursor: pointer;
}
