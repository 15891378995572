@use "~styles/constants" as *;

.scd-payment-transaction-in-series-by-batch {
    padding: 15px 20px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    margin-top: 10px;
    overflow: hidden;

    &__container {
        max-width: 1150px;
        height: 650px;
        width: 100%;
        padding: 0px 20px;
    }

    &__error-message {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 500px;

        span {
            font-family: 'Catamaran', sans-serif;
            font-size: 1.25rem;
            color: $scorpion;
        }
    }

    &__header {
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        padding: 20px 0;
        width: 100%;

        &--title {
            font-size: 34px;
            font-weight: 500;
            color: #545454;
            margin: 0;
        }
    }

    &__content {
        font-family: 'Frutiger', sans-serif;
        display: flex;
        flex-direction: column;
        max-height: 500px;
        overflow-y: auto;

        &-new-transaction {
            padding-top: 5px;
            padding-bottom: 32px;
            height: 95px;
            position: relative;
            align-items: center;
            display: flex;

            @media screen and (max-width: 1145px) {
                justify-content: space-between;
            
                &__field-value {
                    max-width: 145px;
                    margin: 0px;
                }
            
                &__field-favored {
                    max-width: 250px;
                    margin-left: -10px;
                }
            
                &__field-account {
                    margin-left: 80px;
                }
            
                &__icons {
                    max-width: 110px;
                }
            
            }

            > :nth-child(3) {
                top: -36px;
                position: relative;
            }

            &__field-value {
                width: 210px;
                margin-right: 20px;
            }

            &__field-favored {
                margin-right: 20px;
                width: 340px;
                &-content {
                    position: absolute;
                    top: 10px;
                    width: 340px;
                }
            }

            &__field-account {
                width: 300px;
                margin-right: 20px;
                position: relative;
                height: 50px;
            }

            &__icons {
                display: flex;
                align-items: center;
                justify-content: flex-end;
                width: 170px;
                height: 50px;

                &-icon {
                    margin: 0px 12px;
                    cursor: pointer;
                    color: #9199a1;
                }

                &-blank {
                    width: 72px;
                }

                &-delete {
                    content: $svg_trash_hover;
                    height: 30px;
                    margin: 0px 21px;
                    cursor: pointer;
                }

                &-check {
                    border: 1px solid #9199a1;
                    padding: 6px;
                    width: 50px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    height: 50px;
                    border-radius: 10px;

                    &.disabled {
                        cursor: default;
                    }

                    &.active {
                        cursor: pointer;
                        border: 1px solid #3f41d1;
                        color: #3f41d1;
                    }
                }

                &-description {
                    content: $svg_description;
                    width: 23px;
                    height: 23px;

                    &.active {
                        cursor: pointer;
                        content: $svg_description_active;
                    }

                    &.disabled {
                        content: $svg_description;
                        cursor: default;
                    }
                }
            }
        }

        &-slide {
            width: 100%;
            background: #ffff;
            position: fixed;
            bottom: 52px;
            left: 0;
            right: 0;
            display: flex;
            z-index: 1;
            justify-content: center;
            height: 90px;
            align-items: center;
            font-family: 'Frutiger', sans-serif;

            &--container {
                display: flex;
                justify-content: space-between;
                align-items: center;
                height: 100%;
                width: 1115px;
            }

            &--item {
                display: flex;
                flex-direction: column;

                &__title {
                    font-size: 13px;
                    color: #5c5c5c;

                    @include responsive(s) {
                        width: 120px;
                    }
                }

                &__value {
                    font-size: 18px;

                    strong {
                        font-weight: 500;
                        margin-right: 120px;
                        color: #072056;
                    }

                    span {
                        font-size: 16px;
                        margin-right: 40px;
                    }
                }
            }

            &--buttons {
                display: flex;
            }
        }
    }
}

.scd-payment-transaction-in-series-by-batch__content::-webkit-scrollbar {
    width: 4px;
    background-color: #e1e2e6;
}

.scd-payment-transaction-in-series-by-batch__content::-webkit-scrollbar-thumb {
    width: 3px;
    background: #a4a4a4;
}
