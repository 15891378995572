@use '~styles/constants' as *;

.batch-operations-detail {
    height: 100%;
    padding: 10px 20px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;

    &__container {
        max-width: 1115px;
        min-height: 450px;
        width: 100%;

    }
    
    &__title-content {
        display: flex;
        margin: 25px 0px -10px 0px;
        &-title {
            min-width: 200px;
            font-family: 'Frutiger-55';
            font-weight: 500;
            font-size: 20px;
            line-height: 24px;
        }
    
        &-counter-operations {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 45px;
            height: 28px;
            padding: 10px;
            margin-left: 10px;
            border: 1px solid #343434;
            border-radius: 20px;
            font-size: 12px;
            color: #343434;
        }
    }

    &__error-message {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 340px;

        span {
            font-family: 'Catamaran', sans-serif;
            font-size: 1.25rem;
            color: $scorpion;
        }
    }

    &__content {

        &--link {
            color: #3f41d1;
            letter-spacing: 1.5px;
            font-size: 14px;
            cursor: pointer;
            display: flex;
            align-items: center;
            margin-right: 30px;
        }

        &-slide {
            width: 100%;
            background: #ffff;
            position: fixed;
            bottom: 52px;
            left: 0;
            right: 0;
            display: flex;
            z-index: 1;
            justify-content: center;
            height: 90px;
            align-items: center;
            font-family: 'Frutiger';

            &--container {
                display: flex;
                justify-content: space-between;
                align-items: center;
                height: 100%;
                width: 1115px;
            }

            &--item {
                display: flex;
                flex-direction: column;

                &__title {
                    font-size: 13px;
                    color: #5c5c5c;

                    @include responsive(s) {
                        width: 120px;
                    }
                }

                &__action {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    color: #3f41d1;
                    cursor: pointer;
                    font-size: 15px;
                    letter-spacing: 1.5px;
                    margin-right: 30px;

                    &.disabled {
                        cursor: default;
                        opacity: 0.5;
                    }

                    &-remove-icon {
                        content: $svg_no_disturb;
                        width: 18px;
                        height: 18px;
                        margin-right: 8px;
                    }
                }

                &__value {
                    font-size: 18px;

                    strong {
                        font-weight: 400;
                        margin-right: 120px;
                        color: #072056;
                    }

                    span {
                        font-size: 16px;
                        margin-right: 40px;
                    }
                }
            }

            &--buttons {
                display: flex;
            }
        }
    }

    &__header {
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        padding: 20px 0;
        width: 100%;

        &-container {
            display: flex;
            flex-direction: column;
            width: 100%;

            &--title {
                font-size: 34px;
                font-weight: 500;
                color: #545454;
                margin: 0;
            }

            &--subtitle {
                font-size: 19px;
                color: #bac0c2;
                margin: 15px 0px;
            }
        }

        &--buttons-content {
            padding: 20px 0px;
            display: flex;
            width: 100%;

            &-filter-button {
                padding-right: 10px;
            }

            &-error-arrow {
                content: $svg_error_arrow;
                width: 30px;
                height: 30px;
                margin-top: 8px;
                margin-left: 10px;
                cursor: pointer;
            }

            &-align-right {
                width: 100%;
                justify-content: flex-end;
                display: flex;
            }
        }

        @include responsive(s) {
            flex-direction: column;
        }
    }

    &__table {
        width: 100%;
        overflow-x: auto;

        &__header {
            display: flex;

            h3 {
                font-size: 22px;
                font-weight: 400;
                margin-right: 10px;
            }

            &-align-right {
                width: 90%;
                justify-content: flex-end;
                display: flex;
            }
        }

        .page-container--table {         
            th {
                font-family: 'Frutiger 65';
                font-weight: 700;
                size: 11px;
                line-height: 12.73px;
                letter-spacing: 1px;

                &:nth-child(1) {
                    width: 15%;
                }
                &:nth-child(2) {
                    width: 15%;
                }
                &:nth-child(3) {
                    width: 25%;
                }
                &:nth-child(4) {
                    width: 25%;
                }
                &:nth-child(5) {
                    flex: 1;
                }
            }
            td {
                margin-right: 11px;

                &:nth-child(1) {
                    width: 14%;
                }
                &:nth-child(2) {
                    width: 14%;
                }
                &:nth-child(3) {
                    width: 24%;
                }
                &:nth-child(4) {
                    width: 24%;
                }
                &:nth-child(5) {
                    flex: 1;
                }
            }
        }

        &--content {
            min-height: 330px;
        }

        .Mui-checked {
            color: #3f41d1 !important;
        }
    }
}
