@use "~styles/constants" as *;

.user-create {
    height: 100%;
    padding: 10px 20px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;

    &--container {
        width: 350px;
        margin-top: 25px;
    }

    &--header {
        display: flex;
        justify-content: flex-start;
        width: 100%;
        padding: 10px 20px 25px;

        &-title {
            font-size: 28px;
            font-weight: 500;
            text-align: center;
            color: #292a2a;
            width: 100%;
            margin: 0;
            text-align: center;
        }
    }

    &--form {
        width: 100%;
        margin: 0 auto 50px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;

        &-field {
            display: flex;
            width: 100%;
            margin-bottom: 5px;
            min-height: 65px;
            padding: 0 20px;
        }

        &-role {
            display: flex;
            flex-direction: column;
            width: 90%;
            min-height: 65px;
            padding: 0 10px;
            margin-bottom: 5px;
            border: 1px solid #ccc;
            border-radius: 6px;

            &-title {
                padding: 5px 0px;
                font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
            }
        }

        &--error-message {
            text-align: left;
            font-size: 0.80rem;
            width: 100%;
            color: #f44336;
            font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
            font-weight: 400;
            line-height: 1.66;
            letter-spacing: 0.03333em;
        }

        &-buttons {
            display: flex;
            justify-content: space-between;
            width: 100%;
            padding: 0 25px;
            margin-top: 30px;
        }
    }
}