.without-partition {
    width: 100%;
    max-height: 320px;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    row-gap: 24px;


    &__title {
        font-family: 'Frutiger-55', sans-serif;
        font-weight: 500;
        font-size: 20px;
    }
    

}