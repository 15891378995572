.scd-organization__detail {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-bottom: 30px;

    &--main-container {
        width: 350px;
        display: flex;
        align-items: center;
        flex-direction: column;
        margin-top: 35px;
        position: relative;
        min-height: 500px;
    }

    &--container {
        width: 290px;
    }
}