@use "~styles/constants" as *;

.banking-correspondent-create-update {
    height: 100%;
    padding: 10px 20px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;

    &__create {
        width: 100%;
        display: flex;
        justify-content: center;
    }

    &--container {
        width: 420px;
        display: flex;
        align-items: center;
        flex-direction: column;
        margin-top: 25px;
        position: relative;
        min-height: 500px;
    }

    &--header {
        display: flex;
        justify-content: flex-start;
        width: 100%;
        padding: 10px 20px 25px;

        &-title {
            font-size: 28px;
            font-weight: 500;
            text-align: center;
            color: #292a2a;
            width: 100%;
            margin: 0;
            text-align: center;
        }
    }

    &--form {
        width: 100%;
        margin: 0 auto 50px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;

        &-field {
            display: flex;
            width: 100%;
            margin-bottom: 5px;
            min-height: 65px;
            padding: 0 20px;
        }

        &-buttons {
            display: flex;
            justify-content: space-between;
            width: 100%;
            padding: 0 20px;
            margin-top: 24px;
        }
    }

    &__container {
        max-width: 1115px;
        width: 100%;
    }

    &__header {
        padding: 20px 0;
        width: 100%;

        &--title {
            font-size: 34px;
            font-weight: 500;
            color: #545454;

            @include responsive(sm) {
                text-align: center;
                font-size: 32px;
            }

            @include responsive(s) {
                text-align: center;
                font-size: 32px;
            }

            @include responsive(ss) {
                text-align: center;
                font-size: 29px;
            }
        }

        &--button {
            background-color: #3f41d1;
            border-radius: 10px;
            max-height: 43px;
            color: #fff;
        }

        &--column-right {
            width: 100%;
            justify-content: flex-end;
            display: flex;

            @include responsive(sm) {
                margin-top: 15px;
                justify-content: center;
            }

            @include responsive(s) {
                margin-top: 15px;
                justify-content: center;
            }
        }
    }
}
