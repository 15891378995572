@use '~styles/constants' as *;

.batch-closing-header {
    display: flex;
    justify-content: space-between;
    max-width: 1115px;
    height: 82px;
    padding: 16px 24px 16px 24px;
    margin: 30px 0;
    position: relative;
    background-color: #ffffff;
    border-radius: 6px;
}
