.monthly-commission-operations-value-card {
    background: #fdfdfd;
    border: 1px solid #d8d8d8;
    border-radius: 6px;
    display: flex;
    margin-top: 30px;
    position: relative;
    flex-direction: column;
    margin-bottom: 80px;

    &--title {
        color: #163c6e;
        font-weight: 500;
        font-size: 20px;
        font-family: 'Frutiger-55';
        text-align: center;
        width: 100%;
        padding-top: 5px;
        padding: 20px;
        position: sticky;
        text-transform: capitalize;
    }

    &--icon {
        cursor: pointer;
        position: absolute;
        right: 10px;
        padding: 20px;
    }

    &--content {
        display: flex;
        overflow: auto;
        max-height: 544px;
        margin: 0px 40px 30px 30px;

        &__container {
            display: flex;
            flex-direction: column;
        }

        &-header {
            width: 220px;
            padding: 8px 0px;
            height: 38px;
            display: flex;
            justify-content: center;
            align-items: center;

            &.__border {
                border-bottom: 1px solid #d8d8d8;
            }

            &__title {
                color: #565a5b;
                font-weight: 400;
                font-family: 'Frutiger-55';
                font-size: 18px;

                span {
                    &:nth-child(1) {
                        padding-right: 5px;
                    }
                }
            }

            &__subtitle {
                &-label {
                    color: #d8d8d8;
                    font-weight: 500;
                    font-size: 14px;
                    font-family: 'Frutiger-55';
                }

                &-value {
                    color: #9199a1;
                    font-weight: 500;
                    font-family: 'Frutiger-55';
                    font-size: 16px;
                    padding-left: 6px;
                }
            }
        }

        &-table {
            height: 25px;
            display: flex;
            width: 220px;
            padding: 8px 0px;
            display: flex;
            align-items: center;
            justify-content: center;

            &-cell {
                font-size: 14px;
                color: #9199a1;
                font-family: 'Frutiger-light';
            }
        }
    }

    &--no-results {
        width: 100%;
        text-align: center;
        margin: 35px 0px 50px 0px;
        font-size: 18px;
        color: #d8d8d8;
    }
}
