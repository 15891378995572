@use '~styles/constants' as *;

.read-only-payment-transaction {
    width: 1090px;
    height: 55px;
    display: flex;
    margin: 8px 0px;

    &__field {
        background-color: $white_three;
        border-radius: 6px;
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        padding: 10px;

            &.MANUAL {
                border-left: 6px solid;
                border-color: $organization_primary;
            }

            &.AUTOMATIC {
                border-left: 6px solid;
                border-color: $organization_secundary_link;
            }

            &.AUTOMATIC_DISPATCH {
                border-left: 6px solid;
                border-color: #ED66C9;
            }

        &--label {
            color: $bluey_grey;
            font-weight: 300;
            font-size: 10px;
            letter-spacing: 0.541667px;
            font-family: 'Frutiger-light';
        }

        &--date {
            color: $medium_grey;
            font-weight: 300;
            font-size: 13px;
            line-height: 12.77px;
            letter-spacing: 0.5px;            
            font-family: 'Frutiger-light';
        }

        &--value {
            color: $medium_grey;
            font-weight: 300;
            font-size: 13px;
            letter-spacing: 0.5px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            font-family: 'Frutiger-light';
        }

        &--pixKey {
            color: $lighter_grey;
            font-weight: 300;
            font-size: 13px;
            letter-spacing: 0.5px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            font-family: 'Frutiger-light';
        }
    }

    &__checkbox {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: flex-end;
        align-items: center;        
    }
}
