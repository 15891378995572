@use "~styles/constants" as *;

.body__modal--style {
    display: flex;
    .container__content--style {
        display: flex;
        flex: 1;
        justify-content: center;
        align-items: center;
        background-color: $white_two;
    }
}
