@use "~styles/constants" as *;


.scd-program__create {
    height: 100%;
    padding: 10px 20px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;

    &__create {
        width: 100%;
        display: flex;
        justify-content: center;
    }

    &__manager {
        display: flex;
        align-items: center;
        flex-direction: column;
        width: 100%;

        &--button {
            width: 100%;
            padding: 0 25px;
        }
    }

    &--container {
        width: 350px;
        display: flex;
        align-items: center;
        flex-direction: column;
        margin-top: 25px;
        position: relative;
        min-height: 500px;
    }

    &--header {
        display: flex;
        justify-content: flex-start;
        width: 100%;
        padding: 10px 20px 25px;

        &-title {
            font-size: 28px;
            font-weight: 500;
            text-align: center;
            color: #292a2a;
            width: 100%;
            margin: 0;
        }
    }

    &--form {
        width: 100%;
        margin: 0 auto 50px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;

        &-field {
            display: flex;
            width: 100%;
            margin-bottom: 5px;
            min-height: 65px;
            padding: 0 20px;
        }

        &-checkbox {
            display: flex;
            width: 100%;
            margin-bottom: 5px;
            min-height: 55px;
            padding: 0 20px;
        }

        &-buttons {
            display: flex;
            justify-content: space-between;
            width: 100%;
            padding: 0 25px;
            margin-top: 30px;
        }
    }

    &__container {
        max-width: 1115px;
        width: 100%;
    }
}

.button__style--action {
    .MuiButton-contained {
        background-color: $organization_primary;
        box-shadow: unset;
    }

    .MuiButton-contained:hover {
        background-color: $organization_primary_focus;
        box-shadow: unset;
    }

    .MuiButton-label {
        justify-content: center;
    }

    .MuiButton-contained:hover,
    .MuiButton-contained {
        color: $white_two;
        font-size: 0.875rem;
        font-family: Frutiger;
        padding: 0px 15px;
        font-weight: 400;
        letter-spacing: 1.5px;
        border-radius: 10px;
        white-space: nowrap;
        width: 100%;
        height: 100%;
    }
}
