@use '~styles/constants' as *;

.home {
    height: 100%;
    padding: 10px 20px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;

    &__container {
        max-width: 1115px;
        width: 100%;
    }

    &__header {
        padding: 20px 0;
        width: 100%;

        &--title {
            font-size: 35px;
            font-weight: 600;
        }
    }
}
