@use '~styles/constants' as *;

.button__style--clear {
    width: 100%;

    .MuiButton-label {
        justify-content: center;
        margin-top: 1.5px;
    }

    .MuiButton-contained:hover,
    .MuiButton-contained {
        background-color: transparent;
        border: none;
        box-shadow: unset;
        color: $blurple;
        font-size: 0.75rem;
        font-weight: bold;
        letter-spacing: 1.29px;
        border-radius: 8px;
        width: 100%;
        max-width: 50px;
        max-height: 46px;
        outline: none;
        text-align: left;

        &.Mui-disabled {
            background-color: transparent;
            color: $blurple;
            opacity: 0.5;
        }
    }

    .button--arrow {
        color: $blurple;
    }
}
