@use '~styles/constants' as *;

.batch-session-interest-calculate {
  display: flex;
  width: 100%;
  max-width: 895px;
  justify-content: center;
  margin: 50px auto 25px auto;
  overflow-x: scroll;

  &--content {
    display: flex;
    height: 720px;
    width: 100%;
    border: #d6d4d4 solid 1px;
    border-radius: 5px;
  }
  &--rigth-column {
    border-radius: 5px 0 0 5px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    max-width: 500px;
    height: 100%;
    background-color: $white_two;
    position: relative;
  }
  &--header {
    margin: 0 65px 30px;

    h2 {
      font-size: 28px !important;
      font-weight: bold !important;
      line-height: 0.86 !important;
      color: #000000;
    }
  }
  &--form {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 56px;

    &-check {
      width: 100%;
    }

    &-field {
      min-height: 75px;
      width: 100%;

      &-helper-text {
        font-size: 13px;
      }
    }
    &--buttons {
      display: flex;
      justify-content: space-between;
      margin-top: 5px;
      width: 100%;
      column-gap: 8px;

      &-button {
        width: 48%;
      }

      &-button:not(:last-child) {
        margin-right: 15px;
      }

      .MuiButton-contained:hover,
      .MuiButton-contained {
        max-width: none;
      }
    }
  }

  &--left-column {
    border-radius: 0 5px 5px 0;
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 395px;
    max-height: 720px;
    border: 1px solid #ccc;
    background-color: #3f578b;
    align-items: flex-start;
    justify-content: center;
    color: white;
    position: relative;

    > div {
      display: flex;
      flex-direction: column;
      height: 100%;
      width: 100%;

      > hr {
        background-color: #5473b5;
        height: 1px;
        margin-top: 0;
        width: 80%;
      }
    }

    .batch-session-interest-calculate {

      &--detail-container {
        width: 100%;
        align-items: center;
        margin: 90px 0
      }

      &--title {
        font-size: 22px;
        margin: 0 45px 10px;
      }

      &--field {
        display: flex;
        width: 147px;
        flex-direction: column;
        margin: 0 25px 25px;
      }

      &--label {
        font-size: 11px;
        font-weight: 300;
        height: 12px;
      }

      &--text {
        font-size: 15px;
        font-weight: 500;
      }

      &--value {
        font-size: 15px;
        font-weight: bold;
      }
    }
  }
}
