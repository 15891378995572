@use "~styles/constants" as *;

.scd-payment-institutions-accounts {
    height: 100%;
    padding: 10px 20px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;

    &__container {
        max-width: 1115px;
        min-height: 450px;
        width: 100%;
    }

    &__header {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        flex-wrap: nowrap;
        padding: 20px 0;
        width: 100%;

        &-container {
            display: flex;
            width: 100%;
            justify-content: space-between;

            &--title {
                font-size: 34px;
                font-weight: 500;
                color: #545454;
                margin: 0;
            }

            &--align-right {
                justify-content: flex-end;
                display: flex;
                align-items: center;
                flex: 1;
            }
        }

        &--buttons-content {
            padding: 20px 0px;
            display: flex;
            width: 100%;

            &-filter-button {
                padding-right: 10px;
            }

            &-error-arrow {
                content: $svg_error_arrow;
                width: 30px;
                height: 30px;
                margin-top: 8px;
                margin-left: 10px;
                cursor: pointer;
            }

            &-align-right {
                width: 100%;
                justify-content: flex-end;
                display: flex;
            }
        }
        @include responsive(s) {
            flex-direction: column;
        }
    }

    &__table {
        @include responsive(s) {
            width: 100%;
            overflow-x: auto;
        }

        .page-container--table {
            th {
                &:nth-child(1) {
                    width: 22%;
                }
                &:nth-child(2) {
                    width: 12%;
                }
                &:nth-child(3) {
                    width: 12%;
                }
                &:nth-child(4) {
                    width: 22%;
                }
            }
            td {
                &:nth-child(1) {
                    width: 21.2%;
                }
                &:nth-child(2) {
                    width: 13%;
                }
                &:nth-child(3) {
                    width: 12%;
                }
                &:nth-child(4) {
                    width: 22%;
                }
                &:nth-child(5) {
                    width: 19%;
                }
                &:nth-child(6),
                &:nth-child(7),
                &:nth-child(8) {
                    width: 4%;
                }
            }
            @include responsive(sm) {
                th {
                    &:nth-child(1) {
                        width: 27%;
                    }
                    &:nth-child(2) {
                        width: 15%;
                    }
                    &:nth-child(3) {
                        width: 15%;
                    }
                }
                td {
                    &:nth-child(1) {
                        width: 26%;
                    }
                    &:nth-child(2) {
                        width: 16%;
                    }
                    &:nth-child(3) {
                        width: 15%;
                    }
                }
            }

            @include responsive(s) {
                th {
                    &:nth-child(1) {
                        width: 235px;
                    }
                    &:nth-child(2) {
                        width: 150px;
                    }
                    &:nth-child(3) {
                        width: 150px;
                    }
                }
                td {
                    &:nth-child(1) {
                        width: 225px;
                    }
                    &:nth-child(2) {
                        width: 160px;
                    }
                    &:nth-child(3) {
                        width: 150px;
                    }
                    &:nth-child(4) {
                        width: 250px;
                    }
                }
            }

            &-status {
                display: inline-flex;
                padding: 10px;
                font-size: 11px;
                align-items: center;
                white-space: nowrap;
                justify-content: center;
                height: 24px;
                font-weight: 500;
                border-radius: 5px;

                &.ACTIVE {
                    background-color: #b8d759;
                }

                &.INACTIVE {
                    background-color: #db6968;
                    color: #ffffff;
                }
            }
        }

        &--content {
            min-height: 330px;
        }

        &--cell-icon {
            @include responsive(sm) {
                width: 7%;
            }

            @include responsive(s) {
                width: 7%;
            }
        }
    }

    &--container {
        width: 350px;
        display: flex;
        align-items: center;
        flex-direction: column;
        margin-top: 25px;
        position: relative;
        min-height: 500px;
    }

    &--header {
        display: flex;
        justify-content: flex-start;
        width: 100%;
        padding: 10px 20px 25px;

        &-title {
            font-size: 28px;
            font-weight: 500;
            text-align: center;
            color: #292a2a;
            width: 100%;
            margin: 0;
        }
    }
}