@use '~styles/constants' as *;

.issuer-policies__detail {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-bottom: 30px;
    
    &--container {
        width: 290px;
    }
}