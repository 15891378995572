.batch-operations-card {

    &--card-title {
        margin-bottom: 4px;
    }

    &--card-subtitle {
        font-size: 14px; 
        color: #666;
        font-family: 'Frutiger-light'; 
    }

    &--card-subtitle-expired {
        font-size: 14px;
        color: #A2191F;
        font-family: 'Frutiger-light'; 
        width: 225px;
        height: 35px;
        background-color: #fde3e3;
        padding: 12px 16px;
        border-radius: 8px;
        display: flex;
        align-items: center; 
        justify-content: center; 
    }

    &--card-subtitle-today {
        font-size: 14px;
        color: #9c8727; 
        font-family: 'Frutiger-light';
        width: 225px;
        height: 35px;
        background-color: #fff9df;
        padding: 12px 16px;
        border-radius: 8px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}

.operation-details-horizontal {
    display: flex;
    flex-wrap: wrap;  
    gap: 60px;        

    .scd-operation-read-only-text-field {
        flex-direction: column; 
        
        &__label,
        &__value {
            width: 100%; 
        }
    }
}