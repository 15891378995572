@use "~styles/constants" as *;

.button__style--standard {
    
    .MuiButton-contained {
        box-shadow: unset;
        background-color: $blurple;
        width: 188px;
        height: 48px;
        padding: 12px 16px;
        border-radius: 8px;
        border: solid 1.5px $blurple;
        color: $white;
        display: flex;
        justify-content: center;
        align-items: center;
        
        &:hover {
            width: 188px;
            height: 48px;
            background-color: $blurple_focus;
            color: $white;
            border: solid 1.5px $blurple_focus;
            box-shadow: unset;
        }

        &.Mui-disabled {
          opacity: 0.5;
          background-color: $blurple_focus;
          color: $white;
          border: solid 1.5px $blurple_focus;
        }

    }

    .button--arrow {
        color: $brown_grey_three;
    }
    
    .MuiButton-label {
        margin: 0px;
        font-size: 16px;
        font-weight: 500;
        text-transform: capitalize;
    }
}