@use "~styles/constants" as *;

.pending-button__style--link-outlined {
    .MuiButton-contained {
        background-color: transparent;
        border: $organization_primary 1px solid;
        box-shadow: unset;
    }

    .MuiButton-contained:hover {
        background-color: transparent;
        border: $organization_primary 1px solid;
        box-shadow: unset;
    }

    .MuiButton-label {
        justify-content: center;
    }

    .MuiButton-contained:hover,
    .MuiButton-contained {
        color: $organization_primary;
        font-size: 0.875rem;
        font-family: Frutiger;
        padding: 7px 40px;
        font-weight: 400;
        letter-spacing: 1.5px;
        border-radius: 10px;
        white-space: nowrap;
        width: 100%;
        height: 100%;
    }

    .MuiButton-contained.Mui-disabled {
        color: $organization_primary;
        background-color: transparent;
        font-size: 0.875rem;
        font-family: Frutiger;
        padding: 10px 30px;
        font-weight: 400;
        letter-spacing: 1.5px;
        border-radius: 10px;
        white-space: nowrap;
        width: 100%;
        height: 100%;
        opacity: 0.5;
    }

    &-circle {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        border-radius: 50%;        
        margin-left: 8px;
        padding: 1px 3px 1px 4px;
        min-width: 28px;
        min-height: 26px;
        text-align: center;
        border: $organization_primary 1px solid;

        &.more {
            min-height: 33px;
            min-width: 33px;
        }
    }
}
