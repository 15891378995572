@use '~styles/constants' as *;

.search-input {
    width: 100%;

    .MuiFormControl,
    .MuiInputBase {
        &-root {
            border-radius: 6px;
            background-color: #f6f6f6 !important;
            border: solid 1px #e8ebec;
        }
    }

    .MuiFormLabel-root {
        color: #9199a1;
        opacity: 0;
        z-index: 2;
        font-size: 13px;
        transform: translate(15px, 9px) scale(0.75) !important;
        &.Mui-focused {
            color: $brown_grey;
            opacity: 1;
        }
    }

    .MuiButtonBase-root {
        outline: 0;
    }

    .form-control,
    .form-control:focus {
        box-shadow: none;
        border: none;
        border-radius: 6px;
    }

    .MuiInputBase {
        &-root,
        .form-control {
            padding: 0;
        }
        &-input,
        .form-control {
            font-size: 15px;
            padding: 17px 15px 14px;
        }
    }

    label[data-shrink='false'] + .MuiInputBase-formControl .MuiInputBase-input::placeholder {
        opacity: 1 !important;
        color: #9199a1;
    }

    .Mui-focused {
        ::placeholder {
            opacity: 0;
        }
    }

    .MuiButtonBase-root {
        padding: 12px;
        &:hover {
            background: none;
        }
    }

    .MuiFilledInput-underline::before,
    .MuiFilledInput-underline::after {
        display: none;
        &.Mui-focused::after {
            display: block;
            border-bottom: 1px solid #dddddd;
        }
    }

    .MuiInputAdornment-root {
        background-color: inherit !important;
        margin: 0;
        .MuiIcon-root {
            display: flex;
            align-items: center;
            justify-content: center;
            .search--icon {
                content: $svg_search;
                display: flex;
                width: 60%;
            }
        }
    }
    .MuiFormLabel.Mui-focused {
        color: #9199a1;
        font-size: 15px;
        opacity: 1 !important;
    }

    input:not([readonly]):focus {
        padding: 24px 15px 7px;
    }

    .Mui-focused {
        &label {
            opacity: 1 !important;
        }
        &.MuiFormControl,
        &.MuiInputBase {
            &-root {
                border-radius: 6px;
                background-color: $white_two !important;
                border: solid 1px #aaaaaa;
            }
        }
    }

    &.readonly {
        .MuiFormControl,
        .MuiInputBase {
            &-root {
                border-radius: 6px;
                background-color: #f6f6f6 !important;
                border: solid 1px #e8ebec;
            }
        }
        .MuiFormLabel-root {
            color: #9199a1;
            opacity: 0;
            z-index: 2;
            font-size: 13px;
            transform: translate(15px, 9px) scale(0.75) !important;
        }
        .MuiInputBase-input::placeholder {
            opacity: 1 !important;
            color: #9199a1;
        }
    }

    &.dirty {
        label {
            opacity: 1 !important;
        }
        .form-control,
        .MuiInputBase-input {
            padding: 24px 15px 7px;
        }
        .MuiFormControl,
        .MuiInputBase {
            &-root {
                border-radius: 6px;
                background-color: $white_two !important;
                border: solid 1px #aaaaaa;
            }
        }
    }

    &.searchActive {
        .MuiFormControl,
        .MuiInputBase {
            &-root {
                border: none !important;
                background-color: $white_two !important;
            }
        }
    }
}
