@use '~styles/constants' as *;

.company-modal-filter {
    margin-bottom: 15px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 30px;
    width: 420px;

    &--header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        margin-bottom: 16px;

        &-title {
            font-size: 20px;
            font-weight: bold;
            color: #292a2a;
            letter-spacing: 0.3px;
            line-height: 1.2;
            margin: 0;
        }
    }

    &--buttons {
        &-actions {
            display: flex;
            align-items: center;
            justify-content: center;
            margin-top: 25px;
            > :nth-child(2) {
                margin-left: 25px;
            }
        }
    }

    &-search-content {
        &.active {
            height: 120px;
            border: 1px solid #3f41d1;
            border-radius: 7px;
        }
    }
}
