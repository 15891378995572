.batch-card {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;

    width: 270px;
    height: 110px;

    color: #343434;
    background-color: #ffffff;
    border: 1px solid #d8d8d8;
    border-radius: 6px;
}
