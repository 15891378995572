@use "~styles/constants" as *;

.scd-originator__detail {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: 30px;

  &--container {
    width: 290px;
    margin-top: 25px;

  }
}
