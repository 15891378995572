@use '~styles/constants' as *;

.modal-text-area--container {
    margin-bottom: 15px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 30px;
    width: 420px;
}

.modal-text-area--header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-bottom: 16px;

    &-title {
        font-size: 20px;
        font-weight: bold;
        color: #292a2a;
        letter-spacing: 0.3px;
        line-height: 1.2;
        margin: 0;
    }
}

.modal-text-area--buttons {
    &-actions {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 25px;
        > :nth-child(2) {
            margin-left: 25px;
        }

        &-blank {
            width: 200px;
        }
    }
}
