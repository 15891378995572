@use '~styles/constants' as *;


.scd-batch-endorsement {
    height: 100%;
    padding: 10px 20px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;

    &__container {
        max-width: 1115px;
        min-height: 450px;
        width: 100%;
        
        &__loading-box {
            width: 100%;
            height: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            margin-top: 48px;
            gap: 12px;
        }
    }
    

    &__sub-header {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        width: 80%;

        @media screen and (max-height: 720px) {
            margin-top: 15px;
        }
        
        h3 {
            font-size: 18px;
            font-weight: 300;
        }
    }

    &--counters {
        display: flex;
        color: #545454;
        margin-right: 20px;
    }

    &--display {
        color: #343434;
        border: 1px solid #343434;
        padding: 10px;
        border-radius: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 30px;
        height: 15px;
        font-size: 12px;
        margin-left: 5px;
    }

    &__table-header {
        display: flex;
        align-items: center;
    }
   

    &__header {
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        padding: 20px 0;
        width: 100%;

        &-container {
            display: flex;
            flex-direction: column;
            width: 100%;

            &--content {
                display: flex;
                justify-content: space-between;

                .page-container--table-status {
                    display: inline-flex;
                    font-size: 11px;
                    align-items: center;
                    white-space: nowrap;
                    justify-content: flex-start;
                    height: 24px;
                    font-weight: 500;
                    border-radius: 5px;
                    padding: 10px;
                    margin-top: 3px;
                    
                    &.CLOSED {
                        background-color: #b8d759;
                    }
                    
                    &.CONCLUDED {
                        background-color: #6acff1;
                    }
                }
            }

            &--title {
                display: flex;
                align-items: center;
                font-size: 34px;
                font-weight: 500;
                color: #545454;
                margin: 0;
            }

            &--subtitle {
                font-size: 19px;
                color: #bac0c2;
                margin: 15px 0px;
            }

            &--sub-itens {
                display: flex;
                justify-content: space-between;
                align-items: center;
                height: auto;
            }

            &--buttons {
                display: flex;
                align-items: center;
                justify-content: flex-end;
                width: 200px;
                height: 29px;
            }
            
        }
        &--select-operations {
            display: flex;
            align-items: center;
            text-transform: uppercase;
            cursor: pointer;
    
            &__icon {
                content: $svg_select;
            }
            
            &__value {
                letter-spacing: 1.5px;
                color: #3f41d1;
                font-weight: 400;
                font-size: 14px;
                margin: 0px 8px;
                line-height: 24px;
            }
        }

        @media screen and (max-height: 720px) {
            padding: 20px 0 0 0;
            height: 120px;
       
            &--subtitle {
                margin: 10px 0;
            }
        }

        @include responsive(s) {
            flex-direction: column;
        }
    }
 

    &__table {
        width: 100%;

        &__header {
            display: flex;
           
            h3 {
                font-size: 22px;
                font-weight: 400;
                margin-right: 10px;
            }

            &-documents {
                color: #343434;
                border: 1px solid #343434;
                padding: 10px;
                border-radius: 20px;
                display: flex;
                align-items: center;
                justify-content: center;
                width: 45px;
                height: 28px;
                font-size: 12px;
            }

            &-align-right {
                width: 90%;
                justify-content: flex-end;
                display: flex;
            }
        }

        thead {
            &.size {
                display: block;
                width: calc(100% - 14px);
            }
        }

     
        tbody {
            display: block;
            overflow-y: auto;
            width: 100%;
            max-height: calc(100vh - 470px);
        }

        .page-container--table-cell {
            height: 50px;
        }

        .page-container--table {
            th {
                &:nth-child(1) {
                    width: 16%;
                }
                &:nth-child(2) {
                    width: 17.2%;

                    @media screen and (max-height: 720px) {
                        width: 17.3%;
                    }
                }
                &:nth-child(3) {
                    width: 26.9%;

                    @media screen and (max-height: 720px) {
                        width: 26.9%;
                    }
                }
                &:nth-child(4) {
                    width: 15.8%;

                    @media screen and (max-height: 720px) {
                        width: 15.7%;
                    }
                }
                &:nth-child(5) {
                    width: 17.3%;

                    @media screen and (max-height: 720px) {
                        width: 17.3%;
                    }
                }
                &:nth-child(6) {
                    width: 4.5%;
                }
            }
            td {
                margin-right: 11px;
                

                &:nth-child(1) {
                    width: 15%;
                }
                &:nth-child(2) {
                    width: 16%;
                }
                &:nth-child(3) {
                    width: 25.9%;
                    div {
                        width: 80%;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                    }
                }
                &:nth-child(4) {
                    width: 15%;
                }
                &:nth-child(5) {
                    width: 15%;
                }
            
                &:nth-child(6) {
                    flex: 1;
                    span {
                        width: 35px;
                    }
                }
            }
        }

        &--content {
            min-height: 330px;
        }
    }

    &__content {

        &-slide {
            width: 100%;
            background: #ffff;
            position: fixed;
            bottom: 52px;
            left: 0;
            right: 0;
            display: flex;
            justify-content: center;
            height: 90px;
            align-items: center;
            font-family: 'Frutiger';


            &-buttons {
                display: flex;
                justify-content: flex-end;
                align-items: center;
                gap: 25px;
                width: 1115px;

                &--import-left-box {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    width: 45%;
                }

                &--import-right-box {
                    display: flex;
                    justify-content: flex-end;
                    width: 50%;

                    div {
                        margin: 0 30px;
                    }
                }
                
                &-style {
                    width: 245px;
                    margin: 0 10px;
                }

                &--redirect-buttons {
                    display: flex;
                    align-items: center;
                    text-transform: uppercase;
                    font-size: 14px;
                    cursor: pointer;
                    
                    
                    &__value {
                        letter-spacing: 1.5px;
                        color: #3f41d1;
                        font-weight: 400;
                        font-size: 14px;
                        margin: 0px 8px;
                        line-height: 24px;
                    }
                }

                &--left-box {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    width: 50%;
                }
        
                &--redirect-box {
                    display: flex;
                    justify-content: flex-end;
                    width: 45%;
                }
                &--redirect-box-no-select {
                    display: flex;
                    justify-content: flex-end;
                    width: 100%;
                }
            }
        }
    }

    &__collapse {
        
        &--container {
            display: flex;
            width: calc(100% - 9px);
            flex-direction: column;
        }

        tbody {
            display: block;
            overflow-y: auto;
            height: calc(100vh - 500px);
            width: 100%;

            @media screen and (max-height: 720px) {
                height: calc(100vh - 450px);
            }
        }
        
        &-header {
            width: 100%;
            
            &--fixed {
                display: flex;
                align-items: center;
                flex-wrap: nowrap;
                height: 30px;
                padding: 0 10px;
                border-bottom: solid 2px #d8d8d8;
                overflow: hidden;

                
                &.size {
                    width: calc(100% - 15px);
                }

                th {
                    color: #bac0c2;
                    font-family: 'Frutiger';
                    letter-spacing: 1px;
                    text-transform: uppercase;
                    font-size: 11px;
                    font-weight: bold;

                    &:nth-child(1) {
                        width: 7.2%;
                    }
                    &:nth-child(2) {
                        width: 25%;
                        @media screen and (max-height: 720px) {
                            width: 24.6%;
                        }
                    }
                    &:nth-child(3) {
                        width: 30%;
                        @media screen and (max-height: 720px) {
                            width: 29.3%;
                        }
                    }
                    &:nth-child(4) {
                        width: 31%;
                    }
                    &:nth-child(5) {
                        flex: 1;
                        span {
                            width: 45px;
                        }
                    }
                }

            }
        }

        &-content {
            display: flex;
            align-items: center;
            padding: 10px;
            border-bottom: 1px solid #d8d8d8;
            font-family: 'Frutiger-light';
            font-size: 14px;
            height: 50px;

            td {
                width: 30%;

                &:nth-child(1) {
                    width: 7%;
                    cursor: pointer;

                    &.disable {
                        svg {
                            opacity: 0.5;
                        }
                    }
                    svg {
                        transform: rotate(0.75turn);
                        transition: 0.2s ease-in-out;
                    }
                    .active {
                        transform: rotateX(1turn);
                    }
                }
                &:nth-child(2) {
                    width: 25%;

                }
           
                &:nth-child(4) {
                    width: 31%;

                }
                &:nth-child(5) {
                    flex: 1;
                }
            }

            &-status {
                display: inline-flex;
                font-size: 11px;
                align-items: center;
                white-space: nowrap;
                justify-content: flex-start;
                height: 24px;
                font-weight: 500;
                border-radius: 5px;
                padding: 10px;


                &.SENT {
                    background-color: #b8d759;
                }

                &.PENDING {
                    background-color: #f2c466 ;
                }

                &.SIGNED {
                    background-color: #6acff1;
                }

                &.ERROR {
                    background-color: #ff7978;
                }
            }
            
            
            &-cell {
                &.isCheck {
                    span{
                        width: 48px;
                    }
                }
            }
        } 

        &__error-message {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            margin-top: 100px;

            &-image {
                content: $svg_piggy_bank;
                width: 100px;
                height: 100px;
            }
            &-title {
                font-size: 26px;
                line-height: 24px;
                color: #d8d8d8;
                padding-top: 10px;
            }
        }

    }

    &__sublist {
        width: 100%;
        height: auto;
        font-size: 12px;
        background-color: #ffffff;

        &-content {
            display: flex;
            align-items: center;
            width: 100%;
            height: 50px;
            padding: 10px;
            border-bottom: 1px solid #d8d8d8;
            font-family: 'Frutiger-light';
            font-size: 14px;

        }
        &-status {
            display: inline-flex;
            font-size: 11px;
            align-items: center;
            white-space: nowrap;
            justify-content: flex-start;
            height: 24px;
            font-weight: 500;
            border-radius: 5px;
            padding: 10px;
    
            &.SUCCESS {
                background-color: #6acff1;
            }
    
            &.FAILURE {
                background-color: #ff7978;
            }
       
        }

        td {
            width: 20%;

            &:nth-child(1) {
                margin-left: 32%;
                width: 30%;
            }
            &:nth-child(2) {
                width: 31%;
            }
            &:nth-child(3) {
                display: flex;
                align-items: center;
                height: 35px;
                flex: 1;
            }
        }

      
    }

    &__not-sents {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 50px;
        font-family: 'Frutiger';
        background-color: #fff;
        color: #343434;
    }
}