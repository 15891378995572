.disbursement-confirmation-list-header {
    width: 100%;
    height: 30px;
    border-bottom: solid 2px #d8d8d8;
    display: block;

    tr {
        width: 100%;
        display: flex;
        justify-content: space-between;
    }

    &__table-header {
        color: #bac0c2;
        font-family: 'Frutiger', sans-serif;
        letter-spacing: 1px;
        text-transform: uppercase;
        font-size: 11px;
        font-weight: bold;
        padding: 0px;
        width: 19%;
        &:nth-child(1) {
            width: 7%;
        }
        &:nth-child(7) {
            width: 5%;
        }
    }
}
