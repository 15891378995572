@use '~styles/constants' as *;

.scd-issuer {
    width: 100%;
    height: 100%;
    padding: 0px 24px;
    display: flex;
    flex-direction: column;
    align-items: center;

    &__container {
        width: 100%;
        max-width: 1116px;
        min-height: 440px;
    }

    &__header {
        width: 100%;
        display: flex;
        row-gap: 12px;
        align-items: center;
        justify-content: space-between;
        flex-wrap: nowrap;

        &--title {
            font-size: 34px;
            font-weight: 500;
            color: #545454;
            margin: 0;

            @include responsive(sm) {
                text-align: center;
                font-size: 32px;
            }

            @include responsive(s) {
                text-align: center;
                font-size: 32px;
                margin-bottom: 20px;
            }

            @include responsive(ss) {
                text-align: center;
                font-size: 29px;
            }
        }

        @include responsive(s) {
            flex-direction: column;
        }
    }

    &__table {
        width: 100%;
        margin: 8px 0px 0px;

        .page-container--table {
            display: inline-block;
            
            @media screen and (min-height: 850px){
                height: 400px;
            }
            
            thead {
                width: 100%;
                &:first-child {
                    display: inline-block;
                }
            }
            
            tbody { 
                display: block;
                height: calc(100vh - 332px);
            }

            &-container::-webkit-scrollbar {
                width: 4px;
                background-color: #e1e2e6;
            }

            &-container::-webkit-scrollbar-thumb {
                width: 3px;
                background: #a4a4a4;
            }

            &.EMPTY_COLLECTION {
                tbody {
                    min-height: 0px;
                    height: min-content;
                }
            }

            th {
                &:nth-child(1) {
                    width: 37.3%;
                }
                &:nth-child(2) {
                    width: 24%;
                }
                &:nth-child(3) {
                    width: 35%;
                }
            }
            td {
                &:nth-child(1) {
                    width: 42%;
                    margin-right: 15px;
                    line-height: 1.2rem;
                }
                &:nth-child(2) {
                    width: 28%;
                }
                &:nth-child(3) {
                    width: 29%;
                }

            }

            @include responsive(sm) {
                th {
                    &:nth-child(1) {
                        width: 36%;
                    }
                    &:nth-child(2) {
                        width: 24%;
                    }
                    &:nth-child(3) {
                        width: 35%;
                    }
                }
                td {
                    &:nth-child(1) {
                        width: 39%;
                    }
                    &:nth-child(2) {
                        width: 28%;
                    }
                    &:nth-child(3) {
                        width: 30%;
                    }

                }
            }

            @include responsive(s) {
                th {
                    &:nth-child(1) {
                        width: 265px;
                    }
                    &:nth-child(2) {
                        width: 200px;
                    }
                    &:nth-child(3) {
                        width: 200px;
                    }
                }
                td {
                    &:nth-child(1) {
                        width: 280px;
                    }
                    &:nth-child(2) {
                        width: 220px;
                    }
                    &:nth-child(3) {
                        width: 120px;
                    }
                    &:nth-child(4) {
                        width: 40px;
                    }
                    &:nth-child(5) {
                        width: 40px;
                    }
                    &:nth-child(6) {
                        width: 40px;
                    }
                }
            }
            &-status {
                display: inline-flex;
                padding: 10px;
                font-size: 11px;
                align-items: center;
                white-space: nowrap;
                justify-content: center;
                height: 24px;
                font-weight: 500;
                border-radius: 5px;

                &.ACTIVE {
                    background-color: #b8d759;
                }

                &.INACTIVE {
                    background-color: #db6968;
                    color: #ffffff;
                }
            }
        }

        &--content {
            min-height: 330px;
        }

        &-empty-filter {
            width: 100%;
            height: 260px;
            display: flex;
            justify-content: center;
            align-items: center;
            font-family: 'Frutiger';
            flex-direction: column;

            &-image {
                content: $svg_piggy_bank;
                width: 100px;
                height: 100px;
            }

            &-title {
                font-size: 26px;
                line-height: 24px;
                color: #d8d8d8;
                padding-top: 10px;
            }

            &-subtitle {
                font-size: 16px;
                line-height: 24px;
                color: #d8d8d8;
                padding-top: 10px;
            }
        }
    }
}
