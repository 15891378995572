@use '~styles/constants' as *;

.operation-modal-filter {
  margin-bottom: 15px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 30px;
  width: 420px;

  &--content {
    display: flex;
    flex-wrap: wrap;

    &-status--label {
      padding: 0px 22px;
      margin: 5px 5px;
      height: 43px;
      border-radius: 17px;
      background-color: #f0f0f0;
      color: #163c6e;
      display: flex;
      justify-content: center;
      align-items: center;
      font-family: 'Frutiger';
      font-size: 12px;
      letter-spacing: 1.5px;
      cursor: pointer;

      &.NEW {
        background-color: #42BE65;
        color: #ffffff;
      }

      &.PENDING_APPROVAL {
        color: #ffffff;
        background-color: #A56EFF;
      }

      &.DISAPPROVED {
        background-color: #FF832B;
        color: #ffffff;
      }

      &.RECEIVED {
        color: #ffffff;
        background-color: #FF7EB6;
      }

      &.APPROVED {
        background-color: #33B1FF;
        color: #ffffff;

      }

      &.EXPORTED {
        background-color: #6acff1;
        color: #ffffff;
      }

      &.PROCESSED {
        background-color: #08BDBA;
        color: #ffffff;
      }

      &.ERROR {
        background-color: #FA4D56;
        color: #ffffff;
      }

      &.BATCHLESS {
        background-color: #bac0c2;
      }

      &.ARCHIVED {
        color: #ffffff;
        background-color: #A2A9B0;
      }
    }

    &-product--label {
      width: 80px;
      height: 43px;
      margin: 5px 5px;
      border-radius: 17px;
      background-color: #f0f0f0;
      color: #163c6e;
      display: flex;
      justify-content: center;
      align-items: center;
      font-family: 'Frutiger';
      font-size: 12px;
      letter-spacing: 1.5px;
      cursor: pointer;

      &.active {
        background-color: #163c6e;
        color: #ffff;
      }
    }

    &-batchless-input {
      display: flex;
      flex-direction: column;
      border-radius: 6px;
      background: #f6f6f6;
      padding: 10px 20px;
      width: 360px;

      &__label {
        color: #7b7b7b;
        font-weight: 300;
        font-size: 13px;
        letter-spacing: 0.541667px;
      }

      &__value {
        font-weight: 300;
        font-size: 16px;
        line-height: 16px;
        letter-spacing: 0.5px;
        color: #343434;
      }
    }
  }

  &-search-content {
    &.active {
      height: 120px;
      border: 1px solid #3f41d1;
      border-radius: 7px;
    }
  }
}

.operation-modal-filter--header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 16px;

  &-title {
    font-size: 20px;
    font-weight: bold;
    color: #292a2a;
    letter-spacing: 0.3px;
    line-height: 1.2;
    margin: 0;
  }
}

.operation-modal-filter--buttons {
  &-actions {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 25px;

    > :nth-child(2) {
      margin-left: 25px;
    }
  }
}
