@use '~styles/constants' as *;

.monthly-commission-modal-filter {
    margin-bottom: 15px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 30px;
    width: 420px;

    &--header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        margin-bottom: 16px;

        &-title {
            font-size: 20px;
            font-weight: bold;
            color: #292a2a;
            letter-spacing: 0.3px;
            line-height: 1.2;
            margin: 0;
        }
    }
    &--content {
        display: flex;
        flex-wrap: wrap;

        &-status--label {
            padding: 0px 22px;
            margin: 5px 5px;
            height: 43px;
            border-radius: 17px;
            background-color: #f0f0f0;
            color: #163c6e;
            display: flex;
            justify-content: center;
            align-items: center;
            font-family: 'Frutiger';
            font-size: 12px;
            letter-spacing: 1.5px;
            cursor: pointer;

            &.NEW {
                color: #ffffff;
                background-color: #DB6968;
            }

            &.WAITING {
                background-color: #F2C466;
            }

            &.RELEASED {
                background-color: #B8D759;
            }

            &.TRANSFERRING {
                background-color: #6ACFF1;
            }

            &.PAID {
                color: #ffffff;
                background-color: #163C6E;
            }
        }
    }

    &--buttons {
        &-actions {
            display: flex;
            align-items: center;
            justify-content: center;
            margin-top: 25px;
            > :nth-child(2) {
                margin-left: 25px;
            }
        }
    }

    &-search-content {
        &.active {
            height: 120px;
            border: 1px solid #3f41d1;
            border-radius: 7px;
        }
    }
}
