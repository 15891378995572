@use '~styles/constants' as *;

.header-content {
    display: flex;
    flex-direction: column;

    &__title-content {
        font-family: 'Frutiger-45-light';
        color: #9199a1;
    }

    &__type-content {
        width: 36px;
        height: 20px;
        padding: 5px, 8px, 5px, 8px;
        border: 2px solid #163c6e;
        border-radius: 4px;
        font-family: 'Frutiger-65';
        font-weight: 700;
        font-size: 10px;
        line-height: 17px;
        text-align: center;
        color: #163c6e;
    }

    &__default-content {
        font-family: 'Frutiger-55';
        font-weight: 500;
        font-size: 15px;
        max-width: 130px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
}
