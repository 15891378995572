@use '~styles/constants' as *;

.transfeera-statement {
    height: 100%;
    padding: 10px 20px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;

    &__container {
        max-width: 1115px;
        min-height: 450px;
        width: 100%;
    }

    &__header {
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        width: 100%;
        padding: 20px 0;

        &-container {
            display: flex;
            width: 100%;
            align-items: center;
            justify-content: space-between;

            &--title {
                font-size: 34px;
                font-weight: 500;
                color: #545454;
                margin: 0;

            }

            &--align {
                display: flex;
                justify-content: flex-start;
                align-items: center;
            }
        }

        &-account-infos {
            display: flex;
            justify-content: space-between;
            width: 30%;

            &--item {
                font-size: 19px;
                color: #bac0c2;
                margin: 15px 0px;
            }

            &--status {
                display: inline-flex;
                padding: 10px;
                font-size: 11px;
                align-items: center;
                white-space: nowrap;
                justify-content: center;
                height: 24px;
                font-weight: 500;
                border-radius: 5px;
                margin: 15px 0px;

                &.ACTIVE {
                    background-color: #b8d759;
                }

                &.INACTIVE {
                    background-color: #db6968;
                    color: #ffffff;
                }
            }
        }

        &-buttons-content {
            padding: 10px 0px;
            display: flex;
            width: 50%;

            &__filter-button {
                padding-right: 10px;
            }

            &__error-arrow {
                content: $svg_error_arrow;
                width: 30px;
                height: 30px;
                margin-top: 8px;
                margin-left: 10px;
                cursor: pointer;
            }
        }

        &-balance {
            display: flex;
            justify-content: space-between;
            align-items: flex-start;
        }
    }

    &__table {
        width: 100%;
        overflow-x: auto;

        .page-container--table {
            height: calc(100vh - 480px);
            display: inline-block;

            thead {
                width: 100%;

                &:first-child{
                    display: inline-block;
                }
            }

            tbody {
                display: block;
                min-height: 250px;
                height: 100%;
            }

            &.EMPTY_COLLECTION {
                tbody {
                    min-height: 0px;
                    height: min-content;
                }
            }

            th {
                &:nth-child(1){
                    width: 13%;
                }

                &:nth-child(2){
                    width: 16%;
                }

                &:nth-child(3) {
                    width: 14.3%;
                }

                &:nth-child(4){
                    width: 10%;
                }

                &:nth-child(5){
                    width: 15%;
                }

                &:nth-child(6){
                    width: 14.5%;
                }

                &:nth-child(7){
                    flex: 1;
                }
            }

            td {
                span {
                    display: inline-block;
                    margin-right: 15px;
                    color: #4a4a4a;
                    font-size: 14px;
                    font-family: "Frutiger-light";
                }

                &:nth-child(1) {
                    width: 13%;
                }

                &:nth-child(2) {
                    width: 16%;
                }

                &:nth-child(3) {
                    width: 15%;
                }

                &:nth-child(4) {
                    width: 10%;
                }

                &:nth-child(5) {
                    width: 15%;
                }

                &:nth-child(6) {
                    width: 15%;
                }

                &:nth-child(7) {
                    flex: 1;
                }

                &:nth-child(8) {
                    width: 5%;

                    .MuiIcon-root {
                        width: 24px;
                        height: 24px;
                    }
                }
            }

            &-status {
                display: inline-flex;
                padding: 10px;
                font-size: 11px;
                align-items: center;
                white-space: nowrap;
                justify-content: center;
                height: 25px;
                font-weight: 500;
                border-radius: 5px;

                &.CRIADA {
                    background-color: #f2c466;
                    color: #343434;
                }

                &.RECEBIDO {
                    background-color: #2c5d6c;
                    color: #f0f0f0;
                }

                &.TRANSFERIDO {
                    background-color: #6acff1;
                }

                &.AGENDADO {
                    background-color: #b8d759;
                }

                &.FALHA {
                    background-color: #db6968;
                    color: #ffffff;
                }

                &.DEVOLVIDO {
                    background-color: #9199a1;
                    color: #ffffff;
                }
            }
        }

        &--content {
            min-height: 330px;
        }

        &-fields {
            display: flex;

            &--favored-value {
                width: 190px;
                margin-right: 10px;
                align-items: center;

                div {
                    width: 100%;
                    height: 20px;
                    line-height: 20px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                }
            }

            &--agency-value {
                width: 140px;
                margin-right: 10px;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                display: flex;
                align-items: center;
            }

            &--account-value {
                width: 174px;
                margin-left: 8px;
                overflow: hidden;
                text-overflow: ellipsis;
                line-height: 16px;
                align-items: center;
            }

            &--amount-value {
                width: 140px;
                margin-right: 10px;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                align-items: center;
            }
        }

        &-empty-filter {
            width: 100%;
            height: 260px;
            display: flex;
            justify-content: center;
            align-items: center;
            font-family: 'Frutiger';
            flex-direction: column;

            &-image {
                content: $svg_piggy_bank;
                width: 100px;
                height: 100px;
            }

            &-title {
                font-size: 26px;
                line-height: 24px;
                color: #d8d8d8;
                padding-top: 10px;
            }

            &-subtitle {
                font-size: 16px;
                line-height: 24px;
                color: #d8d8d8;
                padding-top: 10px;
            }
        }
    }

    &--container {
        width: 350px;
        display: flex;
        align-items: center;
        flex-direction: column;
        margin-top: 25px;
        position: relative;
        min-height: 500px;
    }

    &--header {
        display: flex;
        justify-content: flex-start;
        width: 100%;
        padding: 10px 20px 25px;

        &-title {
            font-size: 28px;
            font-weight: 500;
            text-align: center;
            color: #292a2a;
            width: 100%;
            margin: 0;
        }
    }
}