.opened-drawer {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    padding: 0 0 104px 0;

    &--header {
        width: 100%;
        min-height: 136px;
        position: relative;
        display: flex;
        justify-content: center;
        padding: 48px 20px 0;

        &--logo_box {
            width: 164px;
            height: 40px;
            display: flex;
            justify-content: center;
            align-items: start;

            &--logo {
                width: 100%;
                height: 100%;
                background: transparent;
                border: none;
                padding: 0;

                img {
                    width: 100%;
                    height: 100%;
                }
            }
        }

        &--close-button {
            width: 32px;
            height: 32px;
            background: #163C6E;
            border: none;
            border-radius: 8px;
            position: absolute;
            top: 20px;
            right: 20px;

            display: flex;
            justify-content: center;
            align-items: center;

            &:hover {
                background: #32AACF;
            }

        }
    }

    &--items_box {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        padding: 16px 22px 0;
        overflow-y: scroll;
        overflow-x: hidden;

        &::-webkit-scrollbar {
            width: 4px;
            background-color: #072056;
        }

        &::-webkit-scrollbar-thumb {
            width: 3px;
            background: #a4a4a4;
        }
    }



    &--footer {
        width: 100%;
        height: 104px;
        padding: 28px 24px;

        display: flex;
        align-items: center;
        justify-content: center;

        position: absolute;
        bottom: 0;

        &--button {
            width: 244px;
            height: 48px;
            background: #163C6E;
            border: none;
            border-radius: 8px;
            color: #fff;
            font-size: 16px;
            cursor: pointer;

            display: flex;
            align-items: center;
            column-gap: 8px;
            padding: 0 28px;

            font-family: 'Frutiger', sans-serif;

            &:hover {
                background: #32AACF;
            }
        }
    }
}
