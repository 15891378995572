@use '~styles/constants' as *;

.read-only-value-input {
    width: 160px;
    height: 50px;
    margin-bottom: 5px;
    margin-top: 5px;
    margin-right: 10px;
    position: relative;
    z-index: 1;
    background: #f6f6f6;
    border-radius: 6px;
    padding: 12px 10px 10px 10px;

    &--content {
        display: flex;
        height: 100%;
        flex-direction: column;
        display: flex;
        justify-content: center;
    }

    &--icon {
        width: 18px;
        height: 18px;
        position: absolute;
        right: 0px;
        top: -13px;
        z-index: 2;
        cursor: pointer;
        opacity: 0;

        path {
            fill: #db6968;
        }

        &.active {
            opacity: 1;
        }
    }

    &--label {
        color: #9199a1;
        font-family: 'Frutiger-light';
        font-size: 10px;
        letter-spacing: 0.541667px;
    }

    &--value {
        letter-spacing: 0.5px;
        font-family: 'Frutiger-light';
        font-size: 13px;
        color: #343434;
    }
}
