.batch-collaterals-card {

    &--card-title {
        margin-bottom: 4px;
    }

    &--card-subtitle {
        font-size: 14px; 
        color: #666;
        font-family: 'Frutiger-light'; 
    }
}