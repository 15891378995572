@use "~styles/constants" as *;

.scd-monthly-processed-operations-report {
    width: 100%;
    display: flex;
    justify-content: center;

    &__container {
        width: 350px;
        display: flex;
        align-items: center;
        flex-direction: column;
        position: relative;
        min-height: 500px;
    }

    &--header {
        display: flex;
        justify-content: flex-start;
        width: 100%;
        padding: 10px 20px 25px;

        &-title {
            font-size: 28px;
            font-weight: 500;
            text-align: center;
            color: #292a2a;
            width: 100%;
            margin: 0;
            text-align: center;
        }
    }

    &--form {
        width: 100%;
        margin: 0 auto 50px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;

        .MuiChip-root {
            text-align: left;
            margin: 3px;
            width: 87%;
            justify-content: flex-start;
            border-radius: 5px;

            .MuiChip-label{
                width: 100%;
            }
        }

        &-field {
            display: flex;
            width: 100%;
            margin-bottom: 5px;
            min-height: 65px;
            padding: 0 20px;
            align-items: center;
            gap: 20px;

            &__icon {
                width: 45px;
                height: 45px;
                display: flex;
                justify-content: center;
                align-items: center;
                background-color: #3f41d1;
                color: white;
                border-radius: 5px;
                margin-left: 5px;
                padding: 10px;
                cursor: pointer;
            }
        }

        &-card {
            display: flex;
            flex-direction: column;
            align-items: center;
            margin: 10px 0 0 0;
            min-height: 45px;
            width: 100%;

            &-field {
                margin-bottom: 15px;
            }
        }

        &-buttons {
            display: flex;
            justify-content: space-between;
            width: 100%;
            padding: 0 25px;
            margin-top: 30px;
        }
    }
}

.MuiSnackbarContent-root{
    margin-bottom: 30px;
}