@use "~styles/constants" as *;

.button__style--policy-icon {
    .MuiButton-contained,
    .MuiButton-contained:hover {
        background: none;
        box-shadow: unset;
        border: none;
        outline: none;
        min-width: 20px;
        max-width: 100%;
        &.Mui-disabled {
            .button__style--policy-icon--icon {
                content: $svg_policy_disable;
            }
            background-color: transparent;
        }
    }

    .MuiButton-label {
        width: auto;
    }

    &--icon {
        content: $svg_policy;
        height: 30px;
        width: 30px;
        &:hover {
            content: $svg_policy-hover;
        }

        &.ACTIVE {
            content: $svg_policy;

            &:hover {
                content: $svg_policy-hover;
            }
        }
    }
}
