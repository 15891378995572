@use "~styles/constants" as *;

.scd-legacy-program {
    height: 100%;
    padding: 10px 20px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;

    &__container {
        max-width: 1115px;
        min-height: 450px;
        width: 100%;
    }

    &__header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-wrap: nowrap;
        padding: 20px 0;
        width: 100%;

        &--title {
            font-size: 34px;
            font-weight: 500;
            color: #545454;
            margin: 0;

            @include responsive(sm) {
                text-align: center;
                font-size: 32px;
            }

            @include responsive(s) {
                text-align: center;
                font-size: 32px;
                margin-bottom: 20px;
            }

            @include responsive(ss) {
                text-align: center;
                font-size: 29px;
            }
        }

        @include responsive(s) {
            flex-direction: column;
        }
    }

    &__table {
        @include responsive(s) {
            width: 100%;
            overflow-x: auto;
        }

        .page-container--table {
            th {
                &:nth-child(1) {
                    width: 100%;
                }
            }
            td {
                &:nth-child(1) {
                    width: 100%;
                }
            }
            @include responsive(sm) {
                th {
                    &:nth-child(1) {
                        width: 100%;
                    }
                }
                td {
                    &:nth-child(1) {
                        width: 100%;
                    }
                }
            }

            @include responsive(s) {
                th {
                    &:nth-child(1) {
                        width: 100%;
                    }
                }
                td {
                    &:nth-child(1) {
                        width: 100%;
                    }
                }
            }
        }

        &--content {
            min-height: 330px;
        }

        &--cell-icon {
            @include responsive(sm) {
                width: 7%;
            }

            @include responsive(s) {
                width: 7%;
            }
        }
    }
}
