@use '~styles/constants' as *;

.scd-create-batch-operations-slide {
    width: 100%;
    background: #ffff;
    position: fixed;
    bottom: 52px;
    left: 0;
    right: 0;
    display: flex;
    z-index: 1;
    justify-content: center;
    height: 90px;
    align-items: center;
    font-family: 'Frutiger';

    &--container {
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 100%;
        width: 1115px;
    }

    &--content {
        display: flex;

        &__selected-operations {
            margin-left: 180px;
        }

        &__item {
            display: flex;
            flex-direction: column;

            &-label {
                font-family: 'Frutiger-light';
                color: #9199a1;
                font-size: 12px;
            }

            &-value {
                font-family: 'Frutiger-55';
                color: #072056;
                font-weight: 500;
                font-size: 18px;
                width: 140px;
            }
        }
    }

    &--item {
        &__action {
            display: flex;
            align-items: center;
            justify-content: center;
            color: #3f41d1;
            cursor: pointer;
            font-size: 15px;
            letter-spacing: 1.5px;
            margin-right: 30px;

            &.disabled {
                cursor: default;
                opacity: 0.5;
            }
            .MuiSvgIcon-root {
                margin-right: 7px;
            }

            &-remove-icon {
                content: $svg_no_disturb;
                width: 18px;
                height: 18px;
                margin-right: 8px;
            }
        }
    }

    &--buttons {
        display: flex;
    }
}
