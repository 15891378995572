.batch-generate-report-modal {
    &--content {
        width: 315px;
        display: flex;
        flex-direction: column;
        margin-bottom: 20px;

        &-message {
            display: flex;
            justify-content: center;
            align-content: center;
            font-size: 16px;
            color: #9199a1;
        }

        &-field {
            margin: 8px 0px;

            &__checkbox {
                display: flex;
                align-items: center;
                justify-content: flex-start;
            }
        }
    }
}
