@use '~styles/constants' as *;

.request-log_json_card__container {
    margin: 0;

    &.list {
        width: 365px;
        padding: 28px 20px 15px;
        border-radius: 8px;
        background-color: #f5f5f5;
        margin: 15px 0px;

        .card__container {
            &--header-name {
                font-size: 18px;
            }
            &--subtitle-box > h4 {
                font-size: 16.5px;
            }
        }
    }


    &--header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 15px;
        width: 100%;

        &-name {
            font-weight: normal;
            color: #000000;
        }
        &-icon {
            width: 20px;
            height: 20px;
            content: $svg_copy_icon;
            cursor: pointer;
        }
    }
    &--field {
        min-height: 65px;
    }

    &--footer {
        margin-top: 25px;
        display: flex;
        justify-content: flex-end;
    }

    &-manager {
        &--header {
            margin-bottom: 15px;
        }
        &--content {
            &-field {
                margin-bottom: 15px;
            }
        }
    }
    > pre {
        padding: 15px;  
    }
}
