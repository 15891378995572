@use "~styles/constants" as *;

.batch-outputs-detail {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding: 10px 20px 0;

    &__container {
        max-width: 1115px;
        min-height: 450px;
        width: 100%;

        &-title {
            margin: 25px 0px -10px 0px;
            font-family: 'Frutiger-55', sans-serif;
            font-weight: 500;
            font-size: 20px;
            line-height: 24px;
        }
    }

    &__component {
        height: 480px;
        
        &--not-found {
            width: 100%;
            height: 260px;
            display: flex;
            justify-content: center;
            align-items: center;
            font-family: 'Frutiger', sans-serif;
            flex-direction: column;
    
            &-image {
                content: $svg_ops;
                width: 100px;
                height: 100px;
            }
    
            &-title {
                font-size: 26px;
                line-height: 24px;
                color: #d8d8d8;
                padding-top: 10px;
            }
    
            &-subtitle {
                font-size: 16px;
                line-height: 24px;
                color: #d8d8d8;
                padding-top: 10px;
            }
        }
    }
}
