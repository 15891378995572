@use '~styles/constants' as *;

.scd-technical-supplier__detail {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-bottom: 30px;

    &--main-container {
        width: 350px;
        display: flex;
        align-items: center;
        flex-direction: column;
        margin-top: 25px;
        position: relative;
        min-height: 500px;
    }
    &--header {
        display: flex;
        justify-content: center;

        width: 100%;
        padding: 10px 20px 25px;
        text-align: center;

        &--title {
            font-size: 28px;
            font-weight: 500;
            color: #292a2a;
            width: 100%;
            margin: 0;
            text-align: center;
        }
    }

    &--container {
        width: 290px;
    }
}
