@use '~styles/constants' as *;

.scd-request-log__detail {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 30px;

    &--main-container {
        width: 350px;
        display: flex;
        align-items: center;
        flex-direction: column;
        margin-top: 25px;
        position: relative;
        min-height: 500px;
    }

    &--header {
        display: flex;
        justify-content: flex-start;
        width: 100%;
        padding: 10px 20px 25px;

        &-title {
            font-size: 28px;
            font-weight: 500;
            text-align: center;
            color: #292a2a;
            width: 100%;
            margin: 0;
            text-align: center;
        }
    }

    &--container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    dl {
        width: 300px;
    }
}
