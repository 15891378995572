.disbursement-confirmation-collapse {

    &__sublist {
        width: 100%;
        border: none;
        border-bottom: 1px solid #d8d8d8;
        border-top: 1px solid #d8d8d8;
        padding: 0px;
        font-size: 14px;
        height: 50px;
        display: flex;
        align-items: center;
        overflow: hidden;
        background: #f5f5f5;

        &--box {
            width: 100%;
            display: flex;
            flex-direction: column;
        }

        &--cell {
            width: 18%;
            display: flex;
            font-family: 'Frutiger-light', sans-serif;

            &:nth-child(1) {
                width: 20%;
            }

            &:nth-child(3) {
                width: 20%;
            }

            &:nth-child(7) {
                width: 5%;
                svg {
                    transform: rotate(0deg);
                }
            }

            &.disable {
                svg {
                    opacity: 0.5;
                }
            }

            svg {
                transition: 0.2s ease-in-out;
            }

            .active {
                transform: rotateX(1turn);
            }

            &-status {
                font-family: 'Frutiger', sans-serif;
                width: fit-content;
                font-size: 11px;
                font-weight: 500;
                padding: 4px 8px;
                border-radius: 5px;
                display: flex;
                align-items: center;
                justify-content: flex-start;
                white-space: nowrap;
                color: #4a4a4a;
    
                &.SUCCESS {
                    background-color: #b8d759;
                }
    
                &.FAILURE {
                    background-color: #ff7978;
                }
            }
        }
    }
}