@use '~styles/constants' as *;

.scd-ccb-operation {
    height: 770px;
    padding: 10px 20px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    margin-bottom: 40px;

    &__container {
        max-width: 1115px;
        min-height: 450px;
        width: 100%;
    }

    &__header {
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        padding: 20px 0;
        width: 100%;

        &-container {
            display: flex;
            width: 100%;

            &--title {
                font-size: 34px;
                font-weight: 500;
                color: #545454;
                margin: 0;
            }

            &--switch {
                background-color: #f6f6f6;
                display: flex;
                border-radius: 20px;
                font-family: 'Frutiger';
                padding: 20px 30px;
                color: #9199a1;
                width: 190px;
                height: 40px;
                justify-content: center;
                align-items: center;
                cursor: pointer;

                &.active {
                    background-color: white;
                    padding: 20px 30px;
                    color: #163c6e;
                    border: 1px solid #e3e3e3;
                    height: 40px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    cursor: default;
                    border-radius: 20px;
                }

                &.disabled {
                    cursor: default;
                }
            }

            &--align-right {
                width: 100%;
                justify-content: flex-end;
                display: flex;
                align-items: center;
            }
        }

        &--filter-content {
            display: flex;
            justify-content: space-between;
            width: 100%;
            align-items: center;
        }

        &--buttons-content {
            padding: 20px 0px;
            display: flex;
            width: 100%;

            &-filter-button {
                padding-right: 10px;
            }

            &-error-arrow {
                content: $svg_error_arrow;
                width: 30px;
                height: 30px;
                margin-top: 8px;
                margin-left: 10px;
                cursor: pointer;
            }

            &-align-right {
                width: 100%;
                justify-content: flex-end;
                display: flex;
            }
        }

        @include responsive(s) {
            flex-direction: column;
        }

        &--select-operations {
            display: flex;
            cursor: pointer;

            &__icon {
                content: $svg_select;
            }

            &__value {
                letter-spacing: 1.5px;
                color: #3f41d1;
                font-weight: 400;
                font-size: 15px;
                margin: 0px 8px;
                line-height: 24px;
            }
        }
    }

    &__table {
        width: 100%;
        overflow-x: auto;

        .page-container--table {
            th {
                &:nth-child(1) {
                    width: 11%;
                }
                &:nth-child(2) {
                    width: 11%;
                }
                &:nth-child(3) {
                    width: 12%;
                }
                &:nth-child(4) {
                    width: 11%;
                }
                &:nth-child(5) {
                    width: 14%;
                }
                &:nth-child(6) {
                    width: 17%;
                }
                &:nth-child(7) {
                    width: 10%;
                }

                &:nth-child(8) {
                    width: 7%;
                    margin-right: 16px;
                }
            }
            td {
                margin-right: 11px;

                &:nth-child(1) {
                    width: 10%;
                }
                &:nth-child(2) {
                    width: 10%;
                    .batchless {
                        padding: 0px 6px;
                        color: #565a5b;
                        font-weight: 400;
                        font-size: 11px;
                        background: #f0f0f0;
                        border-radius: 4px;
                    }
                }
                &:nth-child(3) {
                    width: 11%;
                }
                &:nth-child(4) {
                    width: 10%;
                    span {
                        color: #163c6e;
                        border: 1px solid #163c6e;
                        height: 25px;
                        width: 40px;
                        border-radius: 4px;
                    }
                }
                &:nth-child(5) {
                    width: 13%;
                }
                &:nth-child(6) {
                    width: 15%;
                }
                &:nth-child(7) {
                    width: 10%;
                }
                &:nth-child(8) {
                    width: 7%;
                    display: flex;
                    justify-content: center;

                    .icon-container {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        padding: 4px 12px;
                        cursor: pointer;

                        &:hover {
                            border: 1px solid #163c6e;
                            border-radius: 5px;
                        }

                        &__contract {
                            content: $svg_contract_rest;
                            height: 18px;
                            margin-right: 5px;
                        }
                    }

                    .icon-container-active {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        padding: 4px 12px;
                        cursor: pointer;
                        background-color: #163c6e;
                        color: white;
                        border: 1px solid #163c6e;
                        border-radius: 5px;

                        &__contract {
                            content: $svg_contract_hover;
                            height: 18px;
                            margin-right: 5px;
                        }
                    }
                }
                &:nth-child(9){
                    flex: 1;
                }
            }

            &-status {
                display: inline-flex;
                font-size: 11px;
                align-items: center;
                white-space: nowrap;
                justify-content: flex-start;
                height: 24px;
                font-weight: 500;
                border-radius: 5px;
                padding: 10px;

                &.NEW {
                    background-color: #42BE65;
                    color: #ffffff;
                }

                &.PENDING_APPROVAL {
                    color: #ffffff;
                    background-color: #A56EFF;
                }

                &.DISAPPROVED {
                    background-color: #FF832B;
                    color: #ffffff;
                }

                &.RECEIVED {
                    color: #ffffff;
                    background-color: #FF7EB6;
                }

                &.APPROVED {
                    background-color: #33B1FF;
                    color: #ffffff;
                }

                &.EXPORTED {
                    background-color: #6acff1;
                    color: #ffffff;
                }

                &.PROCESSED {
                    background-color: #08BDBA ;
                    color: #ffffff;
                }

                &.ERROR {
                    background-color: #FA4D56;
                    color: #ffffff;
                }

                &.BATCHLESS {
                    background-color: #bac0c2;
                }

                &.ARCHIVED {
                    color: #ffffff;
                    background-color: #A2A9B0;
                }
            }
        }

        &--content {
            min-height: 330px;
        }

        &-empty-filter {
            width: 100%;
            height: 350px;
            display: flex;
            justify-content: center;
            align-items: center;
            font-family: 'Frutiger';
            flex-direction: column;

            &-image {
                content: $svg_piggy_bank;
                width: 100px;
                height: 100px;
            }

            &-title {
                font-size: 26px;
                line-height: 24px;
                color: #d8d8d8;
                padding-top: 10px;
            }

            &-subtitle {
                font-size: 16px;
                line-height: 24px;
                color: #d8d8d8;
                padding-top: 10px;
            }
        }
    }
}
