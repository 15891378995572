@use '~styles/constants' as *;

.transfeera-transfers-details {
    margin-bottom: 15px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 30px;
    width: 100%;

    &--header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        margin-bottom: 16px;

        &-title {
            font-size: 20px;
            font-weight: bold;
            color: #292a2a;
            letter-spacing: 0.3px;
            line-height: 1.2;
            margin: 0;
        }
    }

    &--container {
        max-height: 500px;
        overflow: auto;
    }

    &--buttons {
        &-actions {
            display: flex;
            align-items: center;
            justify-content: center;
            margin-top: 25px;
            > :nth-child(2) {
                margin-left: 25px;
            }
        }
    }
}
