.internal-error-card {
    width: 100%;
    height: 100%;
    font-size: 14px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    border-radius: 6px;
    padding: 6px 10px;

    &--title {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 95%;
        height: 50%;
        font-size: 14px;
        font-family: 'Frutiger-65-bold', sans-serif;
        font-weight: 200;
        letter-spacing: 1.5px;
        color: #9199a1;
    }
    
    &--message {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 95%;
        height: 35%;

        &__value {
            letter-spacing: 1px;
            font-weight: 400;
            font-size: 12px;
            margin: 0px 8px;
            line-height: 20px;
            font-family: 'Frutiger', sans-serif;
            color: #9199a1;
        }
    }
}