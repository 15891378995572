.conclude-cession-operation-modal {
    
    &__loading-box {
        width: 100%;
        height: 80px;
    }
    
    &__text {
        font-family: 'Frutiger', sans-serif;
        font-weight: 400;
    }

}