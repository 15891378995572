@use "~styles/constants" as *;

.scd-company-group-policy__detail {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-bottom: 30px;

    &--container {
        width: 290px;
    }

    &-edit {
        position: absolute;
        color: #3aadd1;
        top: 18px;
        right: 76px;
        cursor: pointer;
 
        a:-webkit-any-link {
            text-decoration: none;
            color: #3aadd1;
        }
    }
}
