@use '~styles/constants' as *;

.transfeera-transfers-modal-filter {
    margin-bottom: 15px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 30px;
    width: 420px;

    &--status-label {
        padding: 0px 18px;
        margin: 7px 5px;
        height: 43px;
        border-radius: 17px;
        background-color: #f0f0f0;
        color: #163c6e;
        display: flex;
        justify-content: center;
        align-items: center;
        font-family: 'Frutiger';
        font-size: 12px;
        letter-spacing: 1.5px;
        cursor: pointer;

        &.CRIADA {
            background-color: #f2c466;
            color: #343434;
        }

        &.RECEBIDO {
            background-color: #2c5d6c;
            color: #f0f0f0;
        }

        &.TRANSFERIDO {
            background-color: #6acff1;
        }

        &.AGENDADO {
            background-color: #b8d759;
        }

        &.FALHA {
            background-color: #db6968;
            color: #ffffff;
        }

        &.DEVOLVIDO {
            background-color: #9199a1;
            color: #ffffff;
        }
    }

    &--header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        margin-bottom: 16px;

        &-title {
            font-size: 20px;
            font-weight: bold;
            color: #292a2a;
            letter-spacing: 0.3px;
            line-height: 1.2;
            margin: 0;
        }
    }

    &--buttons {
        &-actions {
            display: flex;
            align-items: center;
            justify-content: center;
            margin-top: 25px;
            > :nth-child(2) {
                margin-left: 25px;
            }
        }
    }

    &-search-content {
        &.active {
            height: 120px;
            border: 1px solid #3f41d1;
            border-radius: 7px;
        }
    }
}
