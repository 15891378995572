.closed-drawer {
    width: 100%;
    height: 100%;
    background: transparent;
    border: none;

    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;
    padding: 0 0 104px 0;


    &--closed-header {
        width: 100%;
        min-height: 136px;
        display: flex;
        justify-content: center;
        padding: 48px 0 0;

        &--logo_box {
            width: 64px;
            height: 64px;
            background: #163c6e;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 50%;

            img {
                width: 38px;
                height: 38px;
            }
        }
    }

    &--icon-box {
        display: flex;
        flex-direction: column;
        row-gap: 16px;
        padding: 16px 0 0;
    }
}
