@use '~styles/constants' as *;

.transfeera-statement-error {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 2rem;

    &--item {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        width: 100%;
        max-width: 1115px;

        > label {
            font-size: 1rem;
            color: #292a2a;
            font-weight: 500;
            display: block;
            margin: 0;
        }
    }
}