@use '~styles/constants' as *;

.disbursement-confirmation-apex {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-top: 20px;
    border-bottom: 1px solid #d8d8d8;

    &__table {
        width: 100%;
        margin: 8px 0px 0px;

        .page-container--table {
            display: inline-block;

            thead {
                width: 100%;

                &:first-child {
                    display: inline-block;
                }
            }

            tbody {
                height: calc(100vh - 580px);
                display: block;
                overflow-y: auto;
            }

            &.EMPTY_COLLECTION {
                tbody {
                    min-height: 0px;
                    height: min-content;
                }
            }

            th {
                &:nth-child(1) {
                    width: 30%;
                }

                &:nth-child(2) {
                    width: 40%;
                }

                &:nth-child(3) {
                    width: 30%;
                }
            }

            td {
                margin-right: 10px;
                overflow: hidden;
                text-overflow: ellipsis;

                &:nth-child(1) {
                    width: 30%;
                }

                &:nth-child(2) {
                    width: 40%;
                }

                &:nth-child(3) {
                    width: 30%;
                }
            }
        }

        .page-container--table-container {
            &::-webkit-scrollbar {
                width: 4px;
                background-color: #e1e2e6;
            }

            &::-webkit-scrollbar-thumb {
                width: 3px;
                background: #a4a4a4;
            }
        }

        .page-container--table-cell {
            max-height: 50px;
            min-height: 50px;
            overflow: hidden;

            &-status {
                display: inline-flex;
                font-size: 11px;
                align-items: center;
                white-space: nowrap;
                justify-content: flex-start;
                height: 24px;
                font-weight: 500;
                border-radius: 5px;
                padding: 10px;
                margin-top: 3px;

                &.SUCCESS {
                    background-color: #b8d759;
                }

                &.PROCESSING {
                    background-color: #f2c466;
                }

                &.PENDENT {
                    background-color: #6acff1;
                }

                &.ERROR {
                    background-color: #ff7978;
                }
            }
        }

        &-empty-filter {
            width: 100%;
            height: 260px;
            display: flex;
            justify-content: center;
            align-items: center;
            font-family: 'Frutiger', sans-serif;
            flex-direction: column;

            &-image {
                content: $svg_piggy_bank;
                width: 100px;
                height: 100px;
            }

            &-title {
                font-size: 26px;
                line-height: 24px;
                color: #d8d8d8;
                padding-top: 10px;
            }

            &-subtitle {
                font-size: 16px;
                line-height: 24px;
                color: #d8d8d8;
                padding-top: 10px;
            }
        }
    }
}
