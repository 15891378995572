@use '~styles/constants' as *;

.scd-output-hemera {
    width: 100%;
    padding: 10px 20px 0;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 30px;

    &--container {
        width: 300px;
    }

    &-container-icon {
        display: flex;
        justify-content: space-between;
        align-items: center;
        position: relative;
        width: 100%;
    }

    &__icon {
        content: $svg_arrow_download;
        height: 20px;
        width: 20px;
        margin-right: 10px;
        position: absolute;
        right: 0;
        cursor: pointer;
    }

    &--header {
        color: red;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        flex-wrap: nowrap;
        padding: 20px 0;
        width: 100%;
        
        &-title {
            font-size: 26px;
            padding-top: 10px;
            font-weight: 500;
            color: #292a2a;
            margin: 0;
        }
    }

    &--form-buttons {
        display: flex;
        margin-top: 40px;
        div{
            display: flex;
            justify-content: center;
            width: 100%;
        }
    }

    &--empty {
        width: 100%;
        height: 260px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-family: 'Frutiger';
        flex-direction: column;

        &-image {
            content: $svg_ops;
            width: 150px;
            height: 150px;
        }

        &-title {
            font-size: 26px;
            line-height: 24px;
            color: #d8d8d8;
            padding-top: 10px;
        }

        &-subtitle {
            font-size: 16px;
            line-height: 24px;
            color: #d8d8d8;
            padding-top: 10px;
        }
    }
}