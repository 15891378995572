.monthly-commission-values-card {
    padding: 22px;
    width: 190px;
    height: 244px;
    background: #2c5d6c;
    border-radius: 8px;

    &--title {
        font-family: 'Frutiger';
        font-weight: 500;
        font-size: 18px;
        color: #ffffff;
    }

    &--item {
        display: flex;
        flex-direction: column;
        margin: 18px 0px;

        &__label {
            color: #ffffff;
            font-family: 'Frutiger-55';
            font-size: 14px;
            font-weight: 500;
            line-height: 14px;
        }

        &__value {
            color: #ffffff;
            font-family: 'Frutiger-light';
            font-size: 16px;
            line-height: 25px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            width: 260px;

            strong {
                font-family: 'Frutiger';
                font-size: 18px;
                font-weight: 500;
                line-height: 25px;
            }
        }
    }
}
