@use '~styles/constants' as *;

.scd-monthly-commission {
    height: 100%;
    padding: 10px 20px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;

    &__container {
        max-width: 1115px;
        min-height: 450px;
        width: 100%;
    }

    &__header {
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        padding: 20px 0;
        width: 100%;

        &-container {
            display: flex;
            width: 100%;
            justify-content: space-between;

            &--title {
                font-size: 34px;
                font-weight: 500;
                color: #545454;
                margin: 0;
            }

            &--align-right {
                width: 100%;
                justify-content: flex-end;
                display: flex;
                align-items: center;
            }
        }

        &--buttons-content {
            padding: 20px 0px;
            display: flex;
            width: 100%;

            &-filter-button {
                padding-right: 10px;
            }

            &-error-arrow {
                content: $svg_error_arrow;
                width: 30px;
                height: 30px;
                margin-top: 8px;
                margin-left: 10px;
                cursor: pointer;
            }

            &-align-right {
                width: 100%;
                justify-content: flex-end;
                display: flex;
            }
        }

        @include responsive(s) {
            flex-direction: column;
        }
    }

    &__table {
        width: 100%;
        overflow-x: auto;

        .page-container--table {
            th {
                &:nth-child(1) {
                    width: 11%;
                }
                &:nth-child(2) {
                    width: 27%;
                }
                &:nth-child(3) {
                    width: 15%;
                }
                &:nth-child(4) {
                    width: 21%;
                }
                &:nth-child(5) {
                    width: 13%;
                }
                &:nth-child(6) {
                    width: 10%;
                }
            }
            td {
                margin-right: 11px;

                &:nth-child(1) {
                    width: 10%;
                }
                &:nth-child(2) {
                    width: 26%;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    display: block;
                }
                &:nth-child(3) {
                    width: 14%;
                }
                &:nth-child(4) {
                    width: 19%;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    display: block;
                }
                &:nth-child(5) {
                    width: 13%;
                }
                &:nth-child(6) {
                    width: 6%;
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    .icon-container {
                        padding: 10px;

                        .MuiSvgIcon-root {
                            font-size: 29px;
                            color: #565a5b;
                            cursor: pointer;
                        }

                        &:hover {
                            background-color: #3f41d1;
                            border-radius: 10px;
                            height: 38px;
                            width: 38px;
                            cursor: pointer;

                            .MuiSvgIcon-root {
                                font-size: 29px;
                                color: #ffffff;
                            }

                            path {
                                fill: #ffffff;
                            }
                        }
                    }

                    .icon-container-active {
                        margin-top: 4px;
                        padding: 10px;
                        box-shadow: -2px -2px 4px rgba(0, 0, 0, 0.1);
                        border-radius: 13px 10px 0px 0px;
                        background-color: #ffffff;
                        height: 47px;
                        position: absolute;

                        .MuiSvgIcon-root {
                            font-size: 29px;
                            color: #bac0c2;
                        }
                    }
                }
            }

            &-status {
                display: inline-flex;
                font-size: 11px;
                align-items: center;
                white-space: nowrap;
                justify-content: flex-start;
                height: 24px;
                font-weight: 500;
                border-radius: 5px;
                padding: 10px;
                color: #343434;

                &.NEW {
                    color: #ffffff;
                    background-color: #db6968;
                }

                &.WAITING {
                    background-color: #f2c466;
                }

                &.RELEASED {
                    background-color: #b8d759;
                }

                &.TRANSFERRING {
                    background-color: #6acff1;
                }

                &.PAID {
                    color: #ffffff;
                    background-color: #163c6e;
                }
            }

            &-create {
                align-items: center;
                border-bottom: solid 1px #d8d8d8;
                color: $dark;
                display: flex;
                font-family: 'Frutiger';
                font-size: 0.875rem;
                font-weight: 300;
                height: 70px;
                line-height: 2.43;
                overflow: hidden;
                text-overflow: ellipsis;
                width: 100%;
                background-color: #ffffff;
                padding: 0px 22px;
            }
        }

        &--content {
            min-height: 330px;
        }

        &-empty-filter {
            width: 100%;
            height: 350px;
            display: flex;
            justify-content: center;
            align-items: center;
            font-family: 'Frutiger';
            flex-direction: column;

            &-image {
                content: $svg_piggy_bank;
                width: 100px;
                height: 100px;
            }

            &-title {
                font-size: 26px;
                line-height: 24px;
                color: #d8d8d8;
                padding-top: 10px;
            }

            &-subtitle {
                font-size: 16px;
                line-height: 24px;
                color: #d8d8d8;
                padding-top: 10px;
            }
        }
    }
}

.MuiPaper-root {
    background: #ffffff;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
    border-radius: 5px;

    .MuiMenuItem-root {
        font-size: 15px;
        color: #072056;
    }
}
