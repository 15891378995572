@use "~styles/constants" as *;

.batch-list-card {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    max-height: calc(100vh - 400px);

    &__container {
        max-height: calc(100vh - 400px);
        margin-left: 5px;
        > div {
            &::-webkit-scrollbar {
                width: 4px;
                background-color: #e1e2e6;
            }
            
            &::-webkit-scrollbar-thumb {
                width: 3px;
                background: #a4a4a4;
            }
        }
    }

    &--detail-card {
        font-weight: bold;
    }
}