@use '~styles/constants' as *;

.payment-transaction-modal-filter--container {
    width: 420px;
    height: 100%;
    padding: 24px;

    display: flex;
    flex-direction: column;

    &::-webkit-scrollbar {
        width: 4px;
        background-color: #e1e2e6;
    }

    &::-webkit-scrollbar-thumb {
        width: 3px;
        background: #a4a4a4;
    }

    &.ALL_FILTERS_OPEN {
        width: 100%
    }

    &-mode--label {
        padding: 0px 18px;
        margin: 7px 5px;
        height: 43px;
        border-radius: 17px;
        background-color: #f0f0f0;
        color: #163c6e;
        display: flex;
        justify-content: center;
        align-items: center;
        font-family: 'Frutiger';
        font-size: 12px;
        letter-spacing: 1.5px;
        cursor: pointer;

        &.AUTOMATIC {
            background-color: #32aacf
        }

        &.MANUAL {
            background-color: #3f41d1;
            color:#ffffff
        }

        &.AUTOMATIC_DISPATCH {
            background-color: #ED66C9;
        }

        &.MANUAL_DISPATCH {
            background-color: #ffffff;
            border: 1px solid #3f41d1;
            color: #3f41d1;
        }
    }
    &-type--label {
        padding: 0px 18px;
        margin: 7px 5px;
        height: 43px;
        border-radius: 17px;
        background-color: #f0f0f0;
        color: #163c6e;
        display: flex;
        justify-content: center;
        align-items: center;
        font-family: 'Frutiger';
        font-size: 12px;
        letter-spacing: 1.5px;
        cursor: pointer;
        outline: none;
        border: none;

        &.MANUAL {
            background-color: #9199a1;
            color: #ffffff;
        }

        &.DISBURSEMENT_BY_BATCH {
            background-color: #072056;
            color: #ffffff;
        }

        &.DISBURSEMENT_TO_DEBTOR {
            background-color: #32aacf;
            color: #343434;
        }
        &.MANUAL_DISBURSEMENT {
            background-color: #6dbc70;
            color: #343434;
        }
        &.AMENDED_DISBURSEMENT {
            background-color: #ED66C9;
            color: #343434;
        }
        &.INTERNAL_REPAYMENT {
            background-color: #ef7d00;
            color: #343434;
        }
        &.EXTERNAL_DISBURSEMENT {
            background-color: #b947ff;
            color: #343434;
        }

    

        
        

    }

    &-status--label {
        padding: 0px 18px;
        margin: 7px 5px;
        height: 43px;
        border-radius: 17px;
        background-color: #f0f0f0;
        color: #163c6e;
        display: flex;
        justify-content: center;
        align-items: center;
        font-family: 'Frutiger';
        font-size: 12px;
        letter-spacing: 1.5px;
        cursor: pointer;

        &.CONFIRMED {
            background-color: #b8d759;
        }

        &.ERROR {
            background-color: #db6968;
            color: #ffffff;
        }

        &.PENDING {
            background-color: #f2c466;
            color: #343434;
        }

        &.WAITING {
            background-color: #6ACFF1;
        }

        &.ARCHIVED {
            background-color: #9199a1;
            color: #ffffff;
        }

        &.VALIDATING {
            background-color: #2c5d6c;
            color: #f0f0f0;
        }

        &.INCONSISTENT {
            color: #565a5b;
            border: 1px solid #9199a1;
            background-color: #ffffff;
        }
        &.UNRESOLVED {
          color: #ff0000;
          border: 1px solid #ff0000;
          background-color: #ffffff;
        }
    }

    &-search-content {
        &.active {
            height: 120px;
            border: 1px solid #3f41d1;
            border-radius: 7px;
        }
    }
}

.payment-transaction-modal-filter--header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-bottom: 16px;

    &-title {
        font-size: 20px;
        font-weight: bold;
        color: #292a2a;
        letter-spacing: 0.3px;
        line-height: 1.2;
        margin: 0;
    }
}

.payment-transaction-modal-filter--buttons {
    &-actions {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 25px;
        > :nth-child(2) {
            margin-left: 25px;
        }
    }
}

.payment-transaction-modal-filter--label {
    font-family: 'Frutiger';
    font-weight: 700;
    font-size: 14px;
    line-height: 16.5px;
    letter-spacing: 1px;
    color: #343434
}
.payment-transaction-modal-filter--status {
    display: flex; 
    flex-wrap: wrap; 
    justify-content: center;
}

.payment-transaction-modal-filter--row {
    width: 100%;
    display: flex; 
    justify-content: space-around;
    margin: 8px 0px;

}
.payment-transaction-modal-filter--rowAlign {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.payment-transaction-modal-filter--alignItems {
    display: flex;
}

.bank-tooltip {
    color: #999;
}