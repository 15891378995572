@use '~styles/constants' as *;

.scd-payment-transaction {
    width: 100%;
    height: 100%;
    padding: 0px 24px;
    display: flex;
    flex-direction: column;
    align-items: center;

    &__container {
        width: 100%;
        max-width: 1116px;
        min-height: 440px;
    }

    &__header {
        width: 100%;
        display: flex;
        row-gap: 12px;
        flex-direction: column;
        flex-wrap: nowrap;

        &--container-buttons {
            display: flex;
            justify-content: space-between;
            align-items: center;
        }

        &--title {
            font-size: 34px;
            font-weight: 500;
            color: #545454;
            margin: 0;
            display: flex;
        }

        &--filter-container{
            width: 100%;
            display: flex;
            flex-wrap: wrap;
            align-items: center;
        }

        &--buttons-content {
            display: flex;
            width: 100%;

            &-filter-button {
                padding-right: 10px;
            }

            &-error-arrow {
                content: $svg_error_arrow;
                width: 30px;
                height: 30px;
                margin-top: 8px;
                margin-left: 10px;
                cursor: pointer;
            }

            &-align-right {
                justify-content: flex-end;
                display: flex;
                width: 100%;
            }

            &-new-transaction {
                width: 120px;
                margin: 0px 10px;
            }

            &-pending {
                width: 165px;
            }

            &-in-series {
                width: 140px;
                margin: 0px 10px;
            }
        }

        @include responsive(s) {
            flex-direction: column;
        }
    }

    &__table {
        width: 100%;
        margin: 8px 0px 0px;

        .transactions-container {
            display: flex;
            align-items: center;
            justify-content: space-between;


            & > * {
                cursor: pointer;

                &:hover {
                    color: #3f41d1
                }
            }
        }

        .transactions-info {
            font-weight: 500;
            font-size: 14px;
            line-height: 24px;
            letter-spacing: 1.5px;
            color: #32AACF;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            column-gap: 8px;

            span {
                font-family: 'Frutiger-55';
            }

            &--icon {
                width: 19px;
                height: 17px;
            }
        }

        .page-container--table {
            display: inline-block;

            thead {
                width: 100%;

                &:first-child{
                    display: inline-block;
                }
            }

            tbody {
                height: calc(100vh - 424px);
                display: block;
            }

            &.CHIPS_ACTIVE {
                tbody {
                    height: calc(100vh - 456px);

                }
            }

            &.EMPTY_COLLECTION {
                tbody {
                    min-height: 0px;
                    height: min-content;
                }
            }

            ::-webkit-scrollbar {
                width: 4px;
                background-color: #e1e2e6;
            }

            ::-webkit-scrollbar-thumb {
                width: 3px;
                background: #a4a4a4;
            }

            th {
                &:nth-child(1) {
                    width: 12.3%;
                    padding-left: 25px;
                }
                &:nth-child(2) {
                    width: 11%;
                }
                &:nth-child(3) {
                    width: 14%;
                }
                &:nth-child(4){
                    width: 22%;
                }
                &:nth-child(5) {
                    width: 8%;
                }
                &:nth-child(6) {
                    width: 17%;
                }
            }

            td {
                margin-right: 10px;
                overflow: hidden;
                text-overflow: ellipsis;

                &:nth-child(1) {
                    width: 11%;
                    padding-left: 15px;
                }
                &:nth-child(2) {
                    width: 10.4%;
                }
                &:nth-child(3) {
                    width: 13.3%;
                }
                &:nth-child(4) {
                    width: 21.7%;
                }
                &:nth-child(5) {
                    width: 6.6%;
                }
                &:nth-child(6) {
                    width: 16%;
                }
                &:nth-child(7) {
                    width: 11.5%;
                    margin-right: 0px;
                }
                &:nth-child(8) {
                    width: 3%
                }
            }

            @include responsive(s) {
                th {
                    &:nth-child(1) {
                        width: 150px;
                    }
                    &:nth-child(2) {
                        width: 140px;
                    }
                    &:nth-child(3) {
                        width: 410px;
                    }
                    &:nth-child(4) {
                        width: 140px;
                    }
                    &:nth-child(5) {
                        width: 230px;
                    }
                    &:nth-child(6) {
                        width: 130px;
                    }
                }
                td {
                    &:nth-child(1) {
                        width: 150px;
                    }
                    &:nth-child(2) {
                        width: 150px;
                    }
                    &:nth-child(3) {
                        width: 450px;
                    }
                    &:nth-child(4) {
                        width: 140px;
                    }
                    &:nth-child(5) {
                        width: 250px;
                    }
                    &:nth-child(6) {
                        width: 130px;
                    }
                }
            }

            &-status {
                display: inline-flex;
                padding: 10px;
                font-size: 11px;
                align-items: center;
                white-space: nowrap;
                justify-content: center;
                height: 25px;
                font-weight: 500;
                border-radius: 5px;

                &.CONFIRMED {
                    background-color: #b8d759;
                }

                &.ERROR {
                    background-color: #db6968;
                    color: #ffffff;
                }

                &.PENDING {
                    background-color: #f2c466;
                    color: #343434;
                }

                &.WAITING {
                    background-color: #6acff1;
                }

                &.ARCHIVED {
                    background-color: #9199a1;
                    color: #ffffff;
                }

                &.VALIDATING {
                    background-color: #2c5d6c;
                    color: #f0f0f0;
                }

                &.INCONSISTENT {
                    color: #565a5b;
                    border: 1px solid #9199a1;
                }
                &.UNRESOLVED {
                  color: #ff0000;
                  border: 1px solid #ff0000;
                  background-color: #ffffff;
              }

                &.EXTERNAL_DISBURSEMENT {
                    color: #ff0000;
                    border: 1px solid #b947ff;
                    background-color: #ffffff;
                }
            }
        }

        &--content {
            min-height: 330px;
        }

        &-fields {
            display: flex;

            &-table-column {
                &__amount-value {
                    width: 140px;
                    margin-right: 10px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    align-items: center;
                }

                &__favored-value {
                    width: 210px;
                    margin-right: 10px;
                    align-items: center;

                    div {
                        height: 20px;
                        line-height: 20px;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                    }
                }

                &__originator-value {
                    width: 120px;
                    margin-right: 10px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    align-items: center;
                }

                &__agency-value {
                    width: 140px;
                    margin-right: 10px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    align-items: center;
                }

                &__account-value {
                    width: 174px;
                    margin-left: 8px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    line-height: 16px;
                    align-items: center;
                }
            }
            &-new-transaction {
                &__field-value {
                    width: 210px;
                    margin-right: 20px;
                }

                &__field-favored {
                    margin-right: 20px;
                    width: 340px;
                    &-content {
                        position: absolute;
                        top: 10px;
                        width: 340px;
                        z-index: 2;
                    }
                }

                &__field-account {
                    width: 300px;
                    margin-right: 20px;
                    position: relative;
                }

                &__icons {
                    display: flex;
                    align-items: center;
                    justify-content: flex-end;
                    width: 170px;
                    height: 50px;

                    &-icon {
                        margin: 0px 12px;
                        cursor: pointer;
                        color: #9199a1;
                    }

                    &-blank {
                        width: 72px;
                    }

                    &-delete {
                        content: $svg_trash_hover;
                        height: 30px;
                        margin: 0px 21px;
                        cursor: pointer;
                    }

                    &-check {
                        border: 1px solid #9199a1;
                        padding: 6px;
                        width: 50px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        height: 50px;
                        border-radius: 10px;

                        &.disabled {
                            cursor: default;
                        }

                        &.active {
                            cursor: pointer;
                            border: 1px solid #3f41d1;
                            color: #3f41d1;
                        }
                    }

                    &-description {
                        content: $svg_description;
                        width: 23px;
                        height: 23px;

                        &.active {
                            cursor: pointer;
                            content: $svg_description_active;
                        }

                        &.disabled {
                            content: $svg_description;
                            cursor: default;
                        }
                    }
                }
            }
        }

        &-empty-filter {
            width: 100%;
            height: 260px;
            display: flex;
            justify-content: center;
            align-items: center;
            font-family: 'Frutiger';
            flex-direction: column;

            &-image {
                content: $svg_piggy_bank;
                width: 100px;
                height: 100px;
            }

            &-title {
                font-size: 26px;
                line-height: 24px;
                color: #d8d8d8;
                padding-top: 10px;
            }

            &-subtitle {
                font-size: 16px;
                line-height: 24px;
                color: #d8d8d8;
                padding-top: 10px;
            }
        }
    }
}
