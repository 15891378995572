.card__container {
    margin: 0;

    &.edit {
        width: 365px;
        padding: 32px 25px;
        border-radius: 6px;
        border: solid 1px #e1e2e6;
        margin-bottom: 15px;

        .card__container {
            &--header-name {
                font-size: 24px;
            }
            &--subtitle-box > h4 {
                font-size: 18px;
            }
        }
    }

    &.list {
        width: 315px;
        padding: 28px 20px 15px;
        border-radius: 8px;
        background-color: #f5f5f5;
        margin: 15px 0px;

        .card__container {
            &--header-name {
                font-size: 18px;
            }
            &--subtitle-box > h4 {
                font-size: 16.5px;
            }
        }
    }

    &--manager {
        display: flex;
        align-items: center;
        flex-direction: column;
        width: 100%;

        &-button {
            width: 100%;
            padding: 0 25px;
        }
    }

    &--header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 15px;
        width: 100%;

        &-name {
            font-weight: normal;
            color: #000000;
        }
    }
    &--field {
        min-height: 65px;
    }

    &--fields-two {
        display: flex;
        width: 100%;
        justify-content: space-between;
        .readonly__text-field {
            justify-content: space-between;
            width: 48%;
        }
    }

    &--buttons {
        display: flex;
        width: min-content;
        &-button {
            border: none;
            background: none;
            outline: none;
            &:first-child {
                margin-right: 10px;
            }
        }
    }

    &--footer {
        margin-top: 25px;
        display: flex;
        justify-content: flex-end;
    }

    &-manager {
        &--header {
            margin-bottom: 15px;
        }
        &--content {
            &-field {
                margin-bottom: 15px;
            }
        }
    }

    &--subtitle-box {
        margin: 20px 0;

        > * {
            font-weight: normal;
            color: #000000;
        }
    }
}
