@use "~styles/constants" as *;

.batch-reports-list {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    
    &__container {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 400px;
        padding: 10px;
        background-color: #f6f6f6;
        
        &--content {
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 300px;
            margin: 10px 0;
            padding-bottom: 5px;
            border-bottom: 1px solid #d8d8d8;
    
            &-items {
                display: flex;
                svg {
                    color: #3f41d1;
                }
                span {
                    font-family: 'Frutiger', sans-serif;
                    color: #212529;
                    margin-left: 10px;
                }
            }
            &-icon {
                content: $svg_arrow_download;
                height: 20px;
                width: 20px;
                margin-right: 10px;
                cursor: pointer;
            }
        }
    }

}