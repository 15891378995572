.header-content {
    font-family: 'Frutiger', sans-serif;
    font-size: 11px;
    font-weight: 500;
    display: flex;
    justify-content: space-between;
    width: 100%;

    &.issuer-policy-status {
        width: fit-content;
        padding: 4px 8px;
        border-radius: 5px;
        display: flex;
        align-items: center;
        justify-content: center;

        
        &.PENDING {
            background-color: #f2c466; 
        }

        &.CONFIGURED {
            background-color: #b8d759; 
        }

        &.INCONSISTENT {
            background-color: #ff7978;
        }
    }
}