@use '~styles/constants' as *;

.scd-technical-supplier {
    width: 100%;
    height: 100%;
    padding: 0px 24px;
    display: flex;
    flex-direction: column;
    align-items: center;

    &__container {
        width: 100%;
        max-width: 1116px;
        min-height: 440px;
    }

    &__header {
        width: 100%;

        display: flex;
        row-gap: 12px;
        align-items: center;
        justify-content: space-between;
        flex-wrap: nowrap;

        &--title {
            font-size: 34px;
            font-weight: 500;
            color: #545454;
            margin: 0;

            @include responsive(sm) {
                text-align: center;
                font-size: 32px;
            }

            @include responsive(s) {
                text-align: center;
                font-size: 32px;
                margin-bottom: 20px;
            }

            @include responsive(ss) {
                text-align: center;
                font-size: 29px;
            }
        }

        @include responsive(s) {
            flex-direction: column;
        }
    }

    &__table {
            width: 100%;
            margin: 8px 0px 0px;

        .page-container--table {
            display: inline-block;
            
            @media screen and (min-height: 850px){
                height: 400px;
            }

            thead {
                width: 100%;

                &:first-child {
                    display: inline-block;
                }
            }
            
            tbody {
                display: block;
                height: calc(100vh - 332px);
            }

            &.EMPTY_COLLECTION {
                tbody {
                    min-height: 0px;
                    height: min-content;
                }
            }

            &-container::-webkit-scrollbar {
                width: 4px;
                background-color: #e1e2e6;
            }

            &-container::-webkit-scrollbar-thumb {
                width: 3px;
                background: #a4a4a4;
            }
            
            th {
                &:nth-child(1) {
                    width: 29.5%;
                }
                &:nth-child(2) {
                    width: 15%;
                }
                &:nth-child(3) {
                    width: 18.5%;
                }
                &:nth-child(4) {
                    width: 10.5%;
                }
                &:nth-child(5) {
                    width: 10%;
                }
            }
            td {
                display: inline-block;
                margin-right: 5px;
                overflow-x: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                &:nth-child(1) {
                    width: 29%;
                }
                &:nth-child(2) {
                    width: 15%;
                }
                &:nth-child(3) {
                    width: 18%;
                }
                &:nth-child(4) {
                    width: 10%;
                }
                &:nth-child(5) {
                    width: 9%;
                }
                &:nth-child(6) {
                    width: 5%;
                }
                &:nth-child(7) {
                    width: 5%;
                }
                &:nth-child(8) {
                    width: 5%;
                }
            }
            @include responsive(sm) {
                th {
                    &:nth-child(1) {
                        width: 29%;
                    }
                    &:nth-child(2) {
                        width: 15.5%;
                    }
                    &:nth-child(3) {
                        width: 19.5%;
                    }
                    &:nth-child(4) {
                        width: 12%;
                    }
                    &:nth-child(5) {
                        width: 10%;
                    }
                }
                td {
                    &:nth-child(1) {
                        width: 30%;
                    }
                    &:nth-child(2) {
                        width: 16%;
                    }
                    &:nth-child(3) {
                        width: 20%;
                    }
                    &:nth-child(4) {
                        width: 12%;
                    }
                    &:nth-child(5) {
                        width: 8%;
                    }
                    &:nth-child(6) {
                        width: 5%;
                    }
                    &:nth-child(7) {
                        width: 5%;
                    }
                    &:nth-child(7) {
                        width: 5%;
                    }
                }
            }

            @include responsive(s) {
                th {
                    &:nth-child(1) {
                        width: 235px;
                    }
                    &:nth-child(2) {
                        width: 175px;
                    }
                    &:nth-child(3) {
                        width: 210px;
                    }
                    &:nth-child(4) {
                        width: 205px;
                    }
                    &:nth-child(5) {
                        width: 135px;
                    }
                    &:nth-child(6) {
                        width: 235px;
                    }
                }

                td {
                    &:nth-child(1) {
                        width: 235px;
                    }
                    &:nth-child(2) {
                        width: 165px;
                    }
                    &:nth-child(3) {
                        width: 205px;
                    }
                    &:nth-child(4) {
                        width: 200px;
                    }
                    &:nth-child(5) {
                        width: 138px;
                    }
                    &:nth-child(6) {
                        width: 50;
                    }
                    &:nth-child(7) {
                        width: 50px;
                    }
                }
            }
        }

        &--content {
            min-height: 330px;
        }

        &--cell-icon {
            @include responsive(sm) {
                width: 7%;
            }

            @include responsive(s) {
                width: 7%;
            }
        }

        &-empty-filter {
            width: 100%;
            height: 260px;
            display: flex;
            justify-content: center;
            align-items: center;
            font-family: 'Frutiger';
            flex-direction: column;

            &-image {
                content: $svg_piggy_bank;
                width: 100px;
                height: 100px;
            }

            &-title {
                font-size: 26px;
                line-height: 24px;
                color: #d8d8d8;
                padding-top: 10px;
            }

            &-subtitle {
                font-size: 16px;
                line-height: 24px;
                color: #d8d8d8;
                padding-top: 10px;
            }
        }
    }
}
