.batch-select-option-search-input-container {
    &.active {
        height: 200px;
        border: 1px solid #3f41d1;
        border-radius: 7px;
        background-color: white;
        z-index: 999;
        width: 92%;
        position: absolute!important;
    }
}

.batch-select-option-search-div-style {
    overflow-y: auto;
    overflow-x: hidden !important;
    max-height: 140px;
    right: 20px !important;
}

.batch-select-option-search-div-style::-webkit-scrollbar {
    width: 4px;
    background-color: #e1e2e6;
}

.batch-select-option-search-div-style::-webkit-scrollbar-thumb {
    width: 3px;
    background: #a4a4a4;
}


.batch-select-option-search-item-container {
    font-size: 15px;
    font-weight: 300;
    letter-spacing: 0.56px;
    padding: 12px 5px;
    display: flex;
    align-items: center;
    color: #111518;
    line-height: 15px;
    overflow: hidden;
    left: 32px !important;
    height: 70px !important;
    width: 310px!important;
    font-family: 'Frutiger';

    &:hover {
        border-radius: 4px;
        background-color: #3f41d1;
        cursor: pointer;
        color: #ffffff;
    }
}
