@use '~styles/constants' as *;

.scd-company {
    width: 100%;
    height: 100%;
    padding: 0px 24px ;
    display: flex;
    flex-direction: column;
    align-items: center;

    &__container {
        width: 100%;
        max-width: 1116px;
        min-height: 440px;
    }

    &__header {
        width: 100%;
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        row-gap: 12px;

        &-container {
            display: flex;
            width: 100%;
            justify-content: space-between;

            &--title {
                font-size: 34px;
                font-weight: 500;
                color: #545454;
                margin: 0;
            }

            &--align-right {
                width: 100%;
                justify-content: flex-end;
                display: flex;
                align-items: center;
            }
        }

        &--buttons-content {
            display: flex;
            width: 100%;

            &-filter-button {
                padding-right: 10px;
            }

            &-error-arrow {
                content: $svg_error_arrow;
                width: 30px;
                height: 30px;
                margin-top: 8px;
                margin-left: 10px;
                cursor: pointer;
            }

            &-align-right {
                width: 100%;
                justify-content: flex-end;
                display: flex;
            }
        }

        @include responsive(s) {
            flex-direction: column;
        }
    }

    &__table {
            width: 100%;
            margin: 8px 0px 0px;
        
        .page-container--table {
            display: inline-block;

            @media screen and (min-height: 850px){
                height: 400px;
            }

            thead {
                width: 100%;

                &:first-child {
                    display: inline-block;
                }
            }
            
            
            tbody {
                height: calc(100vh - 396px);
                display: block;
                overflow-y: auto;
            }

            &.CHIPS_ACTIVE {
                tbody {
                    height: calc(100vh - 432px);

                }
            }
            &.EMPTY_COLLECTION {
                tbody {
                    min-height: 0px;
                    height: min-content;
                }
            }

            &-container::-webkit-scrollbar {
                width: 4px;
                background-color: #e1e2e6;
            }

            &-container::-webkit-scrollbar-thumb {
                width: 3px;
                background: #a4a4a4;
            }

            th {
                &:nth-child(1) {
                    width: 35%;
                }
                &:nth-child(2) {
                    width: 20%;
                }
                &:nth-child(3) {
                    width: 30%;
                }
            }
            td {
                &:nth-child(1) {
                    width: 35%;
                    span {
                        max-width: 95%;
                        font-family: 'Frutiger-light';
                        font-size: 14px;
                        font-weight: 300;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                        display: block;
                    }
                }
                &:nth-child(2) {
                    width: 20%;
                }
                &:nth-child(3) {
                    width: 35%;
                    span {
                        max-width: 95%;
                        font-family: 'Frutiger-light';
                        font-size: 14px;
                        font-weight: 300;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                        display: block;
                    }
                }
            }
            @include responsive(sm) {
                th {
                    &:nth-child(1) {
                        width: 35%;
                    }
                    &:nth-child(2) {
                        width: 25%;
                    }
                    &:nth-child(3) {
                        width: 30%;
                    }
                }
                td {
                    &:nth-child(1) {
                        width: 35.2%;
                    }
                    &:nth-child(2) {
                        width: 25%;
                    }
                    &:nth-child(3) {
                        width: 30%;
                    }
                    &:nth-child(5) {
                        width: 4.8%;
                    }
                }
            }
            @include responsive(s) {
                th {
                    &:nth-child(1) {
                        width: 250px;
                    }
                    &:nth-child(2) {
                        width: 150px;
                    }
                    &:nth-child(3) {
                        width: 250px;
                    }
                }
                td {
                    &:nth-child(1) {
                        width: 250px;
                    }
                    &:nth-child(2) {
                        width: 150px;
                    }
                    &:nth-child(3) {
                        width: 250px;
                    }
                    &:nth-child(5) {
                        width: 200px;
                    }
                }
            }
        }

        &--content {
            min-height: 330px;
        }

        &-empty-filter {
            width: 100%;
            height: 350px;
            display: flex;
            justify-content: center;
            align-items: center;
            font-family: 'Frutiger';
            flex-direction: column;

            &-image {
                content: $svg_piggy_bank;
                width: 100px;
                height: 100px;
            }

            &-title {
                font-size: 26px;
                line-height: 24px;
                color: #d8d8d8;
                padding-top: 10px;
            }

            &-subtitle {
                font-size: 16px;
                line-height: 24px;
                color: #d8d8d8;
                padding-top: 10px;
            }
        }
    }
}

.MuiPaper-root {
    background: #ffffff;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
    border-radius: 5px;

    .MuiMenuItem-root {
        font-size: 15px;
        color: #072056;
    }
}
