.scd-header-operation-info-top {
    height: 65px;
    background: #ffffff;
    border-radius: 6px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding-left: 15px;

    &--item {
        display: flex;
        flex-direction: column;
        width: 24.5%;

        &__label {
            font-size: 14px;
            color: #9199a1;
            font-family: 'Frutiger-light';
        }

        &__value {
            max-width: 80%;
            color: #343434;
            font-size: 16px;
            line-height: 19px;
            font-family: 'Frutiger-light';
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }
    }
}

.scd-header-operation-expiration-top {
    position: absolute;
    top: 5px;
    left: 0px;
    width: 152px;
    background-color: #ffffff;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
    border-radius: 6px;
    display: flex;
    flex-direction: column;
    padding: 20px 23px;

    &--item {
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin-bottom: 15px;

        &__label {
            font-size: 13px;
            color: #565a5b;
            font-family: 'Frutiger-light';
        }

        &__value {
            color: #343434;
            font-size: 16px;
            line-height: 25px;
            font-family: 'Frutiger';
        }
    }

    &--expiration-number {
        text-align: center;
        margin-bottom: 15px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        &__value {
            color: #343434;
            font-weight: bold;
            font-size: 24px;
            line-height: 28px;

            input {
                width: 40px;
                border: none;
                text-align: center;
                color: #343434;
                font-weight: bold;
                font-size: 24px;
                line-height: 28px;
            }

            input:focus {
                font-family: 'Frutiger-light';
                background-color: #f0f0f0;
                font-weight: 300;
            }

            input[type='number'] {
                -moz-appearance: textfield;
            }

            input::-webkit-outer-spin-button,
            input::-webkit-inner-spin-button {
                -webkit-appearance: none;
            }
        }

        &__icon {
            color: #565a5b;
            font-size: 15px;
            width: 30px;
            cursor: pointer;
        }
    }
}
